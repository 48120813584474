<div class="justify-content-center flex align-items-center" *ngIf="hasVariables() && isMobile">
  <button
    label="{{ 'label.smartTabel.button.filter' | transloco : { default: 'Filter instellen' } }}"
    pButton
    pRipple
    icon="pi pi-filter-fill"
    (click)="openFilterWindow()"
    class="doffice-table-button p-button-sm mt-1 mb-2 p-button-secondary"
  ></button>
</div>

<!--TODO-->
<!--<div class="justify-content-center flex flex-wrap align-items-center gap-2 mb-2" *ngIf="isMobile">-->
<!--  <ng-container *ngIf="(parameters.filter ?? []).length > 0">-->
<!--    <p-tag *ngFor="let filter of parameters.filter" (click)="removeFilterParameter(filter)">{{ getColumnName(filter.id) }}: {{ filter.value }}<i class="pi pi-times text-xs ml-2"></i></p-tag>-->
<!--  </ng-container>-->
<!--</div>-->

<p-table
  [value]="data"
  dataKey="id"
  [rows]="10"
  [autoLayout]="true"
  [showCurrentPageReport]="!loading"
  [rowsPerPageOptions]="[5, 10, 25, 50]"
  [paginator]="true"
  currentPageReportTemplate="{first} - {last} / {totalRecords}"
  [totalRecords]="totalRecords"
  [lazy]="true"
  [defaultSortOrder]="-1"
  [sortField]="this.order.key"
  [sortOrder]="this.order.direction === DirectionEnum.ASC ? -1 : 1"
  responsiveLayout="stack"
  sortMode="single"
  (onLazyLoad)="onLazyLoad($event)"
>
  <ng-template pTemplate="header">
    <tr>
      <th *ngIf="(buttons ?? []).length > 0 && !loading" [style.width]="buttons.length * 40 + 10 + 'px'"></th>
      <ng-container *ngFor="let column of getColumns()">
        <th *ngIf="(column.sortKey ?? '').length > 0" [style]="'width: ' + column.width ?? 'auto' + ';'" [pSortableColumn]="column.sortKey">
          <span [class.ml-2]="column.type == 'STRING'">{{ column.name }}</span>
          <p-sortIcon [field]="column.sortKey"></p-sortIcon>
        </th>
        <th *ngIf="(column.sortKey ?? '').length <= 0" [style]="'width: ' + column.width ?? 'auto' + ';'">
          <span [class.ml-2]="column.type == 'STRING'">{{ column.name }}</span>
        </th>
      </ng-container>
    </tr>
    <tr *ngIf="filter && !loading && !isMobile">
      <th *ngIf="(buttons ?? []).length > 0"></th>
      <th [attr.colspan]="getColumns().length">
        <section class="w-full border-1 surface-border border-round-lg">
          <div class="flex w-full align-items-center justify-content-between px-1">
            <span class="p-input-icon-left w-full">
                <i class="pi pi-search text-500"></i>
                <input type="text" pInputText placeholder="{{ 'label.smartTable.search' | transloco: { default: 'Zoeken' } }}" class="w-full border-none shadow-none outline-none" [(ngModel)]="parameters.filter" (ngModelChange)="searchUpdated = true;" (focusout)="applySearch()" (keydown.enter)="applySearch()"/>
            </span>
          </div>
        </section>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
    <tr *ngIf="!loading">
      <td *ngIf="(getButtons(data, rowIndex) ?? []).length > 0">
        <div class="p-inputgroup" [class.flex]="isMobile" [class.justify-content-center]="isMobile">
          <ng-container *ngFor="let button of getVisibleButtons(data, rowIndex)">
            <button
              *ngIf="!getRowDataFromButton(button, rowIndex, data).loading"
              [disabled]="getRowDataFromButton(button, rowIndex, data).disabled"
              [label]="button.label"
              pButton
              pRipple
              [icon]="button.icon"
              (click)="button.command ? button.command(data, this, button, rowIndex) : null"
              class="{{ 'doffice-table-button p-button-sm mt-1 mb-1' + (button.color ? ' p-button-' + button.color : 'p-button-primary') }}"
              [class.width-40]="(button.label ?? '').length <= 0"
            ></button>
            <button
              *ngIf="getRowDataFromButton(button, rowIndex, data).loading"
              [disabled]="getRowDataFromButton(button, rowIndex, data).disabled"
              [label]="button.label"
              [loading]="true"
              pButton
              pRipple
              [icon]="button.icon"
              class="{{ 'doffice-table-button p-button-sm mt-1 mb-1' + (button.color ? ' p-button-' + button.color : 'p-button-primary') }}"
              [class.width-40]="(button.label ?? '').length <= 0"
            ></button>
          </ng-container>
        </div>
      </td>
      <td *ngFor="let column of getColumns()" [style]="'width: ' + column.width ?? 'auto' + ';'">
        <ng-container *ngIf="!isMobile">
          <ng-container *ngIf="!column.type || column.type == 'STRING' || column.type == 'DATE'"><span>{{ getValue(data, column) }}</span></ng-container>
          <ng-container *ngIf="column.type == 'BOOLEAN'"><i class="pi" [ngClass]="{ 'text-green-500 pi-check-circle': getValue(data, column), 'text-red-500 pi-times-circle': !getValue(data, column) }"></i></ng-container>
          <ng-container *ngIf="column.type == 'TAG'">
            <p-tag class="row-tag" class="doffice-table-tag" [severity]="getTagColor(data, column)" [value]="getValue(data, column)"></p-tag>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="isMobile">
          <div style="display: grid; column-gap: 10px; grid-template-columns: 1fr 1fr; align-items: center" class="w-full">
            <span
            ><b>{{ column.name }}</b></span
            >
            <span>
              <ng-container *ngIf="!column.type || column.type == 'STRING' || column.type == 'DATE'"
              ><span [class.w-full]="isMobile" [class.text-center]="isMobile">{{ getValue(data, column) }}</span></ng-container
              >
              <ng-container *ngIf="column.type == 'BOOLEAN'"><i class="pi" [class.w-full]="isMobile" [ngClass]="{ 'text-green-500 pi-check-circle': getValue(data, column), 'text-red-500 pi-times-circle': !getValue(data, column) }"></i></ng-container>
              <ng-container *ngIf="column.type == 'TAG'">
                <p-tag class="row-tag" class="doffice-table-tag" [class.w-full]="isMobile" [class.text-center]="isMobile" [severity]="getTagColor(data, column)" [value]="getValue(data, column)"></p-tag>
              </ng-container>
            </span>
          </div>
        </ng-container>
      </td>
    </tr>
    <ng-container *ngIf="loading">
      <tr *ngFor="let index of getSkeletonRows()">
        <td *ngFor="let column of getColumns()">
          <p-skeleton></p-skeleton>
        </td>
      </tr>
    </ng-container>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr *ngIf="!loading">
      <td colspan="20" class="text-center">{{ emptyMessage }}</td>
    </tr>
    <ng-container *ngIf="loading">
      <tr *ngFor="let index of getSkeletonRows()">
        <td *ngFor="let column of getColumns()">
          <p-skeleton></p-skeleton>
        </td>
      </tr>
    </ng-container>
  </ng-template>
</p-table>
<div class="variables mt-4" *ngIf="hasVariables() && !isMobile">
  <doffice-checkbox-group>
    <doffice-checkbox *ngFor="let key of getBooleanVariableKeys()" [(ngModel)]="variables[key].value" (ngModelChange)="variableChanged()" [label]="variables[key].label"></doffice-checkbox>
  </doffice-checkbox-group>
</div>
<div class="globals mt-4 justify-content-center flex align-items-center" *ngIf="globalResults.length > 0">
  <ng-container *ngFor="let globalResult of globalResults; let last = last">
    <p-tag *ngIf="globalResult.loading" [class.mr-2]="!last" [severity]="globalResult.severity"
    ><span>{{ globalResult.label }}:</span> <span class="ml-1 mt-1"><p-progressSpinner styleClass="w-1rem h-1rem" strokeWidth="8"></p-progressSpinner></span
    ></p-tag>
    <p-tag *ngIf="!globalResult.loading" [class.mr-2]="!last" [severity]="globalResult.severity">{{ globalResult.label }}: {{ globalResult.value }}</p-tag>
  </ng-container>
</div>
