import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import _ from 'lodash';
import SignaturePad from 'signature_pad';
import { cloneCanvas, trimCanvas } from 'app/shared/logic/system/canvas.logic';

@Component({
  selector: 'doffice-request-signature-modal',
  templateUrl: 'request-signature-modal.component.html',
  styleUrls: ['request-signature-modal.component.scss'],
})
export class RequestSignatureModalComponent implements AfterViewInit {
  @ViewChild('SignaturePad') signaturePad: SignaturePad;
  @ViewChild('SignatureContainer') signatureContainer: ElementRef;

  private _visible = new BehaviorSubject<boolean>(true);
  get visible$() {
    return this._visible.asObservable();
  }
  get visible() {
    return this._visible.getValue();
  }
  set visible(visible: boolean) {
    this._visible.next(visible);
  }

  confirmed$ = new Subject<{
    name: string;
    signature: string;
  }>();

  signaturePadOptions: any = {};

  signatureCanvasInited = false;
  showSignatureName = true;
  name = '';
  signature = '';
  blocked = false;

  ogSignature = '';
  resetted = false;

  get isSignature() {
    return (this.signature ?? '').trim().length > 0;
  }

  constructor(private cdr: ChangeDetectorRef) {
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 10);
  }

  ngAfterViewInit(): void {
    if (!this.ogSignature) {
      this.initSignature();
    }
  }

  cancelClicked() {
    this._visible.next(false);
  }

  initSignature() {
    this.signaturePadOptions = {
      minWidth: 1,
      canvasWidth: this.signatureContainer.nativeElement.offsetWidth,
      canvasHeight: 300,
    };

    this.signatureCanvasInited = true;
  }

  confirmSignature(): void {
    this._visible.next(false);
    this.confirmed$.next({
      name: this.name,
      signature: this.signature,
    });
  }

  clearSignature() {
    this.signaturePad.clear();
  }

  deleteSignature(): void {
    this._visible.next(false);
    this.confirmed$.next({
      name: null,
      signature: null,
    });
  }

  drawComplete() {
    let tmpCanvas = cloneCanvas(this.signaturePad.signaturePad._canvas);
    let newCanvas = trimCanvas(tmpCanvas);
    this.signature = newCanvas.toDataURL('image/png');
  }
}
