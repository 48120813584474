import {Injectable} from '@angular/core';
import {Address} from 'app/shared/models/relation/address';
import {MutationInputObject} from 'app/shared/models/system/mutation-object';
import _ from 'lodash';
import {IAddressMutationInput} from "../graphql/generated/types";

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  createAddressMutations(addresses: Address[], originalAddresses: Address[] = null): MutationInputObject<IAddressMutationInput> {
    if (_.isNil(originalAddresses)) {
      addresses.forEach((contact) => {
        contact.id == null;
      });
    }

    const input: MutationInputObject<IAddressMutationInput> = {deletes: [], updates: []};

    const newAddress = addresses.filter((address) => _.isNil(address.id))
      .map((address) => address.convertToInput());
    const updatedAddress = addresses
      .filter((address) => !_.isNil(address.id))
      .map((address) => address.convertToInput())
      .filter((address) => !_.isEqual(address, originalAddresses?.find((s) => s.id == address.id)
        ?.convertToInput()));
    input.updates = _.concat(newAddress, updatedAddress)
      .map((address) => {
        return {
          id: address.id,
          address: address,
        };
      });

    input.deletes = _.differenceBy(
      originalAddresses,
      addresses.filter((address) => !_.isNil(address.id)),
      (address) => address.id
    )
      .map((address) => +address.id);

    return input;
  }
}
