import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ModalButton } from '../models';
import _ from 'lodash';

@Component({
  selector: 'doffice-error-modal',
  templateUrl: 'error-modal.component.html',
  styleUrls: ['error-modal.component.scss'],
})
export class ErrorModalComponent {
  private _visible = new BehaviorSubject<boolean>(true);
  get visible$() {
    return this._visible.asObservable();
  }
  get visible() {
    return this._visible.getValue();
  }
  set visible(visible: boolean) {
    this._visible.next(visible);
  }

  buttons: ModalButton[] = [];

  title: string; 
  body: string;
  icon = 'pi pi-times';

  constructor(private cdr: ChangeDetectorRef) {
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 10);
  }

  onCloseRequest() {
    this._visible.next(false);
  }
}
