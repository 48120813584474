import {Language} from '../general/language';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {IPartner} from "../../../core/graphql/generated/types";
import {GetDateTimeScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";
import {languages} from "../../data/languages";

export class Partner implements IPartner {
  id: number = 0;
  mail: string = '';
  name: string = '';
  masterId: number = 0;
  activated: boolean = false;
  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  isReseller: boolean = false;
  isAccountancy: boolean = false;
  isSupport: boolean = false;
  isWhitelabel: boolean = false;
  lastLogin: string = "";
  private _lastLogin: Date;

  get LastLogin() {return this._lastLogin;}

  set LastLogin(date: Date) {
    this._lastLogin = date;
    this.created = SetDateTimeScalar(date);
  }

  languageId: number = 0;
  language: Language;
  discountRate: number = 0;
  uninvoicedLicensesAmount: number = 0;
  whitelabel: string = "doffice";

  constructor(partner: IPartner = null) {
    if (partner === null) {
      return;
    }

    constructFromInterface(this, partner);

    if (partner.lastLogin) {this._lastLogin = GetDateTimeScalar(partner.lastLogin);}
    if (partner.created) {this._created = GetDateTimeScalar(partner.created);}
    if (partner.updated) {this._updated = GetDateTimeScalar(partner.updated);}
    if (partner.languageId) {this.language = languages.find(l => l.id == partner.languageId);}
  }
}
