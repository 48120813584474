import {environment} from "../../../environments/environment";

export function getWhitelabelName() {
  const origin = window.location.origin;

  const whitelabels = environment.whitelabels;
  const usedWhitelabel = Object.keys(whitelabels).find(key => (whitelabels[key] as WhitelabelEnvironment).app.url === origin);

  return usedWhitelabel ?? "doffice";
}

export function getWhitelabelByEnvironmentName(name: string) {
  return environment.whitelabels[name] as WhitelabelEnvironment;
}

export function getAllWhitelabelNames() {
  return Object.keys(environment.whitelabels);
}

export function getWhitelabelEnvironment() {
  const name = getWhitelabelName();
  return environment.whitelabels[name] as WhitelabelEnvironment;
}

export interface WhitelabelEnvironment {
  name: string,
  app: {
    url: string,
    logo: string,
    accentColor: string,
    systemEmail: string
    enableCatchallMailboxes: boolean,
    enableSelfLicenseUpgrade: boolean,
    support: {
      website: string,
      email: string,
      call: string,
      whatsapp: string,
      messenger: string
    }
  },
  api: {
    url: string,
    ws: string
  },
  azure: {
    redirectUri: string
  },
  exactOnline: {
    redirectUri: string;
  }
}
