import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subject, filter, fromEvent, takeUntil, tap} from 'rxjs';
import {Subscriber} from 'app/shared/models/entity/subscriber';
import {AuthService} from 'app/core/services/auth.service';

import {translate} from '@jsverse/transloco';
import {marker} from '@nyffels/transloco-keys-manager/marker';
import {FieldButton} from '../../field-buttons/field-buttons.component';
import {GraphqlService} from "../../../../core/services/graphql.service";
import {AuthenticationClient, SwaggerBase} from "../../../../core/swagger/generated/swagger-client";

@Component({
  selector: 'doffice-change-subscriber-modal',
  templateUrl: 'change-subscriber-modal.component.html',
  styleUrls: ['change-subscriber-modal.component.scss'],
})
export class ChangeSubscriberModalComponent implements OnDestroy, OnInit {
  private _visible = new BehaviorSubject<boolean>(true);

  get visible$() {
    return this._visible.asObservable();
  }

  get visible() {
    return this._visible.getValue();
  }

  set visible(visible: boolean) {
    this._visible.next(visible);
  }

  subscribers: Subscriber[] = [];
  subscribersLoading = true;

  private _destroy$ = new Subject<void>();

  stepItems = [
    {
      label: translate(marker('label.modal.change.subscriberRequest.subscriber', 'Abonnee')),
    },
    {
      label: translate(marker('label.modal.change.subscriberRequest.authentication', 'Authenticatie')),
    },
  ];
  stepIndex = 0;

  selectedSubscriber: number;
  password: string;
  switching = false;

  selectSubscriberButtons: FieldButton[] = [
    {
      disabled: (subscriber) => this.isCurrentSubscriber(subscriber.id),
      icon: 'pi pi-arrow-right-arrow-left',
      click: (subscriber) => this.pickSubscriber(subscriber.id)
    }
  ]

  constructor(
    private authService: AuthService,
    private cdr: ChangeDetectorRef,

    private graphqlService: GraphqlService,
    private authenticationClient: AuthenticationClient
  ) {
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 10);

    this.authenticationClient.listSubscribersByLogin(this.authService.activeUserSnapshot.mail)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (res) => {
          this.subscribers = res.map(x => new Subscriber(x as any));
          this.subscribersLoading = false;

          this.cdr.detectChanges();
        },
        error: (err) => {
          alert(err);
          console.error(err);
        },
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  ngOnInit(): void {
    fromEvent(document, 'keydown')
      .pipe(
        takeUntil(this._destroy$),
        filter((keyEvent: KeyboardEvent) => keyEvent.code === 'Enter' && !keyEvent.shiftKey && !keyEvent.ctrlKey)
      )
      .subscribe({
        next: (keyEvent: KeyboardEvent) => {
          if (this.stepIndex == 1) {
            keyEvent.preventDefault();
            this.loginClicked();
          }
        },
        error: (err) => {
          console.error(err);
        },
      });
  }

  cancelClicked() {
    this._visible.next(false);
  }

  isCurrentSubscriber(id: number) {
    return this.authService.activeSubscriberSnapshot.id == id;
  }

  pickSubscriber(id: number) {
    this.stepIndex = 1;
    this.selectedSubscriber = id;

    this.cdr.detectChanges();
  }

  loginClicked() {
    this.switching = true;

    SwaggerBase.enableBasicAuth = true;
    SwaggerBase.basicAuthLogin = this.authService.activeUserSnapshot.mail;
    SwaggerBase.basicAuthPassword = this.password
    this.authenticationClient.requestTokenByBasicAuth(this.selectedSubscriber)
      .pipe(
        tap(() => {
          SwaggerBase.enableBasicAuth = false;
        }),
        takeUntil(this._destroy$),
        this.authService.registerReceivedClientToken()
      )
      .subscribe({
        next: () => {
          window.location.reload();
        },
        error: (err) => {
          const throwErrorMessage = (err) => {
            console.log('Unknown error at login:');
            console.log(err);

            alert(translate(marker('label.modal.change.subscriberRequest.unknownErrorOnLogin', 'Onverwachte fout tijdens het inloggen. Contacteer support of probeer later opnieuw.')));

            this.switching = false;
            this.cdr.detectChanges();
          };

          try {
            const error = JSON.parse(err.error.message);

            switch (error.message) {
              case 'API_ERR_PASSWORD_INCORRECT':
                alert(translate(marker('label.modal.change.subscriberRequest.incorrectPasswordError', 'Onjuist wachtwoord')));
                this.password = '';
                this.switching = false;
                this.cdr.detectChanges();
                return;
              default:
                throwErrorMessage(err);
                break;
            }
          } catch {
            throwErrorMessage(err);
          }
        },
      });
  }
}
