import {Injectable} from '@angular/core';
import {Product} from 'app/shared/models/article/product';
import _ from 'lodash';
import {ArticlePriceService} from './article-price.service';
import {IProductMutationInput} from "../graphql/generated/types";

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private articlePriceService: ArticlePriceService) {}

  public createProductMutationInput(product: Product, originalProduct: Product = null): IProductMutationInput {
    let mutationInput: IProductMutationInput = {id: product.id, product: null, prices: null};
    mutationInput.product = _.isEqual(product.convertToInput(), originalProduct ? originalProduct.convertToInput() : null) ? null : product.convertToInput();
    mutationInput.prices = this.articlePriceService.createArticlePriceMutations(product.prices ?? [], originalProduct ? originalProduct.prices : []);

    return mutationInput;
  }
}
