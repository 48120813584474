import {constructFromInterface} from "app/core/logic/map.logic";
import {IRole} from "../../../core/graphql/generated/types";


export class Role implements IRole {
  id: string = '';
  name: string = '';
  description: string = '';
  inheritance: string[] = [];
  group: string = '';

  constructor(role: IRole) {
    constructFromInterface(this, role);
  }
}
