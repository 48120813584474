import _ from 'lodash';

export type ElementType<T extends Iterable<any>> = T extends Iterable<infer E> ? E : never;

export function constructFromInterface<target, source>(tObject: target, sObject: source) {
  if (_.isNil(sObject)) {
    return null;
  }
  const keys = Object.keys(tObject);
  keys.forEach((key) => {
    switch ((typeof tObject[key]).toLowerCase()) {
      case 'number':
        tObject[key] = !_.isNil(sObject[key]) ? +sObject[key] : null;
        break;
      case 'string':
        tObject[key] = !_.isNil(sObject[key]) ? '' + sObject[key] : null;
        break;
      case 'boolean':
        tObject[key] = !_.isNil(sObject[key]) ? !!sObject[key] : null;
        break;
      case 'object':
        if (!_.isNil(sObject[key]) && !_.isNil(tObject[key])) {
          try {
            switch (tObject[key].constructor.name.toLowerCase()) {
              case 'string':
                tObject[key] = !_.isNil(sObject[key]) ? '' + sObject[key] : null;
                break;
              case 'number':
                tObject[key] = !_.isNil(sObject[key]) ? '' + sObject[key] : null;
                break;
              case 'boolean':
                tObject[key] = !_.isNil(sObject[key]) ? !!sObject[key] : null;
                break;
              case 'date':
                tObject[key] = !_.isNil(sObject[key]) ? new Date(sObject[key]) : null;
                break;
              case 'array':
                if (sObject[key].length > 0) {
                  switch (sObject[key].find((x) => true).constructor.name.toLowerCase()) {
                    case 'number':
                      tObject[key] = sObject[key].map((x) => (!_.isNil(x) ? +x : null));
                      break;
                    case 'string':
                      tObject[key] = sObject[key].map((x) => (!_.isNil(x) ? '' + x : null));
                      break;
                    case 'boolean':
                      tObject[key] = sObject[key].map((x) => (!_.isNil(x) ? !!x : null));
                      break;
                    default:
                      // console.log('Unknown array: ' + key);
                  }
                } else {
                  tObject[key] = [];
                }
                break;
              default:
                if (tObject[key] !== null && tObject[key].constructor.name.toLowerCase() != 'object') {
                  console.log('Missing variable found: ' + tObject[key].constructor.name.toLowerCase());
                }

                // TODO Construct object from names object.new() is required!
                // TODO Construct object for arrays
                tObject[key] = null;
                break;
            }
          } catch (err) {
            // TODO Remove
            console.log(key);
            console.log(err);
          }
        } else {
          tObject[key] = null;
        }
        break;
    }
  });
}

export function ObjectFetch<T = any>(object: any, path: string[], defaultValue: T = null, mutateResult: (value) => T = null, preventMutateOnDefault = false) {
  let defaultUsed = false;

  let value = _.cloneDeep(object);
  try {
    for (let p of path) {
      value = value[p];
    }
  } catch {
    value = null;
  }

  if (_.isNil(value)) {
    defaultUsed = true;
    value = defaultValue;
  }

  if (mutateResult && (!defaultUsed || (defaultUsed && !preventMutateOnDefault))) {
    value = mutateResult(value);
  }

  return value as T;
}
