<p-dialog  [(visible)]="visible" appendTo="body" [modal]="true" [breakpoints]="{ '960px': '75vw', '640px': '100vw' }" [style]="{ 'max-height': '90vh', 'overflow': 'auto', width: '60vw' }" [closable]="false">
  <div class="grid formgrid p-fluid">
    <div class="field mb-4 col-12">
      <label for="contactName" class="font-medium text-900">{{ 'label.relation.name' | transloco: { default: 'Naam' } }}</label>
      <div class="p-inputgroup">
        <input id="contactName" type="text" pInputText [(ngModel)]="contact.name" />
      </div>
    </div>

    <div class="field mb-4 col-12 lg:col-6">
      <label for="contactName" class="font-medium text-900">{{ 'label.relation.phonenumber' | transloco: { default: 'Telefoonnummers' } }}</label>
      <div class="p-inputgroup" >
        <input id="contactName" type="text" pInputText [(ngModel)]="contact.phone" />
      </div>
    </div>

    <div class="field mb-4 col-12 lg:col-6">
      <label for="contactName" class="font-medium text-900">{{ 'label.relation.email' | transloco: { default: 'E-mailadres' } }}</label>
      <div class="p-inputgroup">
        <input id="contactName" type="text" pInputText [(ngModel)]="contact.email" />
      </div>
    </div>

    <doffice-checkbox-group class="mb-4 col-12">
      <doffice-checkbox label="{{ 'type.salesInvoice.name' | transloco: { default: 'Verkoopsfactuur' } }}" [(ngModel)]="contact.emailIsInvoice"></doffice-checkbox>
      <doffice-checkbox label="{{ 'type.salesOrder.name' | transloco: { default: 'Bestelling' } }}" [(ngModel)]="contact.emailIsOrder"></doffice-checkbox>
      <doffice-checkbox label="{{ 'type.salesQuotation.name' | transloco: { default: 'Offerte' } }}" [(ngModel)]="contact.emailIsQuote"></doffice-checkbox>
      <doffice-checkbox label="{{ 'type.salesInvoiceReminder.name' | transloco: { default: 'Betaalherinnering' } }}" [(ngModel)]="contact.emailIsInvoiceReminder"></doffice-checkbox>
      <doffice-checkbox label="{{ 'type.workperformance.name' | transloco: { default: 'Werkprestatie' } }}" [(ngModel)]="contact.emailIsWorkperformance"></doffice-checkbox>
    </doffice-checkbox-group>
    <doffice-checkbox-group class="mb-4 col-12">
      <doffice-checkbox label="{{ 'label.relation.useEmailWhenNotSelectedAsContact' | transloco: { default: 'E-mailadres gebruiken wanneer de contact niet geselecteerd is.' } }}" [(ngModel)]="contact.emailUseWhenNotSelected"></doffice-checkbox>
    </doffice-checkbox-group>
  </div>
  <ng-template pTemplate="footer">
    <div class="border-top-1 surface-border pt-3">
      <button pButton pRipple (click)="cancelClicked()" class="p-button-sm mr-2 p-button-text p-button-danger" label="{{ 'label.general.cancel' | transloco: { default: 'Annuleren' } }}"></button>
      <button pButton pRipple icon="pi pi-check" [disabled]="(contact.name ?? '').length <= 0" [loading]="saveLoading" (click)="saveClicked()" class="p-button-sm p-button-success" label="{{ 'label.general.saveAndUse' | transloco: { default: 'Opslaan en gebruiken' } }}"></button>
    </div>
  </ng-template>
</p-dialog>
