import {Injectable} from '@angular/core';
import {Bank} from 'app/shared/models/financial/bank';
import _ from 'lodash';
import {IBankMutationInput} from "../graphql/generated/types";

@Injectable({
  providedIn: 'root',
})
export class FinancialBankService {
  public createBankMutationInput(bank: Bank, originalBank: Bank = null): IBankMutationInput {
    let mutationInput: IBankMutationInput = {id: bank.id, bank: null};
    mutationInput.bank = _.isEqual(bank.convertToInput(), originalBank ? originalBank.convertToInput() : null) ? null : bank.convertToInput();

    return mutationInput;
  }
}
