import {Injectable} from '@angular/core';
import {MutationInputObject} from 'app/shared/models/system/mutation-object';
import _ from 'lodash';
import {IEntityBankAccountMutationInput} from "../graphql/generated/types";
import {EntityBankAccount} from "../../shared/models/relation/entity-bank-account";

@Injectable({
  providedIn: 'root',
})
export class RelationBankAccountService {
  createBankAccountMutations(accounts: EntityBankAccount[], originalAccounts: EntityBankAccount[] = null): MutationInputObject<IEntityBankAccountMutationInput> {
    if (_.isNil(originalAccounts)) {
      accounts.forEach((account) => {
        account.id == null;
      });
    }

    const input: MutationInputObject<IEntityBankAccountMutationInput> = {deletes: [], updates: []};

    const newAccount = accounts.filter((account) => _.isNil(account.id))
      .map((account) => account.convertToInput());
    const updatedAccount = accounts
      .filter((account) => !_.isNil(account.id))
      .map((account) => account.convertToInput())
      .filter((account) => !_.isEqual(account, originalAccounts?.find((s) => s.id == account.id)
        ?.convertToInput()));
    input.updates = _.concat(newAccount, updatedAccount)
      .map((account) => {
        return {
          id: account.id,
          bankAccount: account,
        };
      });

    input.deletes = _.differenceBy(
      originalAccounts,
      accounts.filter((phone) => !_.isNil(phone.id)),
      (account) => account.id
    )
      .map((account) => +account.id);

    return input;
  }
}
