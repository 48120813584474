import {TranslationInjector} from 'app/app.module';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {TranslationService} from 'app/core/services/translation.service';
import {ILicense} from "../../../core/graphql/generated/types";


export class License implements ILicense {
  id: string = '';
  name: string = '';
  description: string = '';
  price: number = 0;
  documentLimit: number = 0;
  userLimit: number = 0;
  userFree: number = 0;
  userCost: number = 0;
  yearDiscountPercentage: number = 0;
  active: boolean = false;

  get Name() {
    return TranslationInjector.get(TranslationService)
      .getValueByKey(this.name);
  }

  constructor(license: ILicense = null) {
    if (license === null) {
      return;
    }

    constructFromInterface(this, license);
  }
}

export enum PaymentMethod {
  ONLINE = 1,
  INVOICE = 2,
  CREDIT = 3,
}
