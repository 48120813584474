import _ from "lodash";
import moment from "moment";

export function GetDateScalar(value: string) {
  if (_.isNil(value)) {
    return null;
  }

  if (!moment(value, "YYYY-MM-DD")
    .isValid()) {
    console.warn("Invalid Date Scalar: " + value);
    return null;
  }

  return moment(value)
    .toDate();
}

export function SetDateScalar(value: Date) {
  if (_.isNil(value)) {
    return null;
  }

  if (!moment(value)
    .isValid()) {
    console.warn("Invalid Date entry: " + value);
    return null;
  }

  return moment(value)
    .format("YYYY-MM-DD");
}

export function GetDateTimeScalar(value: string) {
  if (_.isNil(value)) {
    return null;
  }

  if (!moment(value)
    .isValid()) {
    console.warn("Invalid DateTime Scalar: " + value);
    return null;
  }

  // TODO Convert to current timezone
  return moment(value)
    .toDate();
}

export function SetDateTimeScalar(value: Date) {
  if (_.isNil(value)) {
    return null;
  }

  if (!moment(value)
    .isValid()) {
    console.warn("Invalid DateTime entry: " + value);
    return null;
  }

  // TODO Timezone?
  return moment(value)
    .toISOString(false);
}
