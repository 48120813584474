import { ChangeDetectorRef, Component } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import {DirectionEnum, TableColumn, TableOrder, TableRowButton, TableVariables} from '../../table/models';

import { translate } from '@jsverse/transloco';
import { marker } from '@nyffels/transloco-keys-manager/marker';
import { Service } from 'app/shared/models/article/service';
import {GraphqlService} from "../../../../core/services/graphql.service";

@Component({
  selector: 'doffice-search-service-modal',
  templateUrl: 'search-service-modal.component.html',
  styleUrls: ['search-service-modal.component.scss'],
})
export class SearchServiceModalComponent {
  private _visible = new BehaviorSubject<boolean>(true);
  get visible$() {
    return this._visible.asObservable();
  }
  get visible() {
    return this._visible.getValue();
  }
  set visible(visible: boolean) {
    this._visible.next(visible);
  }

  source = this.graphqlService.queries.article.service.list;
  variables: TableVariables = {};
  columns: TableColumn[] = [
    {
      id: 'sku',
      name: translate(marker('table.column.article.sku', 'SKU')),
      keys: ['sku'],
      sortKey: 'sku'
    },
    {
      id: 'name',
      name: translate(marker('table.column.article.name', 'Naam')),
      keys: ['name'],
      sortKey: 'name'
    },
    {
      id: 'categoryName',
      name: translate(marker('table.column.article.category', 'Categorie')),
      keys: ['category', 'name'],
    },
  ];
  order: TableOrder = {
    direction: DirectionEnum.ASC,
    key: 'name',
  };
  buttons: TableRowButton[] = [
    {
      icon: 'pi pi-check',
      color: 'success',
      label: translate(marker('label.base.select', 'Selecteren')),
      command: (row: Service) => {
        this.selected$.next(row.id);
        this._visible.next(false);
      },
    },
  ];
  addNewButton = false;

  selected$ = new Subject<number>();
  new$ = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef, private graphqlService: GraphqlService) {
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 10);
  }

  cancelClicked() {
    this._visible.next(false);
  }

  newClicked() {
    this._visible.next(false);
    this.new$.next();
  }
}
