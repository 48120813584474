import {TranslationInjector} from 'app/app.module';
import {TranslationService} from 'app/core/services/translation.service';
import {MutationInputObject} from 'app/shared/models/system/mutation-object';
import _ from 'lodash';
import {Product} from './product';
import {Service} from './service';
import {ArticleCategory} from '../parameters/article-category';
import {ArticleTypeEnum} from './articletype.enum';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {ICombinedArticle, ICombinedArticleInput, ICombinedArticleMutationInput, ICombinedArticleSubline, ICombinedArticleSublineInput, ICombinedArticleSublineMutationInput, IProduct, IService} from "../../../core/graphql/generated/types";
import {GetDateTimeScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";

export class CombinedArticle implements ICombinedArticle {
  id: number = 0;
  subscriber_id: number = 0;
  sku: string = '';
  name: string = '';
  description: string = '';

  get FullName(): string {
    if (this.sku) {
      return `${this.name} (${this.sku})`;
    } else {
      return this.name;
    }
  }

  artcat_id: number = 0;
  category: ArticleCategory;
  salesprice: number = 0;
  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  archived: boolean = false;
  hideSublinesOnInvoice: boolean = false;
  defaultUnit: string = '';
  importDescriptionOnDocument: boolean = false;

  sublines: CombinedArticleSubline[] = [];

  get Validation(): boolean {
    return this.name && this.name.trim().length > 0;
  }

  autogenerateSku(): void {
    this.sku = this.name.toLowerCase()
      .replace(/ /g, '-');
  }

  constructor(combinedArticle: ICombinedArticle = null) {
    if (combinedArticle === null) {
      return;
    }

    constructFromInterface(this, combinedArticle);

    if (combinedArticle.created) {this._created = GetDateTimeScalar(combinedArticle.created);}
    if (combinedArticle.updated) {this._updated = GetDateTimeScalar(combinedArticle.updated);}
    if (combinedArticle.category) {
      this.category = new ArticleCategory(combinedArticle.category);
    }
    if (combinedArticle.sublines) {
      this.sublines = combinedArticle.sublines.map((x) => new CombinedArticleSubline(x));
    }
  }

  convertToInput(): ICombinedArticleInput {
    return {
      id: this.id,
      sku: this.sku,
      name: this.name,
      description: this.description,
      artcat_id: +this.artcat_id,
      salesprice: +this.salesprice,
      archived: this.archived,
      hideSublinesOnInvoice: this.hideSublinesOnInvoice !== null ? !!this.hideSublinesOnInvoice : null,
      defaultUnit: this.defaultUnit,
      importDescriptionOnDocument: this.importDescriptionOnDocument,
    };
  }

  createMutationInput(sublines: MutationInputObject<CombinedArticleSubline>, originalCombinedArticle: CombinedArticle): ICombinedArticleMutationInput {
    let mutationInput: ICombinedArticleMutationInput = {id: this.id, combinedArticle: null, combinedArticleSublines: null};
    mutationInput.combinedArticle = _.isEqual(this.convertToInput(), originalCombinedArticle?.convertToInput()) ? null : this.convertToInput();

    /* Set sublines */
    mutationInput.combinedArticleSublines = {
      deletes: sublines.deletes.map((x) => Number(x)),
      updates: sublines.updates.map((x) => {
        return {
          id: x.id,
          combinedArticleSubline: x.convertToInput(),
        } as ICombinedArticleSublineMutationInput;
      }),
    };

    return mutationInput;
  }

  public static new(): CombinedArticle {
    return new CombinedArticle({
      id: null,
      subscriber_id: null,
      sku: null,
      name: null,
      description: null,
      artcat_id: null,
      category: null,
      salesprice: null,
      created: null,
      updated: null,
      archived: false,
      sublines: [],
      hideSublinesOnInvoice: false,
      defaultUnit: null,
      importDescriptionOnDocument: false,
    });
  }
}

export class CombinedArticleSubline implements ICombinedArticleSubline {
  id: number = 0;
  subscriber_id: number = 0;
  combart_id: number = 0;
  art_typeid: ArticleTypeEnum = ArticleTypeEnum.PRODUCT;

  get ArtType(): string {
    switch (this.art_typeid) {
      case ArticleTypeEnum.PRODUCT:
        return TranslationInjector.get(TranslationService)
          .getValueByKey('UI_PRODUCT');
      case ArticleTypeEnum.SERVICE:
        return TranslationInjector.get(TranslationService)
          .getValueByKey('UI_SERVICE');
      case ArticleTypeEnum.COMBINEDARTICLE:
        return TranslationInjector.get(TranslationService)
          .getValueByKey('UI_COMBINED_ARTICLE');
    }
  }

  get ArtTypeSku(): string {
    switch (this.art_typeid) {
      case ArticleTypeEnum.PRODUCT:
        return TranslationInjector.get(TranslationService)
          .getValueByKey('UI_PRODUCT_SKU');
      case ArticleTypeEnum.SERVICE:
        return TranslationInjector.get(TranslationService)
          .getValueByKey('UI_SERVICE_SKU');
      case ArticleTypeEnum.COMBINEDARTICLE:
        return TranslationInjector.get(TranslationService)
          .getValueByKey('UI_COMBINED_ARTICLE_SKU');
    }
  }

  get ArtTypeName(): string {
    switch (this.art_typeid) {
      case ArticleTypeEnum.PRODUCT:
        return TranslationInjector.get(TranslationService)
          .getValueByKey('UI_PRODUCT_NAME');
      case ArticleTypeEnum.SERVICE:
        return TranslationInjector.get(TranslationService)
          .getValueByKey('UI_SERVICE_NAME');
      case ArticleTypeEnum.COMBINEDARTICLE:
        return TranslationInjector.get(TranslationService)
          .getValueByKey('UI_COMBINED_ARTICLE_NAME');
    }
  }

  art_id: number = 0;
  art_name: string = '';
  art_sku: string = '';
  art_amount: number = 0;
  article: Product | Service;
  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  get Validation(): boolean {
    return this.art_typeid !== null && this.art_typeid > 0 && this.art_name && this.art_name.trim() != '' && this.art_amount !== null;
  }

  constructor(combinedArticleSubline: ICombinedArticleSubline = null) {
    if (combinedArticleSubline === null) {
      return;
    }

    constructFromInterface(this, combinedArticleSubline);

    if (combinedArticleSubline.article) {
      switch (this.art_typeid) {
        case ArticleTypeEnum.PRODUCT:
          this.article = new Product(combinedArticleSubline.article as IProduct);
          break;
        case ArticleTypeEnum.SERVICE:
          this.article = new Service(combinedArticleSubline.article as IService);
          break;
      }
    }
  }

  convertToInput(): ICombinedArticleSublineInput {
    return {
      id: this.id !== null ? +this.id : null,
      art_typeid: this.art_typeid !== null ? +this.art_typeid : null,
      art_id: this.art_id !== null ? +this.art_id : null,
      art_name: this.art_name !== null ? '' + this.art_name : null,
      art_sku: this.art_sku !== null ? '' + this.art_sku : null,
      art_amount: this.art_amount !== null ? +this.art_amount : null,
    };
  }

  public static new(): CombinedArticleSubline {
    return new CombinedArticleSubline({
      id: null,
      subscriber_id: null,
      combart_id: null,
      art_typeid: ArticleTypeEnum.PRODUCT,
      art_id: null,
      art_name: null,
      art_sku: null,
      art_amount: 1,
      article: null,
      created: null,
      updated: null,
    });
  }
}
