import {Injectable} from '@angular/core';
import {RelationParameter} from 'app/shared/models/relation/relation-parameter';
import {MutationInputObject} from 'app/shared/models/system/mutation-object';
import _ from 'lodash';
import {IRelationParameterMutationInput} from "../graphql/generated/types";

@Injectable({
  providedIn: 'root',
})
export class RelationParameterService {
  createParameterMutations(parameters: RelationParameter[], relationParameters: RelationParameter[] = null): MutationInputObject<IRelationParameterMutationInput> {
    if (_.isNil(relationParameters)) {
      parameters.forEach((parameter) => {
        parameter.id == null;
      });
    }

    const input: MutationInputObject<IRelationParameterMutationInput> = {deletes: [], updates: []};

    const newParameter = parameters.filter((parameter) => _.isNil(parameter.id))
      .map((parameter) => parameter.convertToInput());
    const updatedParameter = parameters
      .filter((parameter) => !_.isNil(parameter.id))
      .map((parameter) => parameter.convertToInput())
      .filter((parameter) => !_.isEqual(parameter, relationParameters?.find((s) => s.id == parameter.id)
        ?.convertToInput()));
    input.updates = _.concat(newParameter, updatedParameter)
      .map((parameter) => {
        return {
          id: parameter.id,
          parameter: parameter,
        };
      });

    input.deletes = _.differenceBy(
      relationParameters,
      parameters.filter((parameter) => !_.isNil(parameter.id)),
      (parameter) => parameter.id
    )
      .map((parameter) => +parameter.id);

    return input;
  }
}
