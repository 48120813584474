import _ from 'lodash';
import {TranslationService} from 'app/core/services/translation.service';
import {TranslationInjector} from 'app/app.module';
import moment from 'moment';
import {Address} from '../relation/address';
import {Relation} from '../relation/relation.class';
import {Country} from '../general/country';
import {Company} from '../entity/company';
import {AccountNumber} from '../ledger/account-number';
import {Email} from '../mail/email';
import {Creditnote, CreditnoteStatusEnum, CreditnoteStatusEnumLanguage,} from './creditnote';
import {Document} from '../general/document';
import {Transaction} from '../general/transaction';
import {LedgerExportTypeEnum} from '../ledger/ledger-export';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {Journal} from '../ledger/journal';
import {translate} from '@jsverse/transloco';
import {marker} from '@nyffels/transloco-keys-manager/marker';
import {VatService} from "../../../core/services/vat.service";

import {MutationInputObject} from "../system/mutation-object";
import {IDocumentMutationInput, IPurchaseCreditnote, IPurchaseCreditnoteInput, IPurchaseCreditnoteMutationInput, IPurchaseCreditnoteSubline, IPurchaseCreditnoteSublineInput, IPurchaseCreditnoteSublineMutationInput, ITransactionMutationInput} from "../../../core/graphql/generated/types";
import {GetDateScalar, GetDateTimeScalar, SetDateScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";
import {countries} from "../../data/country";

export class PurchaseCreditnote implements IPurchaseCreditnote {
  id: number = 0;
  subscriberId: number = 0;
  number: string = '';
  companyId: number = 0;
  companyName: string = '';
  companyVatNumber: string = '';
  companyAddressStreet: string = '';
  companyAddressNumber: string = '';
  companyAddressCity: string = '';
  companyAddressZip: string = '';
  companyAddressCounty: string = '';
  companyAddressCountryId: number = 0;
  companyAddresCountry: Country;

  get company_address(): Address {
    return new Address({
      id: null,
      street: this.companyAddressStreet,
      number: this.companyAddressNumber,
      city: this.companyAddressCity,
      zip: this.companyAddressZip,
      county: this.companyAddressCounty,
      country_id: this.companyAddressCountryId,
      country: this.companyAddresCountry,
      default: null,
      subscriber_id: this.subscriberId,
    });
  }

  company: Company;
  relationId: number = 0;
  relationName: string = '';
  relationVatNumber: string = '';
  relationAddressStreet: string = '';
  relationAddressNumber: string = '';
  relationAddressCity: string = '';
  relationAddressZip: string = '';
  relationAddressCounty: string = '';
  relationAddressCountryId: number = 0;
  relationAddressCountry: Country;

  get supplier_address(): Address {
    return new Address({
      id: null,
      street: this.relationAddressStreet,
      number: this.relationAddressNumber,
      city: this.relationAddressCity,
      zip: this.relationAddressZip,
      county: this.relationAddressCounty,
      country_id: this.relationAddressCountryId,
      country: this.relationAddressCountry,
      default: null,
      subscriber_id: this.subscriberId,
    });
  }

  relation: Relation;
  value: number = 0;
  vatValue: number = 0;
  valueIncl: number = 0;
  intnote: string = '';
  note: string = '';
  date: string = "";
  private _date: Date;

  get Date() {return this._date;}

  set Date(date: Date) {
    this._date = date;
    this.date = SetDateScalar(date);
  }

  status: CreditnoteStatusEnum = CreditnoteStatusEnum.CONCEPT;

  get Status(): string {
    return TranslationInjector.get(TranslationService)
      .getValueByKey(CreditnoteStatusEnumLanguage[CreditnoteStatusEnum[this.status]]);
  }

  created: string = "";
  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  exported: string = "";
  private _exported: Date;

  get Exported() {return this._exported;}

  set Exported(date: Date) {
    this._exported = date;
    this.exported = SetDateTimeScalar(date);
  }

  exportedType: LedgerExportTypeEnum = LedgerExportTypeEnum.PDF;

  get ExportedType(): string {
    switch (this.exportedType) {
      case LedgerExportTypeEnum.PDF:
        return translate(marker('label.ledgerExportType.email', 'E-mail'));
      case LedgerExportTypeEnum.OCTOPUS:
        return translate(marker('label.ledgerExportType.octopus', 'Octopus'));
      case LedgerExportTypeEnum.ADMISOL:
        return translate(marker('label.ledgerExportType.admisol', 'Admisol'));
      case LedgerExportTypeEnum.YUKI:
        return translate(marker('label.ledgerExportType.yuki', 'Yuki'));
      case LedgerExportTypeEnum.MANUAL:
        return translate(marker('label.ledgerExportType.manual', 'Manueel gemarkeerd als geëxporteerd'));
      case LedgerExportTypeEnum.EXACT:
        return translate(marker('label.ledgerExportType.exactApi', 'Exact'));
    }
  }

  journalId: number = 0;
  journal: Journal;
  vatCode: string = "";
  accountNumberId: number = 0;
  accountNumber: AccountNumber;
  sublines: PurchaseCreditnoteSubline[] = [];
  documents: Document[] = [];
  transactions: Transaction[] = [];
  emails: Email[] = [];

  get Valid(): boolean {
    return (
      this.number != null &&
      this.number.trim().length > 0 &&
      this.companyId != null &&
      this.companyId > 0 &&
      this.companyName != null &&
      this.companyName.trim().length > 0 &&
      this.companyVatNumber != null &&
      this.companyVatNumber.trim().length > 0 &&
      this.relationId != null &&
      this.relationId > 0 &&
      this.relationName != null &&
      this.relationName.trim().length > 0 &&
      this.value !== null &&
      this.vatValue !== null &&
      this.valueIncl !== null &&
      this.date !== null
    );
  }

  constructor(creditnote: IPurchaseCreditnote = null) {
    if (creditnote === null) {
      return;
    }

    constructFromInterface(this, creditnote);

    if (creditnote.date) {this._date = GetDateScalar(creditnote.date);}
    if (creditnote.created) {this._created = GetDateTimeScalar(creditnote.created);}
    if (creditnote.updated) {this._updated = GetDateTimeScalar(creditnote.updated);}
    if (creditnote.exported) {this._exported = GetDateTimeScalar(creditnote.exported);}
    if (creditnote.companyAddressCountryId) {
      this.companyAddresCountry = new Country(countries.find(x => x.id == creditnote.companyAddressCountryId));
    }
    if (creditnote.company) {
      this.company = new Company(creditnote.company);
    }
    if (creditnote.relationAddressCountryId) {
      this.relationAddressCountry = new Country(countries.find(x => x.id == creditnote.relationAddressCountryId));
    }
    if (creditnote.relation) {
      this.relation = new Relation(creditnote.relation);
    }
    if (creditnote.journal) {
      this.journal = new Journal(creditnote.journal);
    }
    if (creditnote.accountNumber) {
      this.accountNumber = new AccountNumber(creditnote.accountNumber);
    }
    if (creditnote.sublines) {
      this.sublines = creditnote.sublines.map((x) => new PurchaseCreditnoteSubline(x));
    }
    if (creditnote.documents) {
      this.documents = creditnote.documents.map((x) => new Document(x));
    }
    if (creditnote.transactions) {
      this.transactions = creditnote.transactions.map((x) => new Transaction(x));
    }
    if (creditnote.emails) {
      this.emails = creditnote.emails.map((x) => new Email(x));
    }
  }

  public calculateValue(): void {
    this.value = _.sumBy(this.sublines, (x) => +x.price);
    this.valueIncl = _.sumBy(this.sublines, (x) => +x.priceIncl);
    this.vatValue = _.sumBy(this.sublines, (x) => +x.vatprice);
  }

  public convertToInput(): IPurchaseCreditnoteInput {
    return {
      id: this.id,
      number: this.number,
      companyId: +this.companyId,
      companyName: this.companyName,
      companyVatNumber: this.companyVatNumber,
      companyAddressStreet: this.companyAddressStreet,
      companyAddressNumber: this.companyAddressNumber,
      companyAddressCity: this.companyAddressCity,
      companyAddressZip: this.companyAddressZip,
      companyAddressCounty: this.companyAddressCounty,
      companyAddressCountryId: +this.companyAddressCountryId,
      relationId: +this.relationId,
      relationName: this.relationName,
      relationVatNumber: this.relationVatNumber,
      relationAddressStreet: this.relationAddressStreet,
      relationAddressNumber: this.relationAddressNumber,
      relationAddressCity: this.relationAddressCity,
      relationAddressZip: this.relationAddressZip,
      relationAddressCounty: this.relationAddressCounty,
      relationAddressCountryId: +this.relationAddressCountryId,
      value: +this.value,
      vatValue: +this.vatValue,
      valueIncl: +this.valueIncl,
      intnote: this.intnote,
      note: this.note,
      date: SetDateScalar(this.date ? moment(this.date)
        .toDate() : null),
      status: +this.status,
      journalId: +this.journalId,
      vatCode: this.vatCode ? '' + this.vatCode : null,
      accountNumberId: this.accountNumberId ? +this.accountNumberId : null,
    };
  }

  public static new(): PurchaseCreditnote {
    return new PurchaseCreditnote({
      id: null,
      subscriberId: null,
      number: null,
      companyId: null,
      companyName: null,
      companyVatNumber: null,
      companyAddressStreet: null,
      companyAddressNumber: null,
      companyAddressCity: null,
      companyAddressZip: null,
      companyAddressCounty: null,
      companyAddressCountryId: null,
      relationId: null,
      relationName: null,
      relationVatNumber: null,
      relationAddressStreet: null,
      relationAddressNumber: null,
      relationAddressCity: null,
      relationAddressZip: null,
      relationAddressCounty: null,
      relationAddressCountryId: null,
      value: 0,
      vatValue: 0,
      valueIncl: 0,
      intnote: null,
      note: null,
      date: SetDateScalar(new Date()),
      status: CreditnoteStatusEnum.CONCEPT,
      created: null,
      updated: null,
      company: null,
      companyAddressCountry: null,
      relation: null,
      relationAddressCountry: null,
      documents: [],
      emails: [],
      sublines: [],
      transactions: [],
      journalId: null,
      exported: null,
      exportedType: null,
      vatCode: null,
      accountNumberId: null,
      accountNumber: null,
      journal: null,
    });
  }

  public createMutationInput(
    sublines: MutationInputObject<PurchaseCreditnoteSubline>,
    documents: MutationInputObject<Document>,
    transactions: MutationInputObject<Transaction>,
    originalCreditnote: PurchaseCreditnote = null
  ): IPurchaseCreditnoteMutationInput {
    let mutationInput: IPurchaseCreditnoteMutationInput = {id: this.id, creditnote: null, sublines: null, documents: null, transactions: null};
    mutationInput.creditnote = _.isEqual(this.convertToInput(), originalCreditnote?.convertToInput()) ? null : this.convertToInput(); /* Check if invoice is updated */

    /* Set sublines */
    mutationInput.sublines = {
      deletes: sublines.deletes.map((x) => Number(x)),
      updates: sublines.updates.map((x) => {
        return {
          id: x.id,
          subline: x.convertToInput(),
        } as IPurchaseCreditnoteSublineMutationInput;
      }),
    };

    /* Set documents */
    mutationInput.documents = {
      deletes: documents.deletes.map((x) => Number(x)),
      updates: documents.updates.map((x) => {
        return {
          id: x.id,
          document: x.convertToInput(),
        } as IDocumentMutationInput;
      }),
    };

    /* Set transactions */
    mutationInput.transactions = {
      deletes: transactions.deletes.map((x) => Number(x)),
      updates: transactions.updates.map((x) => {
        return {
          id: x.id,
          transaction: x.convertToInput(),
        } as ITransactionMutationInput;
      }),
    };

    return mutationInput;
  }
}

export class PurchaseCreditnoteSubline implements IPurchaseCreditnoteSubline {
  id: number = 0;
  subscriberid: number = 0;
  creditnote: PurchaseCreditnote;
  creditnoteId: number = 0;
  vatCode: string = "";
  name: string = '';
  amount: number = 0;
  accountNumberId: number = 0;
  accountNumber: AccountNumber;
  unitPrice: number = 0;
  price: number = 0;
  priceIncl: number = 0;
  vatprice: number = 0;
  note: string = '';
  created: string = "";
  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  order: number = 0;
  unit: string = '';

  get Valid(): boolean {
    return (
      this.name !== null && this.name.trim().length > 0 && this.amount !== null && this.unitPrice !== null && this.price !== null && !_.isNil(this.vatCode)
    );
  }

  get VatName() {
    return VatService.getLabel(this.vatCode);
  }

  constructor(subline: IPurchaseCreditnoteSubline) {
    if (subline === null) {
      return;
    }

    constructFromInterface(this, subline);

    if (subline.created) {this._created = GetDateTimeScalar(subline.created);}
    if (subline.updated) {this._updated = GetDateTimeScalar(subline.updated);}
    if (subline.creditnote) {
      this.creditnote = new PurchaseCreditnote(subline.creditnote);
    }
    if (subline.accountNumber) {
      this.accountNumber = new AccountNumber(subline.accountNumber);
    }
  }

  public convertToInput(): IPurchaseCreditnoteSublineInput {
    return {
      id: +this.id,
      vatCode: '' + this.vatCode,
      name: this.name,
      amount: +this.amount,
      unitPrice: +this.unitPrice,
      price: +this.price,
      priceIncl: +this.priceIncl,
      vatprice: +this.vatprice,
      note: this.note,
      order: +this.order,
      accountNumberId: +this.accountNumberId,
      unit: (this.unit ?? '').trim().length > 0 ? '' + this.unit : null,
    };
  }

  public static new(): PurchaseCreditnoteSubline {
    return new PurchaseCreditnoteSubline({
      id: null,
      subscriberId: null,
      creditnote: null,
      creditnoteId: null,
      vatCode: null,
      name: null,
      amount: 1,
      unitPrice: null,
      price: null,
      priceIncl: null,
      vatprice: null,
      note: null,
      created: null,
      updated: null,
      order: null,
      accountNumber: null,
      accountNumberId: null,
      unit: null,
    });
  }

  calculateSublineValuesFromAmount(): void {
    this.price = +(this.price ?? 0);
    this.priceIncl = +(this.priceIncl ?? 0);
    this.vatprice = +(this.vatprice ?? 0);
    this.unitPrice = +(this.unitPrice ?? 0);
    this.amount = +(this.amount ?? 1);

    const vat = VatService.Get(this.vatCode);
    this.unitPrice = this.unitPrice ?? 0;
    this.price = +(+this.unitPrice * +(this.amount ?? 0)).toFixed(2);
    this.vatprice = +(vat.percentage ? +this.price * (+vat.percentage / 100) : 0).toFixed(2);
    this.priceIncl = +(+this.price + +(this.vatprice ?? 0)).toFixed(2);
  }

  calculateSublineValuesFomUnitPrice(): void {
    this.price = +(this.price ?? 0);
    this.priceIncl = +(this.priceIncl ?? 0);
    this.vatprice = +(this.vatprice ?? 0);
    this.unitPrice = +(this.unitPrice ?? 0);
    this.amount = +(this.amount ?? 1);

    const vat = VatService.Get(this.vatCode);
    this.price = +(+this.unitPrice * +(this.amount ?? 0)).toFixed(2);
    this.vatprice = +(vat.percentage ? +this.price * (+vat.percentage / 100) : 0).toFixed(2);
    this.priceIncl = +(+this.price + +(this.vatprice ?? 0)).toFixed(2);
  }

  calculateSublineValuesFromRefundPrice(): void {
    this.price = +(this.price ?? 0);
    this.priceIncl = +(this.priceIncl ?? 0);
    this.vatprice = +(this.vatprice ?? 0);
    this.amount = +(this.amount ?? 1);

    const vat = VatService.Get(this.vatCode);
    this.unitPrice = +(+(this.amount ?? 0) == 0 ? 0 : +this.price / +this.amount).toFixed(2);
    this.vatprice = +(vat.percentage ? +this.price * (+vat.percentage / 100) : 0).toFixed(2);
    this.priceIncl = +(+this.price + +(this.vatprice ?? 0)).toFixed(2);
  }

  calculateSublineValuesFromRefundPriceIncl(): void {
    this.price = +(this.price ?? 0);
    this.priceIncl = +(this.priceIncl ?? 0);
    this.vatprice = +(this.vatprice ?? 0);
    this.unitPrice = +(this.unitPrice ?? 0);
    this.amount = +(this.amount ?? 1);

    const vat = VatService.Get(this.vatCode);
    this.price = +(vat.percentage ? +this.priceIncl / (+vat.percentage / 100 + 1) : +this.priceIncl).toFixed(2);
    this.vatprice = +(vat.percentage ? +this.price * (+vat.percentage / 100) : 0).toFixed(2);
    this.unitPrice = +(+(this.amount ?? 0) == 0 ? 0 : +this.price / +this.amount).toFixed(2);
  }
}
