import {ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewContainerRef} from '@angular/core';
import _ from 'lodash';
import {Table, TableLazyLoadEvent, TableModule} from 'primeng/table';
import {Observable, Subject, interval, takeUntil, fromEvent} from 'rxjs';
import {DirectionEnum, ListReturnObject, SmartTableGlobal, TableColumn, TableColumnClass, TableGlobalResult, TableOrder, TableParameters, TableRowButton, TableRowButtonRowLink, TableVariables} from './models';
import {numberToArray} from 'app/shared/logic/system/general.logic';
import {DeviceService} from 'app/core/services/device.service';
import {DofficeSmartTableFilterModal} from './smart-table-filter-modal.component';
import {FormsModule} from "@angular/forms";
import {ButtonModule} from "primeng/button";
import {CommonModule} from "@angular/common";
import {TranslocoModule} from "@jsverse/transloco";
import {TagModule} from "primeng/tag";
import {SkeletonModule} from "primeng/skeleton";
import {DofficeCheckboxModule} from "../checkbox/checkbox.module";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {InputGroupModule} from "primeng/inputgroup";
import {InputTextModule} from "primeng/inputtext";
import {InputNumberModule} from "primeng/inputnumber";
import {RippleModule} from "primeng/ripple";
import {DialogModule} from "primeng/dialog";

@Component({
  selector: 'doffice-smart-table',
  templateUrl: './smart-table.component.html',
  styleUrls: ['./smart-table.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ButtonModule, TranslocoModule, TagModule, TableModule, SkeletonModule, DofficeCheckboxModule, ProgressSpinnerModule, InputGroupModule, InputTextModule, InputNumberModule, RippleModule, DialogModule]
})
export class DofficeSmartTable implements OnDestroy, OnChanges {
  @Input() source: (variables: TableVariables) => Observable<ListReturnObject<any>>;
  @Input() columns: TableColumn[];
  @Input() order: TableOrder;
  @Input() emptyMessage = '';
  @Input() variables: TableVariables = {};
  @Input() buttons: TableRowButton[] = [];
  @Input() filter = true;
  @Input() globals: SmartTableGlobal[] = [];

  data: any[];
  totalRecords: number;
  loading: boolean = true;
  currentRows = 10;

  globalResults: TableGlobalResult[] = [];

  DirectionEnum = DirectionEnum;

  private _destroy$: Subject<void> = new Subject();
  parameters: TableParameters = {
    filter: "",
    order: null,
    pagination: {
      page: 1,
      size: 10,
    },
  };

  isMobile = false;

  constructor(public cdr: ChangeDetectorRef, private deviceService: DeviceService, private vcr: ViewContainerRef) {
    interval(100)
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
      });

    this.deviceService.applicationView$.pipe(takeUntil(this._destroy$))
      .subscribe((view) => {
        this.isMobile = view === 'MOBILE';
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes['source'] && !changes['source'].firstChange) || (changes['variables'] && !changes['variables'].firstChange)) {
      if (_.isNil(this.source)) {
        this.data = [];
      } else {
        this.loading = true;
        this.loadData();
        this.loadGlobals();
      }
    }

    if (changes['globals']) {
      this.globalResults = _.orderBy(
        this.globals.map((global) => {
          return {
            id: global.id,
            label: global.label,
            loading: true,
            order: global.order,
            value: null,
            severity: global.severity ?? 'primary',
          };
        }),
        (x) => x.order
      );
    }

    if (changes['globals'] && !changes['globals'].firstChange) {
      this.loadGlobals();
    }

    this.cdr.detectChanges();
  }

  loadData() {
    const sourceVariables = {};
    if (!_.isNil(this.parameters)) {
      sourceVariables['tableParameters'] = this.parameters;
    }
    for (let property of
      Object.keys(this.variables)) {
      sourceVariables[property] = this.variables[property].value;
    }

    this.source(sourceVariables)
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        (this.totalRecords = res?.pagination?.items), (this.data = res.data);
        this.loading = false;
        this.cdr.detectChanges();
      });
  }

  loadGlobals() {
    this.globals.forEach((global) => {
      const sourceVariables = {};
      if (!_.isNil(this.parameters)) {
        sourceVariables['tableParameters'] = this.parameters;
      }
      for (let property of
        Object.keys(this.variables)) {
        sourceVariables[property] = this.variables[property].value;
      }

      global
        .source(sourceVariables)
        .pipe(takeUntil(this._destroy$))
        .subscribe((res) => {
          const globalResult = this.globalResults.find((result) => result.id == global.id);
          globalResult.loading = false;
          globalResult.value = !_.isNil(global.mutateValue) ? global.mutateValue(res) : res;

          this.cdr.detectChanges();
        });
    });
  }

  clear(table: Table) {
    table.clear();
  }

  getValue(row: any, column: TableColumn) {
    let originalRow: any = _.cloneDeep(row);
    for (let key of
      column.keys) {
      if (row != null) {
        row = row[key] != null ? row[key] : null;
      }
    }

    if (_.isNil(column.mutateValue)) {
      return row;
    } else {
      return column.mutateValue(row, originalRow);
    }
  }

  getTagColor(row: any, column: TableColumn) {
    let originalRow: any = _.cloneDeep(row);
    for (let key of
      column.keys) {
      if (row != null) {
        row = row[key] != null ? row[key] : null;
      }
    }

    if (_.isNil(column.tagColor)) {
      return 'primary';
    } else {
      return column.tagColor(row, originalRow);
    }
  }

  onLazyLoad(ev: TableLazyLoadEvent) {
    this.parameters.pagination.page = ev.first == 0 ? 1 : ev.first / ev.rows + 1;
    this.parameters.pagination.size = ev.rows;

    this.order = {
      direction: ev.sortOrder === 1 ? DirectionEnum.DESC : DirectionEnum.ASC,
      key: ev.sortField as string,
    };
    this.parameters.order = this.order;

    this.loadData();
    this.loadGlobals();
  }

  getColumns() {
    return this.columns.filter((c) => _.isNil(c.visible) || c.visible());
  }

  getButtons(row, rowIndex: number) {
    return (this.buttons ?? []).filter((button) => this.getRowDataFromButton(button, rowIndex, row).visible);
  }

  getRowDataFromButton(button: TableRowButton, index: number, data: any): { loading: boolean; visible: boolean; disabled: boolean } {
    const defaultValue = {
      loading: false,
      visible: button.visible ? button.visible(data, this, button, index) : true,
      disabled: button.disabled ? button.disabled(data, this, button, index) : false,
    };

    if ((button.row ?? []).length > 0) {
      let row: TableRowButtonRowLink;
      if (_.isNil(button.rowIdentificationKey)) {
        row = button.row.find((r) => !_.isNaN(+r.identification) && +r.identification === index);
      } else {
        row = button.row.find((r) => '' + r.identification == data[button.rowIdentificationKey]);
      }

      if (!_.isNil(row)) {
        defaultValue.loading = row.loading ?? defaultValue.loading;
        defaultValue.disabled = row.disabled ? row.disabled(data, this, button, index) : defaultValue.disabled;
        defaultValue.visible = row.visible ? row.visible(data, this, button, index) : defaultValue.visible;
      }
    }

    return defaultValue;
  }

  getSkeletonRows() {
    return numberToArray(this.parameters.pagination.size);
  }

  hasVariables() {
    return Object.keys(this.variables)
      .filter(key => this.variables[key].display && this.variables[key].display != 'HIDDEN').length > 0;
  }

  hasGlobalResults() {
  }

  getBooleanVariableKeys() {
    return Object.keys(this.variables)
      .filter(key => this.variables[key].display == 'BOOLEAN');
  }

  variableChanged() {
    this.loadData();
    this.loadGlobals();
  }

  getVisibleButtons(data: any, index: number) {
    return this.buttons.filter((b) => _.isNil(b.visible) || b.visible(data, this, b, index) == true);
  }

  openFilterWindow() {
    const component = this.vcr.createComponent(DofficeSmartTableFilterModal);
    component.instance.variables = this.variables;
    component.instance.columns = this.columns;
    component.instance.parameters = this.parameters;
    component.instance.visible$.pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        if (res === false) {
          this.loadData();
          this.loadGlobals();
        }
      });
    this.cdr.detectChanges();
  }

  getColumnName(id: string) {
    return this.columns.find((c) => c.id == id)?.name;
  }

  searchUpdated = false;
  applySearch() {
    if (this.searchUpdated) {
      this.searchUpdated = false;

      this.loadData();
      this.loadGlobals();
    }
  }
}
