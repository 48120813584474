<ng-container *ngIf="!readonly">
  <p-inputMask [ngModel]="formattedDate" (ngModelChange)="onChangeInputValue($event)" [mask]="mask ?? ''" [placeholder]="format" (onFocus)="onInputFocus($event)" (onComplete)="onInputComplete()" #dateInput></p-inputMask>
  <p-overlayPanel #op styleClass="date-overlay-panel calendar">
    <ng-template pTemplate>
      <p-calendar [ngModel]="_date" (ngModelChange)="onChangeDateSelectorValue($event)" [inline]="true" [showTime]="type === 'datetime' || type === 'datetimeseconds' || type === 'time' || type === 'timeseconds'" [timeOnly]="type === 'time' || type === 'timeseconds'" [showSeconds]="type === 'datetimeseconds' || type === 'timeseconds'"></p-calendar>
    </ng-template>
  </p-overlayPanel>
</ng-container>

<ng-container *ngIf="readonly">
  <input pInputText [ngModel]="formattedDate" readonly />
</ng-container>
