<p-dialog  [(visible)]="visible" appendTo="body" [modal]="true" [breakpoints]="{ '960px': '75vw', '640px': '100vw' }" [style]="{ 'max-height': '90vh', 'overflow': 'auto', width: '40vw' }" [closable]="false">
  <ng-template pTemplate="header">
    <div class="flex align-items-center">
      <span class="flex align-items-center justify-content-center text-orange-500 mr-3">
        <i class="pi pi-exclamation-circle text-3xl"></i>
      </span>
      <span class="font-medium text-2xl text-900">
        <span>{{ 'label.modal.oldDateDetected.title' | transloco: { default: 'Verouderde datum gedetecteerd' } }}</span>
      </span>
    </div>
  </ng-template>
  <p class="line-height-3 p-0 m-0">
    <span>{{ 'label.modal.oldDateDetected.message' | transloco: { default: 'Dit document bevat een verouderde datum. Het bevestigen van het document met deze datum kan juridische en fiscale gevaren met zich meebrengen!' } }}</span>
  </p>
  <div class="mt-4">
    <doffice-checkbox-group>
      <doffice-checkbox label="{{ 'label.modal.oldDateDetected.doubleConfirm' | transloco: { default: 'Ik herken de gevaren maar wens een oudere datum te kunnen gebruiken' } }}" [(ngModel)]="confirmed"></doffice-checkbox>
    </doffice-checkbox-group>
  </div>
  <ng-template pTemplate="footer">
    <div class="border-top-1 surface-border pt-3">
      <button pButton pRipple (click)="cancelClicked()" class="p-button-sm mr-2 p-button-text p-button-secondary" label="{{ 'label.general.cancel' | transloco: { default: 'Annuleren' } }}"></button>
      <button pButton pRipple [disabled]="!confirmed" (click)="useOldDateClicked()" class="p-button-sm mr-2 p-button-text p-button-danger" label="{{ 'label.modal.oldDateDetected.keepOldDateButton' | transloco: { default: 'Oude datum behouden' } }}"></button>
      <button pButton pRipple icon="pi pi-check" (click)="updateClicked()" class="p-button-sm p-button-success" label="{{ 'label.modal.oldDateDetected.updateDateButton' | transloco: { default: 'Datum updaten' } }}"></button>
    </div>
  </ng-template>
</p-dialog>
