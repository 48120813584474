<p-dialog #dialog [(visible)]="visible" appendTo="body" [modal]="true" [breakpoints]="{ '960px': '85vw', '640px': '100vw' }" [style]="{ 'max-height': '90vh', overflow: 'auto', width: '60vw' }" [closable]="false">
  <ng-template pTemplate="header">
    <div class="border-bottom-1 surface-border pb-3 w-full">
      <p-steps [model]="stepItems" [readonly]="true" [(activeIndex)]="stepIndex"></p-steps>
    </div>
  </ng-template>
  <div class="grid formgrid p-fluid mt-4" *ngIf="stepIndex == 0">
    <div class="field mb-4 col-12">
      <label for="emailSubject" class="font-medium text-900">{{ 'label.modal.sendDocumentEmail.subject' | transloco : { default: 'Onderwerp' } }}</label>
      <input id="emailSubject" type="text" pInputText [ngModel]="email.subject" />
    </div>

    <div class="field mb-4 col-12">
      <label for="emailBody" class="font-medium text-900">{{ 'label.modal.sendDocumentEmail.message' | transloco : { default: 'Bericht' } }}</label>
      <doffice-editor [(value)]="email.body"></doffice-editor>
    </div>

    <div class="field mb-4 col-12 lg:col-6">
      <label for="bodyTemplate" class="font-medium text-900">{{ 'label.modal.sendDocumentEmail.emailTemplate' | transloco : { default: 'Bericht sjablonen' } }}</label>
      <p-dropdown
        inputId="bodyTemplate"
        [options]="bodyTemplates"
        optionValue="id"
        optionLabel="name"
        [filter]="true"
        filterBy="name"
        [showClear]="false"
        [(ngModel)]="selectedBodyTemplateId"
        (onChange)="setBody()"
        placeholder="{{ 'label.selectPlaceholder.bodytemplate' | transloco : { default: 'Selecteer een sjabloon' } }}"
        emptyFilterMessage="{{ 'label.list.bodytemplate.emptyFilter' | transloco : { default: 'Er zijn geen sjablonen in het systeem die aan de huidige filters voldoen.' } }}"
        emptyMessage="{{ 'label.list.bodytemplate.empty' | transloco : { default: 'Er zijn geen sjablonen gevonden in het systeem.' } }}"
        appendTo="body"
      >
        <ng-template let-template pTemplate="item">
          <div class="flex align-items-center">
            <div>{{ template.name }}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>

    <div class="field mb-4 col-12 lg:col-6">
      <label for="footerTemplate" class="font-medium text-900">{{ 'UI_FOOTER_TEMPLATE' | translate }}</label>
      <p-dropdown
        inputId="footerTemplate"
        [options]="footerTemplates"
        optionValue="id"
        optionLabel="name"
        [filter]="true"
        filterBy="name"
        [showClear]="false"
        [(ngModel)]="selectedFooterTemplateId"
        (onChange)="setFooter()"
        placeholder="{{ 'label.selectPlaceholder.footertemplate' | transloco : { default: 'Selecteer een sjabloon' } }}"
        emptyFilterMessage="{{ 'label.list.footertemplate.emptyFilter' | transloco : { default: 'Er zijn geen sjablonen in het systeem die aan de huidige filters voldoen.' } }}"
        emptyMessage="{{ 'label.list.footertemplate.empty' | transloco : { default: 'Er zijn geen sjablonen gevonden in het systeem.' } }}"
        appendTo="body"
      >
        <ng-template let-template pTemplate="item">
          <div class="flex align-items-center">
            <div>{{ template.name }}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
  </div>
  <div class="grid formgrid p-fluid mt-4" *ngIf="stepIndex == 1">
    <div class="mb-4 col-12">
      <doffice-checkbox-group>
        <doffice-checkbox label="{{ 'UI_ADD_GENERAL_TERMS_AND_CONDITIONS' | translate }}" [ngModel]="email.termsAndConditions != null" (ngModelChange)="onTermsAndConditionsToggled($event)"></doffice-checkbox>
      </doffice-checkbox-group>
    </div>
    <p-divider [align]="'center'" class="col-12" *ngIf="(attachments ?? []).length > 0">
      {{ 'UI_ATTACHMENTS' | translate }}
    </p-divider>
    <div class="field mb-4 col-12" *ngFor="let attachment of attachments">
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon" [class.success]="attachment.checked" [class.danger]="!attachment.checked"><p-checkbox [(ngModel)]="attachment.checked" [binary]="true"></p-checkbox></span>
        <input id="attachment" type="text" pInputText [readOnly]="true" [value]="attachment.name" />
      </div>
    </div>
  </div>
  <div class="grid formgrid p-fluid mt-4" *ngIf="stepIndex == 2">
    <div class="col-12 text-center" *ngIf="(this.email.receivers ?? []).length <= 0">
      <p>{{ 'UI_NO_EMAIL_RECEIVERS_FOR_THIS_EMAIL' | translate }}</p>
    </div>
    <div class="mb-4 col-12" *ngFor="let receiver of this.email.receivers ?? []; let index = index">
      <div class="grid formgrid p-fluid">
        <div class="field col-12 lg:col-7">
          <label for="receiverEmail" class="font-medium text-900">{{ 'UI_EMAIL' | translate }}</label>
          <input id="receiverEmail" type="text" pInputText [(ngModel)]="receiver.email" />
        </div>

        <doffice-field-buttons class="col-12 lg:col-5" [buttons]="deleteReceiverButtons" [data]="index">
          <label for="receiverType" class="font-medium text-900">{{ 'UI_TYPE' | translate }}</label>
          <p-dropdown inputId="receiverType" [options]="receiverTypes" optionValue="id" optionLabel="label" [showClear]="false" [(ngModel)]="receiver.type" placeholder="{{ 'UI_TYPE' | translate }}" appendTo="body">
            <ng-template let-type pTemplate="item">
              <div class="flex align-items-center">
                <div>{{ type.label }}</div>
              </div>
            </ng-template>
          </p-dropdown>
        </doffice-field-buttons>
      </div>
    </div>
    <p-divider class="col-12 mb-2" [align]="'center'">
      <div class="p-inputgroup">
        <button pButton pRipple icon="pi pi-plus" class="p-button-sm p-button-success" (click)="addReceiver()"></button>
      </div>
    </p-divider>
  </div>
  <ng-template pTemplate="footer">
    <div class="border-top-1 surface-border pt-3">
      <button pButton pRipple (click)="cancelClicked()" class="p-button-sm p-button-text p-button-danger" label="{{ 'label.general.cancel' | transloco: { default: 'Annuleren' } }}"></button>
      <button pButton pRipple (click)="previousClicked()" *ngIf="stepIndex != 0" icon="pi pi-chevron-left" class="p-button-sm p-button-text p-button-secondary" label="{{ 'UI_PREVIOUS' | translate }}"></button>
      <button pButton pRipple (click)="nextClicked()" *ngIf="stepIndex != stepItems.length - 1" icon="pi pi-chevron-right" iconPos="right" class="p-button-sm p-button-success" label="{{ 'UI_NEXT' | translate }}"></button>
      <button pButton pRipple (click)="sendClicked()" *ngIf="stepIndex >= stepItems.length - 1" icon="pi pi-send" iconPos="right" class="p-button-sm p-button-success" label="{{ 'UI_SEND_EMAIL' | translate }}"></button>
    </div>
  </ng-template>
</p-dialog>
