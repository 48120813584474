<p-dialog  [(visible)]="visible" appendTo="body" [modal]="true" [breakpoints]="{ '1000px': '100vw' }" [style]="{ 'max-height': '90vh', 'overflow': 'auto', width: '1000px' }" [closable]="false">
  <div class="pdf-viewer">
    <ngx-extended-pdf-viewer
      #pdfViewer
      [src]="raw"
      [backgroundColor]="'transparent'"
      [showToolbar]="false"
      [enableDragAndDrop]="false"
      [handTool]="false"
      [ignoreKeyboard]="true"
      [sidebarVisible]="false"
      [textLayer]="true"
      [scrollMode]="0"
      [showBorders]="false"
      [zoom]="'page-width'"
      [logLevel]="0"
    >
    </ngx-extended-pdf-viewer>
  </div>
  <ng-template pTemplate="footer">
    <div class="border-top-1 surface-border pt-3">
      <button pButton pRipple (click)="closeClicked()" class="p-button-sm p-button-text p-button-secondary" label="{{ 'label.general.close' | transloco: { default: 'Sluiten' } }}"></button>
      <button pButton pRipple icon="pi pi-download" [disabled]="!raw" (click)="downloadRequested()" class="p-button-sm p-button-success" label="{{ 'label.pdf.download' | transloco: { default: 'Downloaden' } }}"></button>
    </div>
  </ng-template>
</p-dialog>
