import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import _ from 'lodash';
import { ArticleCategory } from 'app/shared/models/parameters/article-category';
import {GraphqlService} from "../../../../core/services/graphql.service";


@Component({
  selector: 'doffice-creation-article-category-modal',
  templateUrl: 'creation-article-category-modal.component.html',
  styleUrls: ['creation-article-category-modal.component.scss'],
})
export class CreationArticleCategoryModalComponent implements OnDestroy {
  private _visible = new BehaviorSubject<boolean>(true);
  get visible$() {
    return this._visible.asObservable();
  }
  get visible() {
    return this._visible.getValue();
  }
  set visible(visible: boolean) {
    this._visible.next(visible);
  }

  category = ArticleCategory.new();
  saveLoading = false;

  created$ = new Subject<number>();
  private _destroy$ = new Subject<void>();

  constructor( private cdr: ChangeDetectorRef, private graphqlService: GraphqlService) {
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 10);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  cancelClicked() {
    this._visible.next(false);
  }

  saveClicked() {
    this.saveLoading = true;
    this.cdr.detectChanges();

    const input = this.category.createMutationInput(null);
    this.graphqlService.mutations.parameter.articleCategory.update({input})
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        this._visible.next(false);
        this.created$.next(res.id);
      });
  }
}
