<p-dialog [(visible)]="visible" appendTo="body" [modal]="true" [breakpoints]="{ '960px': '75vw', '640px': '100vw' }" [style]="{ 'max-height': '90vh', overflow: 'auto', width: '60vw' }" [closable]="false">
  <ng-template pTemplate="header">
    <div class="flex align-items-center">
      <span class="flex align-items-center justify-content-center text-red-500 mr-3">
        <i class="pi pi-exclamation-circle text-3xl"></i>
      </span>
      <span class="font-medium text-2xl text-900">
        <span *ngIf="type">{{ 'label.modal.removeDataAcceptance.title.withType' | transloco: { default: '{{type}} verwijderen?', type: type } }}</span>
        <span *ngIf="!type">{{ 'label.modal.removeDataAcceptance.title.noType' | transloco : { default: 'Gegevens verwijderen?' } }}</span>
      </span>
    </div>
  </ng-template>
  <p class="line-height-3 p-0 m-0">
    <span *ngIf="type && name">{{ 'label.modal.removeDataAcceptance.body.withType' | transloco : { default: 'Ben je zeker dat je {{type}} \"{{name}}\" wilt verwijderen? Eenmaal gewist is er geen enkele mogelijkheid om deze {{type}} te recupereren!', type: (type | lowercase), name: name } }}</span>
    <span *ngIf="!name">{{ 'label.modal.removeDataAcceptance.body.noType' | transloco : { default: 'Ben je zeker dat je deze gegevens wilt verwijderen? Eenmaal gewist is er geen enkele mogelijkheid om de gegevens te recupereren!' } }}</span>
  </p>
  <div class="mt-4" *ngIf="doubleConfirm" class="mt-3">
    <doffice-checkbox-group>
      <doffice-checkbox label="{{ 'label.modal.removeDataAcceptance.doubleConfirm' | transloco: { default: 'Ik ben zeker dat ik deze data definitief wil wissen' } }}" [(ngModel)]="confirmed"></doffice-checkbox>
    </doffice-checkbox-group>
  </div>
  <div *ngIf="showSkipCheckbox" class="mt-3">
    <doffice-checkbox-group>
      <doffice-checkbox [(ngModel)]="skipDeletionModalInCurrentView" label="{{ 'label.modal.removeDataAcceptance.skipDeletionInCurrentView' | transloco: { default: 'Vraag niet opnieuw totdat de pagina wordt verlaten.' } }}"></doffice-checkbox>
    </doffice-checkbox-group>
  </div>
  <ng-template pTemplate="footer">
    <div class="border-top-1 surface-border pt-3">
      <button pButton pRipple (click)="cancelClicked()" class="p-button-sm mr-2 p-button-text p-button-secondary" label="{{ 'label.general.cancel' | transloco: { default: 'Annuleren' } }}"></button>
      <button pButton pRipple *ngIf="!doubleConfirm" icon="pi pi-trash" (click)="deleteClicked()" class="p-button-sm p-button-danger" label="{{ 'label.general.delete' | transloco: { default: 'Verwijderen' } }}"></button>
      <button pButton pRipple *ngIf="doubleConfirm" [disabled]="!(doubleConfirm && confirmed)" icon="pi pi-trash" (click)="deleteClicked()" class="p-button-sm p-button-danger" label="{{ 'label.general.delete' | transloco: { default: 'Verwijderen' } }}"></button>
    </div>
  </ng-template>
</p-dialog>
