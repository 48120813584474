import {Component, ChangeDetectionStrategy, Input, forwardRef, ViewChild, ChangeDetectorRef, OnChanges, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR} from '@angular/forms';
import {isNil} from 'lodash';
import moment from 'moment';
import {OverlayPanel, OverlayPanelModule} from 'primeng/overlaypanel';
import {CommonModule} from "@angular/common";
import {InputMaskModule} from "primeng/inputmask";
import {CalendarModule} from "primeng/calendar";
import {InputTextModule} from "primeng/inputtext";

@Component({
  selector: 'doffice-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DofficeCalendar), multi: true}],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FormsModule, InputMaskModule, OverlayPanelModule, CalendarModule, InputTextModule]
})
export class DofficeCalendar implements OnChanges, ControlValueAccessor {
  propagateChange: any = (_: any) => {};
  propagateTouch: any = (_: any) => {};

  writeValue(value: Date): void {
    this._date = isNil(value) ? null : new Date(value);
    this.formateDate();
    this.cdr.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  @ViewChild('op') op: OverlayPanel;

  @Input('readonly') readonly = false;
  @Input('date') _date: Date;
  @Input('type') type: 'date' | 'datetime' | 'datetimeseconds' | 'time' | 'timeseconds' = 'date';

  formattedDate?: string;
  focus: boolean = false;
  inputStringValue = '';
  format: string = 'DD/MM/YYYY';
  mask: string = '99/99/9999';

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['type']) {
      this.format = {date: 'DD/MM/YYYY', datetime: 'DD/MM/YYYY HH:mm', datetimeseconds: 'DD/MM/YYYY HH:mm:ss', time: 'HH:mm', timeseconds: 'HH:mm:ss'}[this.type];
      this.mask = {date: '99/99/9999', datetime: '99/99/9999 99:99', datetimeseconds: '99/99/9999 99:99:99', time: '99:99', timeseconds: '99:99:99'}[this.type];
    }
  }

  onChangeDateSelectorValue(value: Date) {
    this._date = isNil(value) ? null : new Date(value);
    this.formateDate();
    this.propagateChange(this._date);

    if (this.type === 'date') {
      this.hideOP();
    }
  }

  getValidDateFromStrings(year: string, month: string, day: string): Date | undefined {
    if (!!year && !!month && !!day && +year > 0 && +year < 4000 && +month > -1 && +month > 12 && +day > 0 && +day < 31) {
      return undefined;
    }
    const d = new Date(+year, +month - 1, +day);
    if (!this.isValidDate(d)) {
      return undefined;
    }
    return d;
  }

  isValidDate(d: any) {
    return d instanceof Date && !isNaN(d.getTime());
  }

  onInputFocus(event: Event) {
    this.focus = true;
    this.op.show(event);
  }

  onInputComplete() {
    this.hideOP();

    let date: Date;
    let dateString: string;
    let timeString: string;
    let day: string;
    let month: string;
    let year: string;
    let hour: string;
    let minut: string;
    let second: string;

    switch (this.type) {
      case 'date':
        [day, month, year] = this.inputStringValue.split('/');
        date = new Date(+year, +month - 1, +day);
        break;
      case 'datetime':
        [dateString, timeString] = this.inputStringValue.split(' ');
        [day, month, year] = dateString.split('/');
        [hour, minut] = timeString.split(':');
        date = new Date(+year, +month - 1, +day, +hour, +minut);
        break;
      case 'datetimeseconds':
        [dateString, timeString] = this.inputStringValue.split(' ');
        [day, month, year] = dateString.split('/');
        [hour, minut, second] = timeString.split(':');
        date = new Date(+year, +month - 1, +day, +hour, +minut, +second);
        break;
      case 'time':
        [hour, minut] = this.inputStringValue.split(':');
        date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), +hour, +minut);

        break;
      case 'timeseconds':
        [hour, minut, second] = this.inputStringValue.split(':');
        date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), +hour, +minut, +second);
        break;
    }

    this._date = moment(date)
      .isValid() ? date : null;
    this.formateDate();
    this.propagateChange(this._date);
  }

  private hideOP() {
    setTimeout(() => {
      this.op.hide();
    }, 500);
  }

  formateDate() {
    this.formattedDate = this._date && moment(this._date)
      .isValid() ? moment(this._date)
      .format(this.format) : '';
  }

  onChangeInputValue($event) {
    this.inputStringValue = $event;

    if ((this.inputStringValue ?? '').length <= 0) {
      this._date = null;
      this.propagateChange(this._date);
    }
  }
}
