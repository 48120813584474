import { ChangeDetectorRef, Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import _ from 'lodash';

@Component({
  selector: 'doffice-simple-information-modal',
  templateUrl: 'simple-information-modal.component.html',
  styleUrls: ['simple-information-modal.component.scss'],
})
export class SimpleInformationModalComponent {
  private _visible = new BehaviorSubject<boolean>(true);
  get visible$() {
    return this._visible.asObservable();
  }
  get visible() {
    return this._visible.getValue();
  }
  set visible(visible: boolean) {
    this._visible.next(visible);
  }

  title: string;
  message: string;

  constructor(private cdr: ChangeDetectorRef) {    
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 10)
  }

  closeClicked() {
    this._visible.next(false);
  }
}
