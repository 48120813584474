import {Injectable} from '@angular/core';
import {Email, EmailReceiver} from 'app/shared/models/mail/email';
import {EmailAttachment} from 'app/shared/models/mail/email';
import {MutationInputObject} from 'app/shared/models/system/mutation-object';
import _ from 'lodash';
import {AuthService} from './auth.service';
import {TranslationService} from './translation.service';
import {IEmailAttachmentMutationInput, IEmailMutationInput, IEmailReceiverMutationInput} from "../graphql/generated/types";

export interface DocumentValues {
  documentNumber?: string;
  guestLink?: string;
  value?: string;
  vatValue?: string;
  valueIncl?: string;
  contact?: string;
  relation?: string;
  company?: string;
  email?: string;
  phone?: string;
  website?: string;
  logo?: string;
  salesperson?: string;
  salespersonEmail?: string;
  salespersonPhone?: string;
}

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  constructor(private authService: AuthService, private translationService: TranslationService) {}

  public createMailMutationInput(email: Email, originalEmail: Email = null): IEmailMutationInput {
    let mutationInput: IEmailMutationInput = {id: email.id, email: null, emailAttachments: null, emailReceivers: null};
    mutationInput.email = _.isEqual(email.convertToInput(), originalEmail?.convertToInput()) ? null : email.convertToInput();

    mutationInput.emailReceivers = this.createEmailReceiverMutations(email.receivers ?? [], originalEmail ? originalEmail.receivers : []);
    mutationInput.emailAttachments = this.createEmailAttachmentMutations(email.attachments ?? [], originalEmail ? originalEmail.attachments : []);

    return mutationInput;
  }

  private createEmailReceiverMutations(receivers: EmailReceiver[], originalReceivers: EmailReceiver[] = null): MutationInputObject<IEmailReceiverMutationInput> {
    if (_.isNil(originalReceivers)) {
      receivers.forEach((receiver) => {
        receiver.id == null;
      });
    }

    const input: MutationInputObject<IEmailReceiverMutationInput> = {deletes: [], updates: []};

    const newPrice = receivers.filter((receiver) => _.isNil(receiver.id))
      .map((receiver) => receiver.convertToInput());
    const updatedSublines = receivers
      .filter((receiver) => !_.isNil(receiver.id))
      .map((receiver) => receiver.convertToInput())
      .filter((receiver) => !_.isEqual(receiver, originalReceivers?.find((s) => s.id == receiver.id)
        ?.convertToInput()));
    input.updates = _.concat(newPrice, updatedSublines)
      .map((receiver) => {
        return {
          id: receiver.id,
          emailReceiver: receiver,
        };
      });

    input.deletes = _.differenceBy(
      originalReceivers,
      receivers.filter((receiver) => !_.isNil(receiver.id)),
      (receiver) => receiver.id
    )
      .map((receiver) => +receiver.id);

    return input;
  }

  private createEmailAttachmentMutations(attachments: EmailAttachment[], originalAttachments: EmailAttachment[] = null): MutationInputObject<IEmailAttachmentMutationInput> {
    if (_.isNil(originalAttachments)) {
      attachments.forEach((attachment) => {
        attachment.id == null;
      });
    }

    const input: MutationInputObject<IEmailAttachmentMutationInput> = {deletes: [], updates: []};

    const newPrice = attachments.filter((attachment) => _.isNil(attachment.id))
      .map((attachment) => attachment.convertToInput());
    const updatedSublines = attachments
      .filter((attachment) => !_.isNil(attachment.id))
      .map((attachment) => attachment.convertToInput())
      .filter((attachment) => !_.isEqual(attachment, originalAttachments?.find((s) => s.id == attachment.id)
        ?.convertToInput()));
    input.updates = _.concat(newPrice, updatedSublines)
      .map((attachment) => {
        return {
          id: attachment.id,
          emailAttachment: attachment,
        };
      });

    input.deletes = _.differenceBy(
      originalAttachments,
      attachments.filter((attachment) => !_.isNil(attachment.id)),
      (attachment) => attachment.id
    )
      .map((attachment) => +attachment.id);

    return input;
  }

  CreateMessageFromTemplate(template: string, values: DocumentValues): string {
    let text = _.cloneDeep(template);
    const subscriber = this.authService.activeSubscriberSnapshot;
    const user = this.authService.activeUserSnapshot;

    if (values) {
      /* Document values */
      text = text?.replace(/\[document_number\]/g, values?.documentNumber ?? '');
      text = text?.replace(/\[document_guest_link\]/g, values?.guestLink ?? '');
      text = text?.replace(/\[document_value_excl\]/g, '' + values?.value ?? '');
      text = text?.replace(/\[document_value_vat\]/g, '' + values?.vatValue ?? '');
      text = text?.replace(/\[document_value_incl\]/g, '' + values?.valueIncl ?? '');

      /* Relation values */
      text = text?.replace(/\[relation_name\]/g, values.relation ?? '');
      text = text?.replace(/\[customer_name\]/g, values.relation ?? '');
      text = text?.replace(/\[supplier_name\]/g, values.relation ?? '');
      text = text?.replace(/\[contact_name\]/g, values.contact ? values.contact : values.relation);

      /* Company */
      text = text?.replace(/\[company_name\]/g, values.company);
      text = text?.replace(/\[company_email\]/g, `<a href="mailto:${values.email}">${values.email}</a>`);
      text = text?.replace(/\[company_phone\]/g, `<a href="tel:${values.phone}">${values.phone}</a>`);
      text = text?.replace(/\[company_website\]/g, `<a href="${values.website}">${values.website}</a>`);
      text = text?.replace(/\[company_logo\]/g, `<img width="auto" height="170" src="${values.logo}">`); // TODO Make hight and width dynamic
    }

    /* SalesPerson */
    text = text?.replace(/\[user_name\]/g, values?.salesperson ? values.salesperson : user.name ?? '');
    text = text?.replace(/\[user_email\]/g, `<a href="mailto:${values?.salespersonEmail ? values.salespersonEmail : user.mail ?? ''}">${values?.salespersonEmail ? values.salespersonEmail : user.mail ?? ''}</a>`);
    text = text?.replace(/\[user_phone\]/g, `<a href="tel:${values?.salespersonPhone ? values.salespersonPhone : user.phone ?? ''}">${values?.salespersonPhone ? values.salespersonPhone : user.phone ?? ''}</a>`);

    /* Company */
    text = text?.replace(/\[company_address_street\]/g, subscriber.street);
    text = text?.replace(/\[company_address_number\]/g, subscriber.houseNumber);
    text = text?.replace(/\[company_address_zip\]/g, subscriber.zip);
    text = text?.replace(/\[company_address_city\]/g, subscriber.city);
    text = text?.replace(/\[company_address_County\]/g, subscriber.county);
    text = text?.replace(/\[company_address_Country\]/g, this.translationService.getValueByKey(subscriber.country.name));

    /* User */
    text = text?.replace(/\[user_name\]/g, user.name);
    text = text?.replace(/\[user_email\]/g, `<a href="mailto:${user.mail}">${user.mail}</a>`);
    text = text?.replace(/\[user_phone\]/g, `<a href="tel:${user.phone}">${user.phone}</a>`);

    return text;
  }
}
