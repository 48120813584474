import { ChangeDetectorRef, Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import _ from 'lodash';

@Component({
  selector: 'doffice-yesno-modal',
  templateUrl: 'acceptance-yesno-modal.component.html',
  styleUrls: ['acceptance-yesno-modal.component.scss'],
})
export class AcceptanceYesNoModalComponent {
  private _visible = new BehaviorSubject<boolean>(true);
  get visible$() {
    return this._visible.asObservable();
  }
  get visible() {
    return this._visible.getValue();
  }
  set visible(visible: boolean) {
    this._visible.next(visible);
  }

  title: string = null;
  message: string = "";

  confirmFunction: () => void;

  constructor(private cdr: ChangeDetectorRef) {
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 10);
  }

  cancelClicked() {
    this._visible.next(false);
  }

  confirmClicked() {
    this._visible.next(false);
    this.confirmFunction();
  }
}
