import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewContainerRef} from '@angular/core';
import {BehaviorSubject, Subject, combineLatest, map, mergeMap, of, takeUntil, tap} from 'rxjs';
import _ from 'lodash';
import {ArticleCategory} from 'app/shared/models/parameters/article-category';

import {AuthService} from 'app/core/services/auth.service';
import {CreationArticleCategoryModalComponent} from '../creation-article-category-modal/creation-article-category-modal.component';
import {Product} from 'app/shared/models/article/product';
import {ProductService} from 'app/core/services/product.service';
import {FieldButton} from '../../field-buttons/field-buttons.component';
import {GraphqlService} from "../../../../core/services/graphql.service";

@Component({
  selector: 'doffice-creation-product-modal',
  templateUrl: 'creation-product-modal.component.html',
  styleUrls: ['creation-product-modal.component.scss'],
})
export class CreationProductModalComponent implements OnDestroy, OnInit {
  private _visible = new BehaviorSubject<boolean>(true);

  get visible$() {
    return this._visible.asObservable();
  }

  get visible() {
    return this._visible.getValue();
  }

  set visible(visible: boolean) {
    this._visible.next(visible);
  }

  product = Product.new();
  saveLoading = false;

  categories: ArticleCategory[];
  categoriesLoading = true;

  created$ = new Subject<number>();
  private _destroy$ = new Subject<void>();

  canUpdateCategory: boolean = this.authService.checkPermissions(['ARTICLECATEGORY.UPDATE']);

  articleCategoryButtons: FieldButton[] = [
    {
      visible: () => !!this.product?.artcat_id,
      icon: 'pi pi-times',
      color: 'danger',
      click: () => this.removeCategory(),
    },
    {
      visible: () => this.canUpdateCategory,
      icon: 'pi pi-plus',
      color: 'success',
      click: () => this.addCategory(),
    },
  ];

  constructor( private cdr: ChangeDetectorRef, private authService: AuthService, private vcr: ViewContainerRef, private productService: ProductService, private graphqlService: GraphqlService) {
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 10);
  }

  ngOnInit(): void {
    this.loadCategories();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  cancelClicked() {
    this._visible.next(false);
  }

  saveClicked() {
    this.saveLoading = true;
    if (!this.product.sku || this.product.sku.trim().length <= 0) this.product.autogenerateSku();
    this.cdr.detectChanges();

    const input = this.productService.createProductMutationInput(this.product);
    this.graphqlService.mutations.article.product.update({input:input})
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        this._visible.next(false);
        this.created$.next(res.id);
      });
  }

  loadCategories() {
    this.categoriesLoading = true;
    this.categories = [];
    this.cdr.detectChanges();

    this.graphqlService.queries.parameters.articleCategory.list()
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        this.categories = res.data;
        this.categoriesLoading = false;
        this.cdr.detectChanges();
      });
  }

  setCategory(id: number): void {
    const category = this.categories.find((c) => c.id == id);
    this.product.category = category;
    this.product.artcat_id = category ? category.id : null;

    this.cdr.detectChanges();
  }

  removeCategory() {
    this.setCategory(null);
  }

  addCategory() {
    const creationComponent = this.vcr.createComponent(CreationArticleCategoryModalComponent);
    creationComponent.instance.created$
      .pipe(
        takeUntil(this._destroy$),
        tap(() => {
          this.categoriesLoading = true;
          this.cdr.detectChanges();
        }),
        mergeMap((id) =>
          combineLatest({
            categories: this.graphqlService.queries.parameters.articleCategory.list().pipe(map((res) => res.data)),
            id: of(id),
          })
        )
      )
      .subscribe(({categories, id}) => {
        this.categories = categories;
        this.categoriesLoading = false;
        this.cdr.detectChanges();

        this.setCategory(id);
      });
  }
}
