import {ChangeDetectorRef, Component} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import _ from 'lodash';
import {Company} from 'app/shared/models/entity/company';
import {Relation} from 'app/shared/models/relation/relation.class';
import {Email, EmailReceiver, EmailReceiverTypeEnum} from 'app/shared/models/mail/email';
import {DocumentValues, EmailService} from 'app/core/services/mail.service';
import {EmailAttachment} from 'app/shared/models/mail/email';
import {EmailBodyTemplate, EmailFooterTemplate} from 'app/shared/models/mail/email-template';
import {translate} from '@jsverse/transloco';
import {marker} from '@nyffels/transloco-keys-manager/marker';
import {FieldButton} from '../../field-buttons/field-buttons.component';
import {AuthService} from "../../../../core/services/auth.service";
import {IEmailMutationInput} from "../../../../core/graphql/generated/types";

@Component({
  selector: 'doffice-send-document-email-modal',
  templateUrl: 'send-document-email-modal.component.html',
  styleUrls: ['send-document-email-modal.component.scss'],
})
export class SendDocumentEmailModalComponent {
  stepItems = [
    {
      label: translate(marker('label.modal.sendDocumentEmail.step.message', 'Bericht')),
    },
    {
      label: translate(marker('label.modal.sendDocumentEmail.step.attachments', 'Bijlages')),
    },
    {
      label: translate(marker('label.modal.sendDocumentEmail.step.receivers', 'Ontvangers')),
    },
  ];
  stepIndex = 0;

  receiverTypes = [
    {
      id: EmailReceiverTypeEnum.TO,
      label: translate(marker('label.modal.sendDocumentEmail.emailType.to', 'Aan')),
    },
    {
      id: EmailReceiverTypeEnum.CC,
      label: translate(marker('label.modal.sendDocumentEmail.emailType.cc', 'CC')),
    },
    {
      id: EmailReceiverTypeEnum.BCC,
      label: translate(marker('label.modal.sendDocumentEmail.emailType.bcc', 'BCC')),
    },
  ];

  private _visible = new BehaviorSubject<boolean>(true);

  get visible$() {
    return this._visible.asObservable();
  }

  get visible() {
    return this._visible.getValue();
  }

  set visible(visible: boolean) {
    this._visible.next(visible);
  }

  email = Email.new();
  attachments: {
    checked: boolean;
    id: number;
    name: string;
  }[] = [];

  company: Company;
  relation: Relation;
  documentTemplateValues: DocumentValues;
  bodyTemplates: EmailBodyTemplate[] = [];
  footerTemplates: EmailFooterTemplate[] = [];

  selectedBodyTemplateId: number;
  selectedFooterTemplateId: number;

  sendRequest$ = new Subject<IEmailMutationInput>();

  deleteReceiverButtons: FieldButton[] = [
    {
      icon: 'pi pi-trash',
      color: 'danger',
      click: (index) => this.deleteReceiver(index)
    }
  ]

  constructor(private cdr: ChangeDetectorRef, private emailService: EmailService, private authService: AuthService) {
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 10);
  }

  cancelClicked() {
    this._visible.next(false);
  }

  nextClicked() {
    this.stepIndex += 1;
    this.cdr.detectChanges();
  }

  previousClicked() {
    this.stepIndex -= 1;
    this.cdr.detectChanges();
  }

  sendClicked() {
    this.email.attachments = this.attachments
      .filter((a) => a.checked)
      .map(
        (a) =>
          new EmailAttachment({
            id: null,
            mail_id: null,
            subscriber_id: null,
            sysdoc: null,
            sysdoc_id: a.id,
          })
      );
    const mutationInput = this.emailService.createMailMutationInput(this.email, null);
    this.sendRequest$.next(mutationInput);
    this._visible.next(false);
    this.cdr.detectChanges();
  }

  setDefaultTemplates() {
    this.selectedBodyTemplateId = null;
    this.selectedFooterTemplateId = null;

    this.selectedBodyTemplateId = this.bodyTemplates.find((t) => t.default && this.authService.activeUserSnapshot.id == t.user_id)?.id;
    this.selectedFooterTemplateId = this.footerTemplates.find((t) => t.default && this.authService.activeUserSnapshot.id == t.user_id)?.id;

    if (_.isNil(this.selectedBodyTemplateId)) {
      this.selectedBodyTemplateId = this.bodyTemplates.find((t) => t.default)?.id;
    }

    if (_.isNil(this.selectedFooterTemplateId)) {
      this.selectedFooterTemplateId = this.footerTemplates.find((t) => t.default)?.id;
    }

    if (!_.isNil(this.selectedBodyTemplateId)) {
      this.setBody();
    }

    if (!_.isNil(this.selectedFooterTemplateId)) {
      this.setFooter();
    }
  }

  setBody() {
    if ((this.selectedBodyTemplateId ?? 0) <= 0) {
      const bodySplit = (this.email.body ?? '').split('<!-- !@!SIGNATURE!@! -->');
      this.email.body = '';
      if (bodySplit.length > 1) {
        this.email.body += '<!-- !@!SIGNATURE!@! -->';
        this.email.body += bodySplit[1];
      }
      this.cdr.detectChanges();
    } else {
      const template = this.bodyTemplates.find((t) => t.id == this.selectedBodyTemplateId);
      const bodySplit = (this.email.body ?? '').split('<!-- !@!SIGNATURE!@! -->');
      this.email.body = this.emailService.CreateMessageFromTemplate(template.text, this.documentTemplateValues);
      if (bodySplit.length > 1) {
        this.email.body += '<!-- !@!SIGNATURE!@! -->';
        this.email.body += bodySplit[1];
      }
      this.cdr.detectChanges();
    }
  }

  setFooter() {
    if ((this.selectedFooterTemplateId ?? 0) <= 0) {
      const bodySplit = (this.email.body ?? '').split('<!-- !@!SIGNATURE!@! -->');
      this.email.body = bodySplit[0];
      this.cdr.detectChanges();
    } else {
      const template = this.footerTemplates.find((t) => t.id == this.selectedFooterTemplateId);
      const bodySplit = (this.email.body ?? '').split('<!-- !@!SIGNATURE!@! -->');
      this.email.body = bodySplit[0];
      this.email.body += '<!-- !@!SIGNATURE!@! -->';
      this.email.body += this.emailService.CreateMessageFromTemplate(template.text, this.documentTemplateValues);
      this.cdr.detectChanges();
    }
  }

  addReceiver() {
    this.email.receivers.push(EmailReceiver.new());
    this.cdr.detectChanges();
  }

  deleteReceiver(index: number) {
    this.email.receivers.splice(index, 1);
    this.cdr.detectChanges();
  }

  onTermsAndConditionsToggled(toggled: boolean) {
    this.email.termsAndConditions = toggled ? +this.company.id : null;
    this.cdr.detectChanges();
  }
}
