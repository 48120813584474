import {constructFromInterface} from 'app/core/logic/map.logic';
import _ from 'lodash';
import {IArticleCategory, IArticleCategoryInput, IArticleCategoryMutationInput} from "../../../core/graphql/generated/types";
import {GetDateTimeScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";

export class ArticleCategory implements IArticleCategory {
  id: number = 0;
  subscriber_id: number = 0;
  name: string = '';
  description: string = '';
  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  get Validation(): boolean {
    return this.name && this.name.trim().length > 0;
  }

  constructor(category: IArticleCategory = null) {
    if (category === null) {
      return;
    }

    constructFromInterface(this, category);

    if (category.created) {this._created = GetDateTimeScalar(category.created);}
    if (category.updated) {this._updated = GetDateTimeScalar(category.updated);}
  }

  public static new(): ArticleCategory {
    return new ArticleCategory({
      id: null,
      subscriber_id: null,
      name: null,
      description: null,
      created: null,
      updated: null,
    });
  }

  public convertToInput(): IArticleCategoryInput {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
    };
  }

  public createMutationInput(originalArticleCategory: ArticleCategory = null): IArticleCategoryMutationInput {
    let mutationInput: IArticleCategoryMutationInput = {id: this.id, articleCategory: null};
    mutationInput.articleCategory = _.isEqual(this.convertToInput(), originalArticleCategory?.convertToInput()) ? null : this.convertToInput(); /* Check if articleCategory is updated */

    return mutationInput;
  }
}
