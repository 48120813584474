import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, SimpleChanges, forwardRef } from '@angular/core';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'doffice-checkbox',
  templateUrl: './checkbox.html',
  styleUrls: ['./checkbox.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DofficeCheckbox), multi: true }],
})
export class DofficeCheckbox implements OnDestroy, OnChanges, ControlValueAccessor {
  @Input('checked') _checked: boolean = false;
  @Input() label: string;
  @Input() readonly: boolean = false;

  propagateChange: any = (_: any) => {};
  propagateTouch: any = (_: any) => {};

  writeValue(value: any): void {
    if (!_.isNil(value)) {
      this._checked = value;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  _uuid = _.uniqueId('checkbox-');

  private _destroy: Subject<void> = new Subject();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.cdr.detectChanges();
  }

  valueChanged(value: boolean) {
    this.propagateChange(value);
  }
}
