import {Language} from "../models/general/language";
import {translate} from "@jsverse/transloco";
import {marker} from "@nyffels/transloco-keys-manager/marker";

export const languages: Language[] = [
  new Language({
    id: 1,
    iso: "NL",
    getName: () => {return translate(marker('languages.nl', 'Nederlands'))}
  }),
  new Language({
    id: 2,
    iso: "EN",
    getName: () => {return translate(marker('languages.en', 'Engels'))}
  })
];
