import {Pipe, PipeTransform} from '@angular/core';
import {ConvertionService} from 'app/core/services/convertion.service';
import moment from "moment";

@Pipe({
  name: 'date',
})
export class ConvertDateToDatePipe implements PipeTransform {
  constructor(private convertionService: ConvertionService) {}

  transform(value: Date, ...args: any[]): string {
    return this.convertionService.convertDateToDateString(value);
  }
}

@Pipe({
  name: 'datetime',
})
export class ConvertDateToDateTimePipe implements PipeTransform {
  constructor(private convertionService: ConvertionService) {}

  transform(value: Date, settings: { timezone: boolean }): string {
    if (typeof value === 'string' && moment(value).isValid()) {
      value = moment(value).toDate();
    }

    if (settings.timezone === undefined || settings.timezone === null) {
      return this.convertionService.convertDateToDateTimeString(value);
    }
    return this.convertionService.convertDateToDateTimeString(value, settings.timezone);
  }
}

@Pipe({
  name: 'number',
})
export class ConvertNumberToNumberPipe implements PipeTransform {
  constructor(private convertionService: ConvertionService) {}

  transform(value: number, ...args: any[]): string {
    return this.convertionService.convertNumberToNumberString(value);
  }
}

@Pipe({
  name: 'money',
})
export class ConvertNumberToMoneyPipe implements PipeTransform {
  constructor(private convertionService: ConvertionService) {}

  transform(value: number, ...args: any[]): string {
    return this.convertionService.convertNumberToMoneyString(value);
  }
}
