import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import _ from 'lodash';

@Component({
  selector: 'doffice-field-buttons',
  templateUrl: './field-buttons.component.html',
  styleUrls: ['./field-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DofficeFieldButtonsComponent {
  @Input() buttons: FieldButton[] = [];
  @Input() data: any;
  @Input() appendButtonClass: string = '';
  @Input() buttonMargin: string = '4';

  constructor() {}

  get visibleButtons() {
    return (this.buttons ?? []).filter((x) => (x.visible ? x.visible(this.data) : true));
  }

  getIcon(button) {
    if (button.loading && button.loading() === true) {
      return 'pi pi-spin pi-spinner';
    } else {
      return button.icon ?? '';
    }
  }

  getDisabled(button) {
    if (button.loading && button.loading() === true) {
      return true;
    } else {
      return button.disabled ? button.disabled(this.data) : false;
    }
  }

  getButtons() {
    return this.buttons.filter((b) => b.visible ? b.visible(this.data) : true);
  }
}

export interface FieldButton {
  visible?: (data) => boolean;
  disabled?: (data) => boolean;
  loading?: (data) => boolean;
  icon?: string;
  label?: string;
  color?: 'secondary' | 'warning' | 'danger' | 'success';
  click?: (data) => void;
}
