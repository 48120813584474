import {Injectable} from '@angular/core';
import {EntityEmail} from 'app/shared/models/relation/entity-email';
import {MutationInputObject} from 'app/shared/models/system/mutation-object';
import _ from 'lodash';
import {IEntityEmailMutationInput} from "../graphql/generated/types";

@Injectable({
  providedIn: 'root',
})
export class RelationEmailService {
  createEmailMutations(emails: EntityEmail[], originalEmails: EntityEmail[] = null): MutationInputObject<IEntityEmailMutationInput> {
    if (_.isNil(originalEmails)) {
      emails.forEach((contact) => {
        contact.id == null;
      });
    }

    const input: MutationInputObject<IEntityEmailMutationInput> = {deletes: [], updates: []};

    const newEmail = emails.filter((email) => _.isNil(email.id))
      .map((email) => email.convertToInput());
    const updatedEmail = emails
      .filter((email) => !_.isNil(email.id))
      .map((email) => email.convertToInput())
      .filter((email) => !_.isEqual(email, originalEmails?.find((s) => s.id == email.id)
        ?.convertToInput()));
    input.updates = _.concat(newEmail, updatedEmail)
      .map((email) => {
        return {
          id: email.id,
          email: email,
        };
      });

    input.deletes = _.differenceBy(
      originalEmails,
      emails.filter((email) => !_.isNil(email.id)),
      (email) => email.id
    )
      .map((email) => +email.id);

    return input;
  }
}
