<div class="flex">
  <div class="field flex-auto" [class.mr-2]="visibleButtons.length > 0">
    <ng-content></ng-content>
  </div>

  <div class="field flex">
    <ng-container *ngFor="let button of getButtons(); let last = last">
      <button type="button" pButton icon="{{ getIcon(button) }}" label="{{ (button.label ?? '') }}" class="mt-{{ buttonMargin }} p-font-small p-button-{{ button.color ?? 'secondary' }} {{ appendButtonClass }} cursor-{{ button.click ? 'pointer' : 'auto' }}" [class.mr-1]="!last" [disabled]="getDisabled(button)" (click)="button.click ? button.click(this.data) : null"></button>
    </ng-container>
  </div>
</div>