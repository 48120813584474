import { ChangeDetectorRef, Component } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import _ from 'lodash';
import { Bank } from 'app/shared/models/financial/bank';
import { FieldButton } from '../../field-buttons/field-buttons.component';

@Component({
  selector: 'doffice-select-bank-modal',
  templateUrl: 'select-bank-modal.component.html',
  styleUrls: ['select-bank-modal.component.scss'],
})
export class SelectBankModalComponent {
  private _visible = new BehaviorSubject<boolean>(true);
  get visible$() {
    return this._visible.asObservable();
  }
  get visible() {
    return this._visible.getValue();
  }
  set visible(visible: boolean) {
    this._visible.next(visible);
  }

  banks: Bank[] = [];
  select$ = new Subject<number>();

  bankButtons: FieldButton[] = [
    {
      icon: 'pi pi-check',
      color: 'success',
      click: (bank) => this.selectClicked(bank.id)
    }
  ]

  constructor(private cdr: ChangeDetectorRef) {
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 10);
  }

  cancelClicked() {
    this._visible.next(false);
  }

  selectClicked(id: number) {
    this._visible.next(false);
    this.select$.next(id);
  }
}
