import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import * as Sentry from "@sentry/angular-ivy";
import {getWhitelabelEnvironment} from "./app/core/logic/whitelabel.logic";

if (environment.production) {
  enableSentry();
  enableProdMode();
}

const whitelabel = getWhitelabelEnvironment();
document.documentElement.style.setProperty('--doffice-gold', whitelabel.app.accentColor);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((error) => console.error(error));

function enableSentry() {
  Sentry.init({
    dsn: environment.sentry.dsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllInputs: false,
        maskAllText: false,
        blockAllMedia: false,
      })
    ],
    tracePropagationTargets: environment.sentry.tracePropagationTargets,
    tracesSampleRate: environment.sentry.tracesSampleRate, // Capture 100% of the transactions
    replaysSessionSampleRate: environment.sentry.replaySessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: environment.sentry.replaysOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
