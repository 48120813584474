import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ChangeDetectorRef, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'doffice-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DofficeEditorComponent implements OnChanges, OnDestroy {
  @Input() label: string = null;

  _value: string;
  @Input() value: string = '';
  @Output('valueChange') valueEmitter: EventEmitter<string> = new EventEmitter<string>();

  @Input() readOnly: boolean = false;
  @Input() spellcheck: boolean = true;
  @Input() placeholder: string = '';
  @Input() toolbar: boolean = true;
  @Input() minHeight: string = '180px';

  onDestroy: Subject<void> = new Subject();

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: this.spellcheck,
    height: 'auto',
    minHeight: this.minHeight,
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'no',
    enableToolbar: this.toolbar,
    showToolbar: this.toolbar,
    placeholder: this.placeholder,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['heading', 'fontname'],
      ['backgroundColor', 'customClasses', 'insertVideo', 'insertHorizontalRule', 'removeFormat', 'toggleEditorMode'],
    ],
  };

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.readOnly) {
      this.cdr.detectChanges();
    }
    if (changes.toolbar) {
      this.editorConfig.showToolbar = this.toolbar;
    }
    if (changes.spellcheck) {
      this.editorConfig.spellcheck = this.spellcheck;
      this.cdr.detectChanges();
    }
    if (changes.placeholder) {
      this.editorConfig.placeholder = this.placeholder;
      this.cdr.detectChanges();
    }
    if (changes.value) {
      this._value = this.value;
    }
    if (changes.minHeight) {
      this.editorConfig.minHeight = this.minHeight;
    }
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
		this.onDestroy.unsubscribe();
  }

  onValueChanged(html: string): void {
    this.valueEmitter.emit(html);
  }

  public reloadValue(): void {
    this._value = this.value;
    this.cdr.detectChanges();
  }
}
