<p-dialog  [(visible)]="visible" appendTo="body" [modal]="true" [breakpoints]="{ '960px': '80vw', '640px': '100vw' }" [style]="{ 'max-height': '90vh', overflow: 'auto', width: '80vw' }" [closable]="false">
  <ng-template pTemplate="header">
    <div class="flex align-items-center">
      <span class="flex align-items-center justify-content-center text-grey-500 mr-3">
        <i class="pi pi-search text-3xl"></i>
      </span>
      <span class="font-medium text-2xl text-900">
        <span>{{ 'label.modal.choseProduct.title' | transloco : { default: 'Kies een product' } }}</span>
      </span>
    </div>
  </ng-template>
  <doffice-smart-table
    [source]="source"
    [columns]="columns"
    [buttons]="buttons"
    [order]="order"
    [variables]="variables"
    emptyMessage="{{ 'table.empty.products' | transloco : { default: 'Geen producten gevonden die aan de huidige filters voldoen.' } }}"
  ></doffice-smart-table>
  <ng-template pTemplate="footer">
    <div class="border-top-1 surface-border pt-3">
      <button pButton pRipple (click)="cancelClicked()" class="p-button-sm mr-2 p-button-text p-button-secondary" label="{{ 'label.base.cancel' | transloco : { default: 'Annuleren' } }}"></button>
      <button pButton pRipple (click)="newClicked()" icon="pi pi-plus" class="p-button-sm mr-2 p-button-success" label="{{ 'label.base.new' | transloco : { default: 'Nieuw' } }}"></button>
    </div>
  </ng-template>
</p-dialog>
