import { ChangeDetectorRef, Component } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import _ from 'lodash';

@Component({
  selector: 'doffice-acceptance-older-date-on-confirm-modal',
  templateUrl: 'acceptance-older-date-on-confirm-modal.component.html',
  styleUrls: ['acceptance-older-date-on-confirm-modal.component.scss'],
})
export class AcceptanceOlderDateOnConfirmModalComponent {
  private _visible = new BehaviorSubject<boolean>(true);
  get visible$() {
    return this._visible.asObservable();
  }
  get visible() {
    return this._visible.getValue();
  }
  set visible(visible: boolean) {
    this._visible.next(visible);
  }

  confirmed = false;

  updateDate$ = new Subject<void>();
  useOldDate$ = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef) {
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 10);
  }

  cancelClicked() {
    this._visible.next(false);
  }

  useOldDateClicked() {
    this._visible.next(false);
    this.useOldDate$.next();
  }

  updateClicked() {
    this._visible.next(false);
    this.updateDate$.next();
  }
}
