import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import _ from 'lodash';

import { TransactionGateway } from 'app/shared/models/parameters/transaction-gateway';
import {GraphqlService} from "../../../../core/services/graphql.service";

@Component({
  selector: 'doffice-creation-transaction-gateway-modal',
  templateUrl: 'creation-transaction-gateway-modal.component.html',
  styleUrls: ['creation-transaction-gateway-modal.component.scss'],
})
export class CreationTransactionGatewayModalComponent implements OnDestroy {
  private _visible = new BehaviorSubject<boolean>(true);
  get visible$() {
    return this._visible.asObservable();
  }
  get visible() {
    return this._visible.getValue();
  }
  set visible(visible: boolean) {
    this._visible.next(visible);
  }

  gateway = TransactionGateway.new();
  saveLoading = false;

  created$ = new Subject<number>();
  private _destroy$ = new Subject<void>();

  constructor( private cdr: ChangeDetectorRef, private graphqlService: GraphqlService) {
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 10);
  }

  ngOnDestroy(): void {
      this._destroy$.next();
      this._destroy$.complete();
  }

  cancelClicked() {
    this._visible.next(false);
  }

  saveClicked() {
    this.cdr.detectChanges();

    const input = this.gateway.createMutationInput(null);
    this.graphqlService.mutations.parameter.transactionGateway.update({input})
      .pipe(takeUntil(this._destroy$)).subscribe((res) => {
      this._visible.next(false);
      this.created$.next(res.id);
    });
  }
}
