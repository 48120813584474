import {TranslationService} from 'app/core/services/translation.service';
import {TranslationInjector} from 'app/app.module';
import _ from 'lodash';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {IEntityType, IEntityTypeInput, IEntityTypeMutationInput} from "../../../core/graphql/generated/types";
import {GetDateTimeScalar, SetDateTimeScalar} from 'app/core/logic/date-scalars';


export class EntityType implements IEntityType {
  id: number = 0;
  subscriber_id: number = 0;
  name: string = '';
  description: string = '';
  vat: boolean = false;

  get VatRequired(): string {
    return this.vat ? TranslationInjector.get(TranslationService)
      .getValueByKey('UI_YES') : TranslationInjector.get(TranslationService)
      .getValueByKey('UI_NO');
  }

  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  get Validation(): boolean {
    return this.name != null && this.name.trim().length > 0;
  }

  constructor(enttype: IEntityType = null) {
    if (enttype === null) {
      return;
    }

    constructFromInterface(this, enttype);

    if (enttype.created) {this._created = GetDateTimeScalar(enttype.created);}
    if (enttype.updated) {this._updated = GetDateTimeScalar(enttype.updated);}
  }

  public static new(): EntityType {
    return new EntityType({
      id: null,
      subscriber_id: null,
      name: null,
      description: null,
      vat: false,
      created: null,
      updated: null,
    });
  }

  public convertToInput(): IEntityTypeInput {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      vat: this.vat,
    };
  }

  public createMutationInput(originalEntityType: EntityType = null): IEntityTypeMutationInput {
    let mutationInput: IEntityTypeMutationInput = {id: this.id, entityType: null};
    mutationInput.entityType = _.isEqual(this.convertToInput(), originalEntityType?.convertToInput()) ? null : this.convertToInput(); /* Check if entityType is updated */

    return mutationInput;
  }
}

export enum EntityTypeEnum {
  customer = 1,
  supplier = 2,
}
