import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subject, takeUntil} from 'rxjs';
import {Transaction} from 'app/shared/models/general/transaction';
import {TransactionGateway} from 'app/shared/models/parameters/transaction-gateway';

import {FieldButton} from '../../field-buttons/field-buttons.component';
import {GraphqlService} from "../../../../core/services/graphql.service";

@Component({
  selector: 'doffice-creation-transaction-modal',
  templateUrl: 'creation-transaction-modal.component.html',
  styleUrls: ['creation-transaction-modal.component.scss'],
})
export class CreationTransactionModalComponent implements OnDestroy, OnInit {
  private _visible = new BehaviorSubject<boolean>(true);

  get visible$() {
    return this._visible.asObservable();
  }

  get visible() {
    return this._visible.getValue();
  }

  set visible(visible: boolean) {
    this._visible.next(visible);
  }

  transaction = Transaction.new();
  saveLoading = false;

  gateways: TransactionGateway[] = [];
  gatewaysLoading = true;

  createdRequest$ = new Subject<Transaction>();
  private _destroy$ = new Subject<void>();

  gatewayButtons: FieldButton[] = [
    {
      visible: () => !!this.transaction.gateway_id,
      icon: 'pi pi-times',
      color: 'danger',
      click: () => this.removeGateway(),
    },
  ];

  constructor(private cdr: ChangeDetectorRef, private graphqlService: GraphqlService) {
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 10);
  }

  ngOnInit() {
    this.loadGateways();
  }

  ngOnDestroy(): void {
    console.log('destroyed');
    this._destroy$.next();
    this._destroy$.complete();
  }

  cancelClicked() {
    this._visible.next(false);
  }

  saveClicked() {
    this._visible.next(false);
    this.createdRequest$.next(this.transaction);
  }

  setGateway(id: number) {
    const gateway = this.gateways.find((g) => g.id == id);
    if (!gateway) {
      this.removeGateway();
      return;
    }

    this.transaction.gateway = gateway;
    this.transaction.gateway_id = gateway.id;
    this.transaction.gateway_name = gateway.name;
  }

  removeGateway() {
    this.transaction.gateway = null;
    this.transaction.gateway_id = null;
    this.transaction.gateway_name = null;
  }

  loadGateways() {
    this.gatewaysLoading = true;
    this.cdr.detectChanges();

    this.graphqlService.queries.parameters.transactionGateway.list()
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        this.gateways = res.data;
        this.gatewaysLoading = false;
        this.cdr.detectChanges();
      });
  }
}
