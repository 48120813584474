import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import _ from 'lodash';

import { Bank } from 'app/shared/models/financial/bank';
import { FinancialBankService } from 'app/core/services/financial-bank.service';
import {GraphqlService} from "../../../../core/services/graphql.service";

@Component({
  selector: 'doffice-creation-bank-account-modal',
  templateUrl: 'creation-bank-account-modal.component.html',
  styleUrls: ['creation-bank-account-modal.component.scss'],
})
export class CreationBankAccountModalComponent implements OnDestroy {
  private _visible = new BehaviorSubject<boolean>(true);
  get visible$() {
    return this._visible.asObservable();
  }
  get visible() {
    return this._visible.getValue();
  }
  set visible(visible: boolean) {
    this._visible.next(visible);
  }

  bank = Bank.new();
  saveLoading = false;

  created$ = new Subject<number>();
  private _destroy$ = new Subject<void>();

  constructor( private cdr: ChangeDetectorRef, private bankService: FinancialBankService, private graphql: GraphqlService) {
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 10);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  cancelClicked() {
    this._visible.next(false);
  }

  saveClicked() {
    this.cdr.detectChanges();

    let input = this.bankService.createBankMutationInput(this.bank);
    this.graphql.mutations.financial.bank.update({input:input})
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        this._visible.next(false);
        this.created$.next(res.id);
      });
  }
}
