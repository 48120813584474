import _ from 'lodash';
import {Country} from '../general/country';
import {Company} from './company';
import {License} from './license';
import {User} from './user';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {ISubscriber, ISubscriberInput, ISubscriberLicense, ISubscriberLicenseDiscount, ISubscriberMutationInput, ISubscriberSettings, ISubscriberSettingsInput} from "../../../core/graphql/generated/types";
import {GetDateScalar, GetDateTimeScalar, SetDateScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";
import {countries} from "../../data/country";


export enum SubscriberTypeEnum {
  VATFREESMALLCOMPANY = 1,
  ONEMANBUSINESS = 2,
  ASSOCIATION = 3,
}

export class SubscriberLicense implements ISubscriberLicense {
  id: number = 0;
  subscriberId: number = 0;
  subscriber: Subscriber;
  licenseId: string = '';
  license: License;
  licenseBasePrice: number = 0;
  discount: number = 0;
  upgradedFromPrice: number = 0;
  basePrice: number = 0;
  credit: number = 0;
  price: number = 0;
  invoiceRaw: string = '';
  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  start: string = "";
  private _start: Date;

  get Start() {return this._start;}

  set Start(date: Date) {
    this._start = date;
    this.start = SetDateScalar(date);
  }

  end: string = "";
  private _end: Date;

  get End() {return this._end;}

  set End(date: Date) {
    this._end = date;
    this.end = SetDateScalar(date);
  }

  period: number = 0;
  userBasePrice: number = 0;
  userAmount: number = 0;
  upgraded: string = "";
  private _upgraded: Date;

  get Upgraded() {return this._upgraded;}

  set Upgraded(date: Date) {
    this._upgraded = date;
    this.upgraded = SetDateTimeScalar(date);
  }

  constructor(license: ISubscriberLicense = null) {
    if (license === null) {
      return;
    }

    constructFromInterface(this, license);

    if (license.start) {this._start = GetDateScalar(license.start);}
    if (license.end) {this._end = GetDateScalar(license.end);}
    if (license.created) {this._created = GetDateTimeScalar(license.created);}
    if (license.updated) {this._updated = GetDateTimeScalar(license.updated);}
    if (license.upgraded) {this._upgraded = GetDateTimeScalar(license.upgraded);}
    if (license.subscriber) {
      this.subscriber = new Subscriber(license.subscriber);
    }
    if (license.license) {
      this.license = new License(license.license);
    }
  }
}

export class SubscriberLicenseDiscount implements ISubscriberLicenseDiscount {
  id: number = 0;
  subscriberId: number = 0;
  description: string = '';
  amount: number = 0;
  currentAmount: number = 0;
  percentage: number = 0;
  periodLimit: number = 0;
  currentPeriodLimit: number = 0;
  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  constructor(discount: ISubscriberLicenseDiscount = null) {
    if (discount === null) {
      return;
    }

    constructFromInterface(this, discount);
  }
}

export class Subscriber implements ISubscriber {
  id: number = 0;
  name: string = '';
  vat: string = '';
  rpr: string = '';
  activated: boolean = false;
  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  settings: SubscriberSettings;
  defaultCompany: Company;
  street: string = '';
  houseNumber: string = '';
  city: string = '';
  zip: string = '';
  county: string = '';
  countryId: number = 0;
  country: Country;
  type: SubscriberTypeEnum = SubscriberTypeEnum.ONEMANBUSINESS;
  activeLicenses: SubscriberLicense[] = [];
  expiredLicenses: SubscriberLicense[] = [];
  upgradedLicenses: SubscriberLicense[] = [];
  activeLicenseDiscounts: SubscriberLicenseDiscount[] = [];
  resellerPaymentType: ResellerPaymentTypeEnum = ResellerPaymentTypeEnum.SYSTEM;
  allowAlphaModules: boolean = false;
  allowBetaModules: boolean = false;
  preferredLicense: string = '';
  modulerelation: boolean = false;
  modulearticle: boolean = false;
  modulesalesinvoice: boolean = false;
  modulesalesinvoicereminders: boolean = false;
  modulesalesorder: boolean = false;
  modulesalesquote: boolean = false;
  moduleworkperformance: boolean = false;
  modulesubscription: boolean = false;
  modulepurchaseinvoice: boolean = false;
  modulepurchaseorder: boolean = false;
  modulestock: boolean = false;
  moduleprepaidservice: boolean = false;
  moduleaccountancy: boolean = false;
  extraDocuments: number = 0;
  extraUsers: number = 0;
  credit: number = 0;
  documentUsageAmount: number = 0;
  userUsageAmount: number = 0;
  resellerId: number = 0;
  accountancyId: number = 0;
  autoRenewLicense: boolean = false;
  autoRenewLicensePeriod: number = 1;

  users: User[] = [];

  public get Licenses() {
    return _.sortBy(
      this.activeLicenses
        .concat(this.expiredLicenses)
        .concat(this.upgradedLicenses)
        .filter((v) => !_.isNil(v)),
      (license) => license.created
    )
      .reverse();
  }

  public get nameAndVat(): string {
    return `${this.name} (${this.vat})`;
  }

  constructor(subscriber: ISubscriber = null) {
    if (subscriber === null) {
      return;
    }

    constructFromInterface(this, subscriber);

    if (subscriber?.settings) {
      this.settings = new SubscriberSettings(subscriber.settings);
    }
    if (subscriber?.defaultCompany) {
      this.defaultCompany = new Company(subscriber.defaultCompany);
    }

    if (subscriber?.countryId) {
      this.country = new Country(countries.find(x => x.id == subscriber.countryId));
    }
    if (subscriber?.activeLicenses) {
      this.activeLicenses = subscriber.activeLicenses.map((license) => new SubscriberLicense(license));
    }
    if (subscriber?.expiredLicenses) {
      this.expiredLicenses = subscriber.expiredLicenses.map((license) => new SubscriberLicense(license));
    }
    if (subscriber?.upgradedLicenses) {
      this.upgradedLicenses = subscriber.upgradedLicenses.map((license) => new SubscriberLicense(license));
    }
    if (subscriber?.activeLicenseDiscounts) {
      this.activeLicenseDiscounts = subscriber.activeLicenseDiscounts.map((discount) => new SubscriberLicenseDiscount(discount));
    }
    if (subscriber?.users) {
      this.users = subscriber.users.map((x) => new User(x));
    }
  }

  public convertToInput(): ISubscriberInput {
    return {
      id: +this.id,
      name: '' + this.name,
      type: +this.type,
      allowAlphaModules: !!this.allowAlphaModules,
      allowBetaModules: !!this.allowBetaModules,
      city: '' + this.city,
      countryId: +this.countryId,
      county: (this.county ?? '').length > 0 ? '' + this.county : null,
      houseNumber: '' + this.houseNumber,
      rpr: (this.rpr ?? '').length > 0 ? '' + this.rpr : null,
      street: '' + this.street,
      zip: '' + this.zip,
      vat: '' + this.vat,
    };
  }

  public createMutationInput(originalSubscriber: Subscriber = null): ISubscriberMutationInput {
    let mutationInput: ISubscriberMutationInput = {id: this.id, subscriber: null, settings: null};

    mutationInput.subscriber = _.isEqual(this.convertToInput(), originalSubscriber?.convertToInput()) ? null : this.convertToInput();
    mutationInput.settings = _.isEqual(this.settings.convertToInput(), originalSubscriber?.settings?.convertToInput()) ? null : this.settings.convertToInput(); /* Check if settings are updated */

    return mutationInput;
  }
}

export class SubscriberSettings implements ISubscriberSettings {
  subscriber_id: number = 0;
  entity_relation_nr_prefix: string = '';
  entity_relation_nr_size: number = 0;
  hr_company_nr_prefix: string = '';
  hr_company_nr_size: number = 0;
  document_order_nr_prefix: string = '';
  document_order_nr_size: number = 0;
  document_quotation_nr_prefix: string = '';
  document_quotation_nr_size: number = 0;
  document_purchaseorder_nr_prefix: string = '';
  document_purchaseorder_nr_size: number = 0;
  document_subscription_nr_prefix: string = '';
  document_subscription_nr_size: number = 0;
  document_product_delivery_nr_prefix: string = '';
  document_product_delivery_nr_size: number = 0;
  document_product_reception_nr_prefix: string = '';
  document_product_reception_nr_size: number = 0;
  document_invoice_reminder_nr_prefix: string = '';
  document_invoice_reminder_nr_size: number = 0;
  ledger_writeoff_nr_prefix: string = '';
  ledger_writeoff_nr_size: number = 0;
  document_split_symbol: string = '';
  document_year_prefix_type: SubscriberDocumentYearType = SubscriberDocumentYearType.FULL;
  document_invoice_override_next_number: number = 0;
  document_creditnote_override_next_number: number = 0;
  enable_vies_lookup: boolean = false;
  default_lednumber_id: number = 0;
  default_paymentterms: number = 0;
  default_vat_code: string = "";
  invoiceReminderAutoConfirm: boolean = false;
  invoiceReminderAutoSendOnAutoConfirm: boolean = false;
  invoiceReminderAutoSendOnAutoSucceed: boolean = false;
  relationInvoiceReminderAddNonOverdue: boolean = false;
  relationInvoiceReminderGracePeriod: number = 0;
  relationInvoiceReminderTimeSpan: number = 0;
  relationInvoiceReminderSurpriceLevelOne: number = 0;
  relationInvoiceReminderSurpercentageLevelOne: number = 0;
  relationInvoiceReminderSurpriceMinLevelOne: number = 0;
  relationInvoiceReminderSurpriceMaxLevelOne: number = 0;
  relationInvoiceReminderSurpriceLevelTwo: number = 0;
  relationInvoiceReminderSurpercentageLevelTwo: number = 0;
  relationInvoiceReminderSurpriceMinLevelTwo: number = 0;
  relationInvoiceReminderSurpriceMaxLevelTwo: number = 0;
  relationInvoiceReminderSurpriceLevelThree: number = 0;
  relationInvoiceReminderSurpercentageLevelThree: number = 0;
  relationInvoiceReminderSurpriceMinLevelThree: number = 0;
  relationInvoiceReminderSurpriceMaxLevelThree: number = 0;
  relationInvoiceReminderLevelOneAutoEmailBody: string = '';
  relationInvoiceReminderLevelTwoAutoEmailBody: string = '';
  relationInvoiceReminderLevelThreeAutoEmailBody: string = '';
  automaticPaymentMatching: boolean = false;
  timezone: string = '';
  paymentMatchingAddBankaccountToRelation: boolean = false;
  pdfWorkperformanceGeneratePdf: boolean = false;
  workPerformanceAddDateToExtras = false;

  constructor(settings: ISubscriberSettings = null) {
    if (settings === null) {
      return;
    }

    constructFromInterface(this, settings);
  }

  convertToInput(): ISubscriberSettingsInput {
    return {
      entity_relation_nr_prefix: this.entity_relation_nr_prefix,
      entity_relation_nr_size: +this.entity_relation_nr_size,
      hr_company_nr_prefix: this.hr_company_nr_prefix,
      hr_company_nr_size: +this.hr_company_nr_size,
      document_order_nr_prefix: this.document_order_nr_prefix,
      document_order_nr_size: +this.document_order_nr_size,
      document_quotation_nr_prefix: this.document_quotation_nr_prefix,
      document_quotation_nr_size: +this.document_quotation_nr_size,
      document_purchaseorder_nr_prefix: this.document_purchaseorder_nr_prefix,
      document_purchaseorder_nr_size: +this.document_purchaseorder_nr_size,
      document_subscription_nr_prefix: this.document_subscription_nr_prefix,
      document_subscription_nr_size: +this.document_subscription_nr_size,
      document_product_delivery_nr_prefix: this.document_product_delivery_nr_prefix,
      document_product_delivery_nr_size: +this.document_product_delivery_nr_size,
      document_product_reception_nr_prefix: this.document_product_reception_nr_prefix,
      document_product_reception_nr_size: +this.document_product_reception_nr_size,
      document_invoice_reminder_nr_prefix: this.document_invoice_reminder_nr_prefix,
      document_invoice_reminder_nr_size: +this.document_invoice_reminder_nr_size,
      ledger_writeoff_nr_prefix: this.ledger_writeoff_nr_prefix,
      ledger_writeoff_nr_size: +this.ledger_writeoff_nr_size,
      document_split_symbol: this.document_split_symbol,
      document_year_prefix_type: this.document_year_prefix_type,
      document_invoice_override_next_number: this.document_invoice_override_next_number ? +this.document_invoice_override_next_number : null,
      document_creditnote_override_next_number: this.document_creditnote_override_next_number ? +this.document_creditnote_override_next_number : null,
      enable_vies_lookup: this.enable_vies_lookup,
      default_lednumber_id: this.default_lednumber_id,
      default_paymentterms: !_.isNil(this.default_paymentterms) ? +this.default_paymentterms : null,
      default_vat_code: this.default_vat_code,
      invoiceReminderAutoConfirm: !!this.invoiceReminderAutoConfirm,
      invoiceReminderAutoSendOnAutoConfirm: !!this.invoiceReminderAutoSendOnAutoConfirm,
      invoiceReminderAutoSendOnAutoSucceed: !!this.invoiceReminderAutoSendOnAutoSucceed,
      relationInvoiceReminderAddNonOverdue: !!this.relationInvoiceReminderAddNonOverdue,
      relationInvoiceReminderGracePeriod: +this.relationInvoiceReminderGracePeriod,
      relationInvoiceReminderTimeSpan: +this.relationInvoiceReminderTimeSpan,
      relationInvoiceReminderSurpriceLevelOne: +this.relationInvoiceReminderSurpriceLevelOne,
      relationInvoiceReminderSurpercentageLevelOne: +this.relationInvoiceReminderSurpercentageLevelOne,
      relationInvoiceReminderSurpriceMinLevelOne: +this.relationInvoiceReminderSurpriceMinLevelOne,
      relationInvoiceReminderSurpriceMaxLevelOne: +this.relationInvoiceReminderSurpriceMaxLevelOne,
      relationInvoiceReminderSurpriceLevelTwo: +this.relationInvoiceReminderSurpriceLevelTwo,
      relationInvoiceReminderSurpercentageLevelTwo: +this.relationInvoiceReminderSurpercentageLevelTwo,
      relationInvoiceReminderSurpriceMinLevelTwo: +this.relationInvoiceReminderSurpriceMinLevelTwo,
      relationInvoiceReminderSurpriceMaxLevelTwo: +this.relationInvoiceReminderSurpriceMaxLevelTwo,
      relationInvoiceReminderSurpriceLevelThree: +this.relationInvoiceReminderSurpriceLevelThree,
      relationInvoiceReminderSurpercentageLevelThree: +this.relationInvoiceReminderSurpercentageLevelThree,
      relationInvoiceReminderSurpriceMinLevelThree: +this.relationInvoiceReminderSurpriceMinLevelThree,
      relationInvoiceReminderSurpriceMaxLevelThree: +this.relationInvoiceReminderSurpriceMaxLevelThree,
      relationInvoiceReminderLevelOneAutoEmailBody: this.relationInvoiceReminderLevelOneAutoEmailBody,
      relationInvoiceReminderLevelTwoAutoEmailBody: this.relationInvoiceReminderLevelTwoAutoEmailBody,
      relationInvoiceReminderLevelThreeAutoEmailBody: this.relationInvoiceReminderLevelThreeAutoEmailBody,
      automaticPaymentMatching: !!this.automaticPaymentMatching,
      timezone: '' + this.timezone,
      paymentMatchingAddBankaccountToRelation: !!this.paymentMatchingAddBankaccountToRelation,
      pdfWorkperformanceGeneratePdf: !!this.pdfWorkperformanceGeneratePdf,
      workPerformanceAddDateToExtras: !!this.workPerformanceAddDateToExtras,
    };
  }
}

export enum PaymentPartnerEnum {
  MOLLIE = 1,
  STRIPE = 2,
}

export enum ResellerPaymentTypeEnum {
  SYSTEM = 1,
  FULLRESELLER = 2,
}

export enum DocumentLogoLocation {
  NONE = 1,
  BACKGROUND = 2,
  TOP = 3,
  ABOVECOMPANY = 4,
}

export enum DocumentLogoLocationEnumLanguage {
  NONE = 'UI_LOGO_LOCATION_NONE',
  BACKGROUND = 'UI_LOGO_LOCATION_BACKGROUND',
  TOP = 'UI_LOGO_LOCATION_TOP',
  ABOVECOMPANY = 'UI_LOGO_LOCATION_ABOVE_COMPANY',
}

export enum SubscriberDocumentYearType {
  FULL = 1,
  SHORT = 2,
}
