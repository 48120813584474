import {Injectable} from "@angular/core";
import {EncryptStorage} from 'encrypt-storage';
import {environment} from "../../../environments/environment";
import {AuthenticationClient, Token} from "../swagger/generated/swagger-client";
import {mergeMap, Observable, combineLatest, BehaviorSubject} from "rxjs";
import {catchError, tap} from "rxjs/operators";
import {GraphqlService} from "./graphql.service";
import * as Sentry from '@sentry/browser';
import {User, UserPermission} from "../../shared/models/entity/user";
import {Subscriber} from "../../shared/models/entity/subscriber";
import {Permission} from "../../shared/data/permission";
import _ from "lodash";
import {TranslocoService} from "@jsverse/transloco";
import {ConvertionService} from "./convertion.service";
import {TranslationService} from "./translation.service";
import {ConvertionInjector, TranslationInjector} from "../../app.module";
import moment from "moment";
import {LanguageService} from "./language.service";
import {IPartner} from "../graphql/generated/types";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _clientCredentialStorage = new EncryptStorage(environment.encryption.auth.client, {prefix: '@cth', storageType: "localStorage"});
  private _partnerCredentialStorage = new EncryptStorage(environment.encryption.auth.partner, {prefix: '@pth', storageType: "localStorage"});

  private _clientRefreshTokenTimeoutId;
  private _partnerRefreshTokenTimeoutId;

  private _activeClientToken = new BehaviorSubject<Token>(null);

  get clientToken() {
    if (_.isNil(this._activeClientToken.getValue())) {
      return null;
    }

    return (this._activeClientToken.getValue().key ?? '').length > 0 ? this._activeClientToken.getValue().key : null;
  }

  private _activePartnerToken = new BehaviorSubject<Token>(null);

  get partnerToken() {
    if (_.isNil(this._activePartnerToken.getValue())) {
      return null;
    }

    return (this._activePartnerToken.getValue().key ?? '').length > 0 ? this._activePartnerToken.getValue().key : null;
  }

  private _activeUser = new BehaviorSubject<User>(null);
  public get activeUser() {
    return this._activeUser.asObservable()
  }

  public get activeUserSnapshot() {
    return this._activeUser.getValue();
  }

  private _activeSubscriber = new BehaviorSubject<Subscriber>(null);
  public get activeSubscriber() {
    return this._activeSubscriber.asObservable();
  }

  public get activeSubscriberSnapshot() {
    return this._activeSubscriber.getValue();
  }

  private _activePartner = new BehaviorSubject<IPartner>(null);
  public get activePartner() {
    return this._activePartner.asObservable();
  }

  public get activePartnerSnapshot() {
    return this._activePartner.getValue();
  }

  constructor(private graphqlService: GraphqlService, private translocoService: TranslocoService, private convertionService: ConvertionService, private translationService: TranslationService, private authenticationClient: AuthenticationClient) {
    this.loadStorage();

    this.activeUser.subscribe(this.handleUserChange);
    this.activeSubscriber.subscribe(this.handleSubscriberChange);
    this.activePartner.subscribe(this.handlePartnerChange);
    this._activeClientToken.subscribe(this.handleClientTokenChange);
    this._activePartnerToken.subscribe(this.handlePartnerTokenChange);
  }

  registerReceivedClientToken() {
    return (source: Observable<Token>) => {
      return source.pipe(
        // Check if token is available
        tap((token) => {
            if (token == null) {
              throw new Error("No token received")
            }
          }
        ),
        // Store the received token
        tap((token) => {
          this._activeClientToken.next(token);

          this._clientCredentialStorage.setItem("ky", token.key);
          this._clientCredentialStorage.setItem("kyxp", token.expiration);
          this._clientCredentialStorage.setItem("kyip", token.ip);
        }),
        // Get the active user and subscriber
        mergeMap(() => combineLatest({
          user: this.graphqlService.queries.entity.account.activeUser(),
          subscriber: this.graphqlService.queries.entity.account.activeSubscriber()
        })),
        // If an error has occured, clear the credentials storage
        catchError((err) => {
          this._clientCredentialStorage.clear();
          throw new Error(err);
        }),
        // Set sentry and cache objects
        tap(({user, subscriber}) => {
          Sentry.setUser({
            email: user ? user.mail : '',
            username: user ? user.name : '',
            id: user ? user.id : 0,
            subscriberId: subscriber ? subscriber.id : 0
          });

          this._activeUser.next(user);
          this._activeSubscriber.next(subscriber);
        })
      )
    }
  }

  registerReceivedPartnerToken() {
    return (source: Observable<Token>) => {
      return source.pipe(
        // Check if token is available
        tap((token) => {
            if (token == null) {
              throw new Error("No token received")
            }
          }
        ),
        // Store the received token
        tap((token) => {
          this._activePartnerToken.next(token);

          this._partnerCredentialStorage.setItem("ky", token.key);
          this._partnerCredentialStorage.setItem("kyxp", token.expiration);
          this._partnerCredentialStorage.setItem("kyip", token.ip);
        }),
        // Get the active partner.
        mergeMap(() =>
          this.graphqlService.queries.partner.partner.active()
        ),
        // If an error has occured, clear the credentials storage
        catchError((err) => {
          this._partnerCredentialStorage.clear();
          throw new Error(err);
        }),
        tap((partner) => {
          this._activePartner.next(partner);
        })
      )
    }
  }

  logoffClient() {
    this._activeUser.next(null);
    this._activeSubscriber.next(null);
    this._activeClientToken.next(null);
    this._clientCredentialStorage.clear();
  }

  logoffPartner() {
    this._activePartner.next(null);
    this._activePartnerToken.next(null);
    this._partnerCredentialStorage.clear();
  }

  private handleUserChange(user: User) {
    if (_.isNil(user)) {
      if (this.translationService) {
        this.translationService.language = this.translationService.defaultIso;
        TranslationInjector.get(TranslationService).language = this.translationService.defaultIso;
      }

      if (this.translocoService) {
        this.translocoService.load(navigator.language.substring(0, 2)
          .toLowerCase())
          .subscribe();
        this.translocoService.setActiveLang(navigator.language.substring(0, 2)
          .toLowerCase());
      }

      if (this.convertionService) {
        this.convertionService.settings = {
          dateFormat: 'DD/MM/YYYY',
          dateTimeFormat: 'DD/MM/YYYY HH:mm:ss',
          timezone: 'Europe/Brussels',
          moneySettings: {
            decimals: 2,
            symbol: '€',
            symbolLocation: 'BEFORE',
          },
          numberSettings: {
            decimalSeperationSymbol: ',',
            digitSeperationSymbol: '.',
          },
        };
        ConvertionInjector.get(ConvertionService).settings = this.convertionService.settings;
      }
    } else {
      const iso = LanguageService.ConvertLanguageIDToIso(user.settings.language_id);
      if (this.translationService) {
        this.translationService.language = iso;
        TranslationInjector.get(TranslationService).language = this.translationService.language;
      }

      if (this.translocoService) {
        this.translocoService.load(iso.toLowerCase())
          .subscribe();
        this.translocoService.setActiveLang(iso.toLowerCase());
      }

      if (this.convertionService) {
        this.convertionService.settings = {
          dateFormat: 'DD/MM/YYYY',
          dateTimeFormat: 'DD/MM/YYYY HH:mm:ss',
          timezone: 'Europe/Brussels',
          moneySettings: {
            decimals: 2,
            symbol: '€',
            symbolLocation: 'BEFORE',
          },
          numberSettings: {
            decimalSeperationSymbol: ',',
            digitSeperationSymbol: '.',
          },
        }; // TODO: Make dynamic
        ConvertionInjector.get(ConvertionService).settings = this.convertionService.settings;
      }
    }
  }

  private handleSubscriberChange(subscriber: Subscriber) {
    if (_.isNil(subscriber)) {
    } else {
    }
  }

  private handlePartnerChange(partner: IPartner) {
    if (_.isNil(partner)) {
      if (this.translationService) {
        this.translationService.language = this.translationService.defaultIso;
        TranslationInjector.get(TranslationService).language = this.translationService.defaultIso;
      }

      if (this.translocoService) {
        this.translocoService.load(navigator.language.substring(0, 2)
          .toLowerCase())
          .subscribe();
        this.translocoService.setActiveLang(navigator.language.substring(0, 2)
          .toLowerCase());
      }
    } else {
      const iso = LanguageService.ConvertLanguageIDToIso(partner.languageId);
      if (this.translationService) {
        this.translationService.language = iso;
        TranslationInjector.get(TranslationService).language = this.translationService.language;
      }

      if (this.translocoService) {
        this.translocoService.load(iso.toLowerCase())
          .subscribe();
        this.translocoService.setActiveLang(iso.toLowerCase());
      }
    }
  }

  private handleClientTokenChange(token: Token) {
    if (_.isNil(token)) {
      clearTimeout(this._clientRefreshTokenTimeoutId);
    } else {
      const timeUntilExpired = moment(token.expiration)
        .subtract(5, 'minutes')
        .diff(moment());
      if (timeUntilExpired <= 0) {
        this.authenticationClient.renewToken(token.key)
          .pipe(
            this.registerReceivedClientToken()
          )
          .subscribe(() => {
          });
      } else {
        this._clientRefreshTokenTimeoutId = setTimeout(() => {
          this.authenticationClient.renewToken(token.key)
            .pipe(
              this.registerReceivedClientToken()
            )
            .subscribe(() => {
            });
        }, timeUntilExpired);
      }
    }
  }

  private handlePartnerTokenChange(token: Token) {
    if (_.isNil(token)) {
      clearTimeout(this._partnerRefreshTokenTimeoutId);
    } else {
      const timeUntilExpired = moment(token.expiration)
        .subtract(5, 'minutes')
        .diff(moment());
      if (timeUntilExpired <= 0) {
        this.authenticationClient.renewPartnerToken(token.key)
          .pipe(
            this.registerReceivedPartnerToken()
          )
          .subscribe(() => {
          });
      } else {
        this._partnerRefreshTokenTimeoutId = setTimeout(() => {
          this.authenticationClient.renewToken(token.key)
            .pipe(
              this.registerReceivedPartnerToken()
            )
            .subscribe(() => {});
        }, timeUntilExpired);
      }
    }
  }

  public reloadUser() {
    this.graphqlService.queries.entity.account.activeUser()
      .subscribe((user) => {
        this._activeUser.next(user);
      })
  }

  public reloadSubscriber() {
    this.graphqlService.queries.entity.account.activeSubscriber()
      .subscribe((subscriber) => {
        this._activeSubscriber.next(subscriber);
      })
  }

  public reloadPartner() {
    this.graphqlService.queries.partner.partner.active()
      .subscribe((partner) => {
        this._activePartner.next(partner);
      })
  }

  public loadStorage() {
    const clientTokenKey = this._clientCredentialStorage.getItem('ky');
    const clientTokenExpiration = this._clientCredentialStorage.getItem('kyxp');

    if ((clientTokenKey ?? '').length > 0 && moment(clientTokenExpiration)
      .isValid() && moment(clientTokenExpiration)
      .diff(moment()) > 0) {
      this.authenticationClient.validateToken(clientTokenKey)
        .pipe(
          tap(token => {
            if (!token) {
              throw new Error("Incorrect client token in the cache, removed client cache!");
            }
          }),
          this.registerReceivedClientToken()
        )
        .subscribe({
          next: () => {
          },
          error: () => {
            this.logoffClient();
          }
        });
    }

    const partnerTokenKey = this._partnerCredentialStorage.getItem('ky');
    const partnerTokenExpiration = this._partnerCredentialStorage.getItem('kyxp');

    if ((partnerTokenKey ?? '').length > 0 && moment(partnerTokenExpiration)
      .isValid() && moment(partnerTokenExpiration)
      .diff(moment()) > 0) {
      this.authenticationClient.validatePartnerToken(partnerTokenKey)
        .pipe(
          tap(token => {
            if (!token) {
              throw new Error("Incorrect partner token in the cache, removed partner cache!");
            }
          }),
          this.registerReceivedPartnerToken()
        )
        .subscribe({
          next: () => {
          },
          error: () => {
            this.logoffClient();
          }
        });
    }
  }

  public hasOnePermissionGroup(groups: Permission[][]) {
    for (let group of
      groups) {
      const hasPermissions = this.checkPermissions(group);
      if (hasPermissions) {
        return true;
      }
    }
    return false;
  }

  public checkPermissions(permissions: Permission[], logMissingPermissions: boolean = false) {
    if (!this.activeUserSnapshot) {
      return false;
    }

    let enabledPermissions: UserPermission[] = _.cloneDeep(this.activeUserSnapshot.permissions);

    for (let permission of
      permissions) {
      if (enabledPermissions.find((x) => x.permission_id == permission)) {
        permissions = permissions.filter((x) => x != permission);
      }
    }

    if (logMissingPermissions && permissions.length > 0) {
      console.error('Missing permissions: ' + permissions.join(' ; '));
    }

    return permissions.length <= 0;
  }
}
