import {TranslationService} from 'app/core/services/translation.service';
import {TranslationInjector} from 'app/app.module';
import {Country} from '../general/country';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {isStringNullOrEmpty} from 'app/shared/logic/system/general.logic';
import {IAddress, IAddressInput} from "../../../core/graphql/generated/types";
import {countries} from "../../data/country";


export class Address implements IAddress {
  id: number = 0;
  subscriber_id: number = 0;
  street: string = '';
  number: string = '';
  city: string = '';
  zip: string = '';
  county: string = '';
  default: boolean = false;

  get Default(): string {
    return this.default ? TranslationInjector.get(TranslationService)
      .getValueByKey('UI_YES') : TranslationInjector.get(TranslationService)
      .getValueByKey('UI_NO');
  }

  country_id: number = 0;
  country: Country;

  get FullAddress(): string {
    const streetInfo = [];
    const stateInfo = [];

    if (this.street) {
      streetInfo.push(this.street);
    }
    if (this.number) {
      streetInfo.push(this.number);
    }

    if (this.zip) {
      stateInfo.push(this.zip);
    }
    if (this.city) {
      stateInfo.push(this.city);
    }

    if (isStringNullOrEmpty(this.street) && isStringNullOrEmpty(this.number) && isStringNullOrEmpty(this.zip) && isStringNullOrEmpty(this.city)) {
      return null;
    }

    return `${streetInfo.join(' ')}, ${stateInfo.join(' ')}, ${TranslationInjector.get(TranslationService)
      .getValueByKey(this.country?.name)}`;
  }

  get MapsUrl(): string {
    return 'https://www.google.com/maps/search/?api=1&query=' + this.FullAddress.replace(',', '%2C')
      .replace(' ', '+');
  }

  get MapsDirection(): string {
    return 'https://www.google.com/maps/dir/?api=1&destination=' + this.FullAddress.replace(',', '%2C')
      .replace(' ', '+');
  }

  isValid(): boolean {
    return (
      this.street != null &&
      this.street.trim() != '' &&
      this.number != null &&
      this.number.trim() != '' &&
      this.city != null &&
      this.city.trim() != '' &&
      this.zip != null &&
      this.zip.trim() != '' &&
      this.country_id > 0
    );
  }

  constructor(address: IAddress) {
    if (address === null) {
      return;
    }

    constructFromInterface(this, address)

    if (address.country_id) {
      this.country = new Country(countries.find(c => c.id == address.country_id));
    }
  }

  public convertToInput(): IAddressInput {
    return {
      id: +this.id,
      street: this.street,
      number: this.number,
      city: this.city,
      zip: this.zip,
      county: this.county,
      country_id: +this.country_id,
      default: this.default,
    };
  }

  public static new(defaultCountry: Country = null): Address {
    return new Address({
      id: null,
      subscriber_id: null,
      street: null,
      number: null,
      city: null,
      zip: null,
      county: null,
      default: null,
      country_id: defaultCountry ? defaultCountry.id : null,
      country: defaultCountry,
    });
  }
}
