import {Injectable} from '@angular/core';
import {Relation} from 'app/shared/models/relation/relation.class';
import _ from 'lodash';
import {ContactService} from './contact.service';
import {AddressService} from './address.service';
import {RelationEmailService} from './email.service';
import {RelationPhoneService} from './phone.service';
import {RelationBankAccountService} from './bank-account.service';
import {Contact} from 'app/shared/models/relation/contact';
import {RelationParameterService} from './parameter.service';
import {RelationParameter} from 'app/shared/models/relation/relation-parameter';
import {MutationInputObject} from 'app/shared/models/system/mutation-object';
import {IContactMutationInput, IRelationMutationInput, IRelationParameterMutationInput} from "../graphql/generated/types";

@Injectable({
  providedIn: 'root',
})
export class RelationService {
  constructor(
    private contactService: ContactService,
    private addressService: AddressService,
    private emailService: RelationEmailService,
    private phoneService: RelationPhoneService,
    private bankaccountService: RelationBankAccountService,
    private parameterService: RelationParameterService
  ) {}

  public createRelationMutationInput(relation: Relation, originalRelation: Relation = null): IRelationMutationInput {
    let mutationInput: IRelationMutationInput = {id: relation.id, relation: null, contacts: null, addresses: null, bankAccounts: null, emails: null, phones: null, parameters: null};
    mutationInput.relation = _.isEqual(relation.convertToInput(), originalRelation ? originalRelation.convertToInput() : null) ? null : relation.convertToInput();
    mutationInput.parameters = this.createRelationParameterMutations(relation.parameters ?? [], originalRelation ? originalRelation.parameters : []);
    mutationInput.contacts = this.contactService.createContactMutations(relation.contacts ?? [], originalRelation ? originalRelation.contacts : []);
    mutationInput.addresses = this.addressService.createAddressMutations(relation.addresses ?? [], originalRelation ? originalRelation.addresses : []);
    mutationInput.emails = this.emailService.createEmailMutations(relation.emails ?? [], originalRelation ? originalRelation.emails : []);
    mutationInput.phones = this.phoneService.createPhoneMutations(relation.phones ?? [], originalRelation ? originalRelation.phones : []);
    mutationInput.bankAccounts = this.bankaccountService.createBankAccountMutations(relation.bankAccounts ?? [], originalRelation ? originalRelation.bankAccounts : []);
    mutationInput.parameters = this.parameterService.createParameterMutations(relation.parameters ?? [], originalRelation ? originalRelation.parameters : []);

    return mutationInput;
  }

  public createrRelationContactMutationInput(contact: Contact, originalContact: Contact = null): IContactMutationInput {
    let mutationInput: IContactMutationInput = {id: contact.id, contact: null};
    mutationInput.contact = _.isEqual(contact.convertToInput(), originalContact ? originalContact.convertToInput() : null) ? null : contact.convertToInput();
    return mutationInput;
  }

  private createRelationParameterMutations(parameters: RelationParameter[], originalParameters: RelationParameter[] = null): MutationInputObject<IRelationParameterMutationInput> {
    if (_.isNil(originalParameters)) {
      parameters.forEach((subline) => {
        subline.id == null;
      });
    }

    const input: MutationInputObject<IRelationParameterMutationInput> = {deletes: [], updates: []};

    const newParameters = parameters.filter((parameter) => _.isNil(parameter.id))
      .map((parameter) => parameter.convertToInput());
    const updatedSublines = parameters
      .filter((parameter) => !_.isNil(parameter.id))
      .map((parameter) => parameter.convertToInput())
      .filter((parameter) => !_.isEqual(parameter, originalParameters?.find((s) => s.id == parameter.id)
        ?.convertToInput()));
    input.updates = _.concat(newParameters, updatedSublines)
      .map((parameter) => {
        return {
          id: parameter.id,
          parameter: parameter,
        };
      });

    input.deletes = _.differenceBy(
      originalParameters,
      parameters.filter((parameter) => !_.isNil(parameter.id)),
      (parameter) => parameter.id
    )
      .map((parameter) => +parameter.id);

    return input;
  }
}
