import {Injectable} from '@angular/core';
import {User} from 'app/shared/models/entity/user';
import {map, of} from 'rxjs';
import {CompleteSubscriberOnboarding, CompleteUserOnboardingBody, EntityVatCheck, OnboardingClient, PasswordResetBody} from "../swagger/generated/swagger-client";

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  constructor(private onboardingClient: OnboardingClient) {}

  getUserByResetToken(token: string) {
    return this.onboardingClient.getUserPasswordResetInformation(token)
      .pipe(map(u => new User(u as any)));
  }

  getSubscriberOnboardingByToken(token: string) {
    return this.onboardingClient.getSubscriberByOnboardingToken(token);
  }

  getUserByOnboardingToken(token: string) {
    return this.onboardingClient.getUserByOnboardingToken(token)
      .pipe(map(x => new User(x as any)));
  }

  checkVatNumber(vatnumber: string) {
    if ((vatnumber ?? '').trim().length <= 0) {
      return of({valid: false} as EntityVatCheck);
    }
    return this.onboardingClient.checkVatNumber(vatnumber);
  }

  completeSubscriberOnboarding(input: CompleteSubscriberOnboarding) {
    return this.onboardingClient.completeSubscriberOnboarding(input);
  }

  completeUserOnboarding(token: string, id: number, password: string) {
    return this.onboardingClient.completeUserOnboarding(new CompleteUserOnboardingBody({
      token,
      id,
      password
    }));
  }

  resetUserPassword(token: string, id: number, password: string) {
    return this.onboardingClient.completePasswordReset(new PasswordResetBody({
      password,
      token,
      id
    }));
  }
}
