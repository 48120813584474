<p-dialog  [(visible)]="visible" appendTo="body" [modal]="true" [breakpoints]="{ '960px': '80vw', '640px': '100vw' }" [style]="{ 'max-height': '90vh', 'overflow': 'auto', width: '80vw' }" [closable]="false">
  <ng-template pTemplate="header">
    <div class="flex align-items-center">
      <span class="flex align-items-center justify-content-center text-grey-500 mr-3">
        <i class="pi pi-search text-3xl"></i>
      </span>
      <span class="font-medium text-2xl text-900">
        <span>{{ 'UI_CHOSE_A_RELATION' | translate }}</span>
      </span>
    </div>
  </ng-template>
  <doffice-smart-table [source]="relationSource" [columns]="relationColumns" [buttons]="relationButtons" [order]="relationOrder" [variables]="relationVariables" emptyMessage="{{ 'label.list.relations.empty' | transloco : { default: 'Er zijn geen relaties gevonden in het systeem.' } }}"></doffice-smart-table>
  <ng-template pTemplate="footer">
    <div class="border-top-1 surface-border pt-3">
      <button pButton pRipple (click)="cancelClicked()" class="p-button-sm mr-2 p-button-text p-button-secondary" label="{{ 'label.general.cancel' | transloco: { default: 'Annuleren' } }}"></button>
      <button pButton pRipple (click)="newClicked()" class="p-button-sm mr-2 p-button-success" icon="pi pi-plus" label="{{ 'UI_NEW' | translate }}" *ngIf="showNewButton"></button>
    </div>
  </ng-template>
</p-dialog>
