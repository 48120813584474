<p-dialog  [(visible)]="visible" appendTo="body" [modal]="true" [breakpoints]="{ '960px': '75vw', '640px': '100vw' }" [style]="{ 'max-height': '90vh', 'overflow': 'auto', width: '60vw' }" [closable]="false">
  <ng-template pTemplate="header">
    <div class="flex align-items-center">
      <span class="flex align-items-center justify-content-center bg-red-100 text-red-800 mr-3 border-circle" style="width: 32px; height: 32px">
        <i class="{{ icon }} text-lg"></i>
      </span>
      <span class="font-medium text-2xl text-900">
        <span>{{ title }}</span>
      </span>
    </div>
  </ng-template>
  <p class="line-height-3 p-0 m-0">
    <span>{{ body }}</span>
  </p>
  <ng-template pTemplate="footer">
    <div class="border-top-1 surface-border pt-3">
      <button pButton pRipple (click)="onCloseRequest()" class="p-button-sm p-button-secondary p-button-text" label="{{ 'label.general.close' | transloco: { default: 'Sluiten' } }}"></button>
    </div>
  </ng-template>
</p-dialog>
