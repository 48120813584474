import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {combineLatest, Observable, of} from 'rxjs';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';
import {Permission} from 'app/shared/data/permission';
import {GraphqlService} from "../services/graphql.service";
import {AuthenticationClient} from "../swagger/generated/swagger-client";

@Injectable()
export class ClientAuthenticationGuard {
  constructor(private router: Router, private authService: AuthService, private graphqlService: GraphqlService, private authenticationClient: AuthenticationClient) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    let authenticationUserObservable;
    let activeUser = this.authService.activeUserSnapshot;
    let activeSubscriber = this.authService.activeSubscriberSnapshot;

    if (!activeUser || !activeSubscriber) {
      let key: string = this.authService.clientToken;
      if (key == null) {
        /* No token is found in the localstorage */
        this.router.navigate(['authentication']);
        this.authService.logoffClient();
        sessionStorage.clear();
        sessionStorage.setItem('guard.requestedurl', state.url);
        return of(false);
      }

      authenticationUserObservable = this.authenticationClient.validateToken(key).pipe(
        tap((res) => {
          if (!res) {
            this.router.navigate(['authentication']);
            this.authService.logoffClient();
            sessionStorage.clear();
            sessionStorage.setItem('guard.requestedurl', state.url);

            throw new Error('Token is no longer valid');
          }
        }),
        this.authService.registerReceivedClientToken()
      );
    } else {
      authenticationUserObservable = of({user: activeUser, subscriber: activeSubscriber});
    }

    return authenticationUserObservable.pipe(
      map(() => {
        if (!this.checkPermissions(next.data.permissions)) {
          this.router.navigate(['']);
          return false;
        } else {
          return true;
        }
      }),
      catchError(() => of(false))
    );
  }

  private checkPermissions(permissions: Permission[]): boolean {
    if (!permissions || permissions.length <= 0) return true;
    return this.authService.checkPermissions(permissions);
  }
}
