import {TranslationService} from 'app/core/services/translation.service';
import {TranslationInjector} from 'app/app.module';
import _ from 'lodash';
import moment from 'moment';
import {Product} from '../article/product';
import {Service} from '../article/service';
import {Address} from '../relation/address';
import {Relation} from '../relation/relation.class';
import {Country} from '../general/country';
import {Company} from '../entity/company';
import {AccountNumber} from '../ledger/account-number';
import {ArticleTypeEnum} from '../article/articletype.enum';
import {MutationInputObject} from 'app/shared/models/system/mutation-object';
import {CombinedArticle} from '../article/combinedarticle';
import {Contact} from '../relation/contact';
import {User} from '../entity/user';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {Journal} from '../ledger/journal';
import {VatService} from "../../../core/services/vat.service";
import {ISubscription, ISubscriptionInput, ISubscriptionMutationInput, ISubscriptionSubline, ISubscriptionSublineInput, ISubscriptionSublineMutationInput} from "../../../core/graphql/generated/types";
import {GetDateScalar, GetDateTimeScalar, SetDateScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";
import {countries} from "../../data/country";

export class Subscription implements ISubscription {
  id: number = 0;
  subscriberId: number = 0;
  number: number = 0;
  Number: string = '';
  name: string = '';
  companyId: number = 0;
  companyName: string = '';
  companyVatNumber: string = '';
  companyAddressStreet: string = '';
  companyAddressNumber: string = '';
  companyAddressCity: string = '';
  companyAddressZip: string = '';
  companyAddressCounty: string = '';
  companyAddressCountryId: number = 0;
  companyAddressCountry: Country;
  company: Company;
  relationId: number = 0;
  relationName: string = '';
  relationVatNumber: string = '';
  relationAddressStreet: string = '';
  relationAddressNumber: string = '';
  relationAddressCity: string = '';
  relationAddressZip: string = '';
  relationAddressCounty: string = '';
  relationAddressCountryId: number = 0;
  relationAddressCountry: Country;
  relation: Relation;
  value: number = 0;
  vatValue: number = 0;
  valueIncl: number = 0;
  intnote: string = '';
  note: string = '';
  startdate: string = "";
  private _startdate: Date;

  get Startdate() {return this._startdate;}

  set Startdate(date: Date) {
    this._startdate = date;
    this.startdate = SetDateTimeScalar(date);
  }

  get company_address(): Address {
    return new Address({
      id: null,
      street: this.companyAddressStreet,
      number: this.companyAddressNumber,
      city: this.companyAddressCity,
      zip: this.companyAddressZip,
      county: this.companyAddressCounty,
      country_id: this.companyAddressCountryId,
      country: this.companyAddressCountry,
      default: null,
      subscriber_id: this.subscriberId,
    });
  }

  enddate: string = "";
  private _enddate: Date;

  get Enddate() {return this._enddate;}

  set Enddate(date: Date) {
    this._enddate = date;
    this.enddate = SetDateTimeScalar(date);
  }

  nextdate: string = "";
  private _nextdate: Date;

  get Nextdate() {return this._nextdate;}

  set Nextdate(date: Date) {
    this._nextdate = date;
    this.nextdate = SetDateTimeScalar(date);
  }

  status: SubscriptionStatusEnum = SubscriptionStatusEnum.CONCEPT;

  get customer_address(): Address {
    return new Address({
      id: null,
      street: this.relationAddressStreet,
      number: this.relationAddressNumber,
      city: this.relationAddressCity,
      zip: this.relationAddressZip,
      county: this.relationAddressCounty,
      country_id: this.relationAddressCountryId,
      country: this.relationAddressCountry,
      default: null,
      subscriber_id: this.subscriberId,
    });
  }

  get Status(): string {
    return TranslationInjector.get(TranslationService)
      .getValueByKey(SubscriptionStatusEnumLanguage[SubscriptionStatusEnum[this.status]]);
  }

  frequency: SubscriptionFrequencyEnum = SubscriptionFrequencyEnum.MONTHLY;

  get Frequency(): string {
    return TranslationInjector.get(TranslationService)
      .getValueByKey(SubscriptionFrequencyEnumLanguage[SubscriptionFrequencyEnum[this.frequency]]);
  }

  frequencyValue: number = 0;
  created: string = "";
  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  journalId: number = 0;
  journal: Journal;
  userId: number = 0;
  userName: string = '';
  userPhone: string = '';
  userMail: string = '';
  contactId: number = 0;
  contactName: string = '';
  contactPhone: string = '';
  contactMail: string = '';
  user: User;
  contact: Contact;
  relationReference: string = '';
  guestEnabled: boolean = false;
  autoAcceptAndSend: boolean = false;
  vatCode: string = "";
  accountNumberId: number = 0;
  accountNumber: AccountNumber;

  sublines: SubscriptionSubline[];

  get Valid(): boolean {
    return (
      (this.name ?? '').trim().length > 0 &&
      this.companyId != null &&
      this.companyId > 0 &&
      this.companyName != null &&
      this.companyName.trim().length > 0 &&
      this.companyVatNumber != null &&
      this.companyVatNumber.trim().length > 0 &&
      this.relationId != null &&
      this.relationId > 0 &&
      this.relationName != null &&
      this.relationName.trim().length > 0 &&
      this.value !== null &&
      this.vatValue !== null &&
      this.valueIncl !== null &&
      this.startdate !== null &&
      this.startdate !== undefined &&
      this.frequency !== null &&
      this.frequency !== undefined &&
      this.frequencyValue > 0
    );
  }

  constructor(subscription: ISubscription = null) {
    if (subscription === null) {
      return;
    }

    constructFromInterface(this, subscription);
    if (subscription.startdate) {
      this._startdate = GetDateScalar(subscription.startdate);
    }
    if (subscription.enddate) {
      this._enddate = GetDateScalar(subscription.enddate);
    }
    if (subscription.nextdate) {
      this._nextdate = GetDateScalar(subscription.nextdate);
    }
    if (subscription.created) {
      this._created = GetDateTimeScalar(subscription.created);
    }
    if (subscription.updated) {
      this._updated = GetDateTimeScalar(subscription.updated);
    }
    if (subscription.companyAddressCountryId) {
      this.companyAddressCountry = new Country(countries.find(x => x.id == subscription.companyAddressCountryId));
    }
    if (subscription.company) {
      this.company = new Company(subscription.company);
    }
    if (subscription.relationAddressCountryId) {
      this.relationAddressCountry = new Country(countries.find(x => x.id == subscription.relationAddressCountryId));
    }
    if (subscription.relation) {
      this.relation = new Relation(subscription.relation);
    }
    if (subscription.user) {
      this.user = new User(subscription.user);
    }
    if (subscription.contact) {
      this.contact = new Contact(subscription.contact);
    }
    if (subscription.accountNumber) {
      this.accountNumber = new AccountNumber(subscription.accountNumber);
    }
    if (subscription.journal) {
      this.journal = new Journal(subscription.journal);
    }
    if (subscription.sublines) {
      this.sublines = subscription.sublines.map((x) => new SubscriptionSubline(x));
    }
  }

  public static new(): Subscription {
    return new Subscription({
      id: null,
      subscriberId: null,
      number: null,
      Number: null,
      name: null,
      companyId: null,
      companyName: null,
      companyVatNumber: null,
      companyAddressStreet: null,
      companyAddressNumber: null,
      companyAddressCity: null,
      companyAddressZip: null,
      companyAddressCounty: null,
      companyAddressCountryId: null,
      relationId: null,
      relationName: null,
      relationVatNumber: null,
      relationAddressStreet: null,
      relationAddressNumber: null,
      relationAddressCity: null,
      relationAddressZip: null,
      relationAddressCounty: null,
      relationAddressCountryId: null,
      value: 0,
      vatValue: 0,
      valueIncl: 0,
      intnote: null,
      note: null,
      startdate: SetDateTimeScalar(new Date()),
      enddate: null,
      nextdate: SetDateScalar(moment()
        .add(1, 'month')
        .toDate()),
      status: SubscriptionStatusEnum.CONCEPT,
      frequency: SubscriptionFrequencyEnum.MONTHLY,
      frequencyValue: 1,
      created: null,
      updated: null,
      journalId: null,
      journal: null,
      sublines: [],
      company: null,
      companyAddressCountry: null,
      relation: null,
      relationAddressCountry: null,
      userId: null,
      contactId: null,
      userName: null,
      userPhone: null,
      userMail: null,
      contactName: null,
      contactPhone: null,
      contactMail: null,
      user: null,
      contact: null,
      relationReference: null,
      guestEnabled: true,
      autoAcceptAndSend: false,
      vatCode: null,
      accountNumberId: null,
      accountNumber: null,
    });
  }

  public convertToInput(): ISubscriptionInput {
    return {
      id: this.id ? +this.id : null,
      name: this.name,
      companyId: this.companyId ? +this.companyId : null,
      companyName: this.companyName,
      companyVatNumber: this.companyVatNumber,
      companyAddressStreet: this.companyAddressStreet,
      companyAddressNumber: this.companyAddressNumber,
      companyAddressCity: this.companyAddressCity,
      companyAddressZip: this.companyAddressZip,
      companyAddressCounty: this.companyAddressCounty,
      companyAddressCountryId: this.companyAddressCountryId ? +this.companyAddressCountryId : null,
      relationId: this.relationId ? +this.relationId : null,
      relationName: this.relationName,
      relationVatNumber: this.relationVatNumber,
      relationAddressStreet: this.relationAddressStreet,
      relationAddressNumber: this.relationAddressNumber,
      relationAddressCity: this.relationAddressCity,
      relationAddressZip: this.relationAddressZip,
      relationAddressCounty: this.relationAddressCounty,
      relationAddressCountryId: this.relationAddressCountryId ? +this.relationAddressCountryId : null,
      value: this.value !== null || this.value !== undefined ? +this.value : null,
      vatValue: this.vatValue !== null || this.value !== undefined ? +this.vatValue : null,
      valueIncl: this.valueIncl !== null || this.value !== undefined ? +this.valueIncl : null,
      intnote: this.intnote,
      note: this.note,
      startdate: SetDateScalar(this.startdate ? moment(this.startdate)
        .utc(true)
        .toDate() : null),
      enddate: SetDateScalar(this.enddate ? moment(this.enddate)
        .utc(true)
        .toDate() : null),
      nextdate: SetDateScalar(this.nextdate ? moment(this.nextdate)
        .utc(true)
        .toDate() : null),
      status: this.status ? +this.status : null,
      frequency: this.frequency ? +this.frequency : null,
      frequencyValue: this.frequencyValue ? +this.frequencyValue : null,
      journalId: this.journalId ? +this.journalId : null,
      userId: this.userId ? +this.userId : null,
      contactId: this.contactId ? +this.contactId : null,
      userName: this.userName,
      userPhone: this.userPhone,
      userMail: this.userMail,
      contactName: this.contactName,
      contactPhone: this.contactPhone,
      contactMail: this.contactMail,
      relationReference: (this.relationReference ?? '').trim().length > 0 ? '' + this.relationReference : null,
      guestEnabled: !!this.guestEnabled,
      autoAcceptAndSend: !!this.autoAcceptAndSend,
      vatCode: this.vatCode ? '' + this.vatCode : null,
      accountNumberId: this.accountNumberId ? +this.accountNumberId : null,
    };
  }

  public createMutationInput(sublines: MutationInputObject<SubscriptionSubline>, originalSubscription: Subscription = null): ISubscriptionMutationInput {
    let mutationInput: ISubscriptionMutationInput = {id: this.id, subscription: null, sublines: null};
    mutationInput.subscription = _.isEqual(this.convertToInput(), originalSubscription?.convertToInput()) ? null : this.convertToInput(); /* Check if reception is updated */

    /* Set sublines */
    mutationInput.sublines = {
      deletes: sublines.deletes.map((x) => Number(x)),
      updates: sublines.updates.map((x) => {
        return {
          id: x.id,
          subline: x.convertToInput(),
        } as ISubscriptionSublineMutationInput;
      }),
    };

    return mutationInput;
  }

  public calculateValue(): void {
    this.value = _.sumBy(this.sublines, (x) => +x.price);
    this.valueIncl = _.sumBy(this.sublines, (x) => +x.priceIncl);
    this.vatValue = _.sumBy(this.sublines, (x) => +x.vatprice);
  }
}

export class SubscriptionSubline implements ISubscriptionSubline {
  id: number = 0;
  subscriberId: number = 0;
  subscriptionId: number = 0;
  articleTypeId: ArticleTypeEnum = ArticleTypeEnum.PRODUCT;

  get ArtType(): string {
    switch (this.articleTypeId) {
      case ArticleTypeEnum.PRODUCT:
        return TranslationInjector.get(TranslationService)
          .getValueByKey('UI_PRODUCT');
      case ArticleTypeEnum.SERVICE:
        return TranslationInjector.get(TranslationService)
          .getValueByKey('UI_SERVICE');
      case ArticleTypeEnum.COMBINEDARTICLE:
        return TranslationInjector.get(TranslationService)
          .getValueByKey('UI_COMBINED_ARTICLE');
    }
  }

  get ArtTypeButton(): string {
    switch (this.articleTypeId) {
      case ArticleTypeEnum.PRODUCT:
        return TranslationInjector.get(TranslationService)
          .getValueByKey('UI_PRODUCT');
      case ArticleTypeEnum.SERVICE:
        return TranslationInjector.get(TranslationService)
          .getValueByKey('UI_SERVICE');
      case ArticleTypeEnum.COMBINEDARTICLE:
        return TranslationInjector.get(TranslationService)
          .getValueByKey('UI_COMBINED');
    }
  }

  get ArtTypeSku(): string {
    switch (this.articleTypeId) {
      case ArticleTypeEnum.PRODUCT:
        return TranslationInjector.get(TranslationService)
          .getValueByKey('UI_PRODUCT_SKU');
      case ArticleTypeEnum.SERVICE:
        return TranslationInjector.get(TranslationService)
          .getValueByKey('UI_SERVICE_SKU');
      case ArticleTypeEnum.COMBINEDARTICLE:
        return TranslationInjector.get(TranslationService)
          .getValueByKey('UI_COMBINED_ARTICLE_SKU');
    }
  }

  get ArtTypeName(): string {
    switch (this.articleTypeId) {
      case ArticleTypeEnum.PRODUCT:
        return TranslationInjector.get(TranslationService)
          .getValueByKey('UI_PRODUCT_NAME');
      case ArticleTypeEnum.SERVICE:
        return TranslationInjector.get(TranslationService)
          .getValueByKey('UI_SERVICE_NAME');
      case ArticleTypeEnum.COMBINEDARTICLE:
        return TranslationInjector.get(TranslationService)
          .getValueByKey('UI_COMBINED_ARTICLE_NAME');
    }
  }

  articleId: number = 0;
  articleSku: string = '';
  articleName: string = '';
  article: Product | Service | CombinedArticle;
  vatCode: string = "";
  amount: number = 0;
  unitPrice: number = 0;
  basePrice: number = 0;
  discount: number = 0;
  price: number = 0;
  priceIncl: number = 0;
  vatprice: number = 0;
  note: string = '';
  created: string = "";
  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  order: number = 0;
  accountNumberId: number = 0;
  accountNumber: AccountNumber;
  hideOnPdf: boolean = false;
  unit: string = '';

  get Valid(): boolean {
    return (
      this.articleTypeId != null &&
      (this.articleName ?? '').trim().length > 0 &&
      this.amount !== null &&
      this.unitPrice !== null &&
      this.basePrice !== null &&
      this.discount !== null &&
      this.price !== null &&
      !_.isNil(this.vatCode)
    );
  }

  constructor(subline: ISubscriptionSubline = null) {
    if (subline === null) {
      return;
    }

    constructFromInterface(this, subline);

    if (subline.created) {
      this._created = GetDateTimeScalar(subline.created);
    }
    if (subline.updated) {
      this._updated = GetDateTimeScalar(subline.updated);
    }
    if (subline?.article) {
      switch (subline?.articleTypeId) {
        case ArticleTypeEnum.PRODUCT:
          this.article = new Product(subline.article as Product);
          break;
        case ArticleTypeEnum.SERVICE:
          this.article = new Service(subline.article as Service);
          break;
        case ArticleTypeEnum.COMBINEDARTICLE:
          this.article = new CombinedArticle(subline.article as CombinedArticle);
          break;
      }
    }
    if (subline?.accountNumber) {
      this.accountNumber = new AccountNumber(subline.accountNumber);
    }
  }

  public convertToInput(): ISubscriptionSublineInput {
    return {
      id: +this.id,
      articleTypeId: +this.articleTypeId,
      articleId: +this.articleId,
      articleSku: this.articleSku,
      articleName: this.articleName,
      vatCode: this.vatCode,
      amount: +this.amount,
      unitPrice: +this.unitPrice,
      basePrice: +this.basePrice,
      discount: +this.discount,
      price: +this.price,
      priceIncl: +this.priceIncl,
      vatprice: +this.vatprice,
      note: this.note,
      order: +this.order,
      accountNumberId: +this.accountNumberId,
      hideOnPdf: this.hideOnPdf !== null ? !!this.hideOnPdf : null,
      unit: this.unit,
    };
  }

  public static new(): SubscriptionSubline {
    return new SubscriptionSubline({
      id: null,
      subscriberId: null,
      subscriptionId: null,
      articleTypeId: ArticleTypeEnum.PRODUCT,
      articleId: null,
      articleSku: null,
      articleName: null,
      article: null,
      vatCode: null,
      amount: 1,
      unitPrice: 0,
      basePrice: 0,
      discount: 0,
      price: 0,
      priceIncl: 0,
      vatprice: 0,
      note: null,
      created: null,
      updated: null,
      order: null,
      accountNumber: null,
      accountNumberId: null,
      hideOnPdf: false,
      unit: null,
    });
  }

  calculateSublineValuesFromAmount(): void {
    this.price = +(this.price ?? 0);
    this.priceIncl = +(this.priceIncl ?? 0);
    this.vatprice = +(this.vatprice ?? 0);
    this.unitPrice = +(this.unitPrice ?? 0);
    this.amount = +(this.amount ?? 1);

    const vat = VatService.Get(this.vatCode);
    this.unitPrice = this.unitPrice ?? 0;
    this.basePrice = +(+this.unitPrice * +(this.amount ?? 0)).toFixed(2);
    this.price = +(+this.basePrice - +(this.discount ?? 0)).toFixed(2);
    this.vatprice = +(vat.percentage ? +this.price * (+vat.percentage / 100) : 0).toFixed(2);
    this.priceIncl = +(+this.price + +(this.vatprice ?? 0)).toFixed(2);
  }

  calculateSublineValuesFomUnitPrice(): void {
    const vat = VatService.Get(this.vatCode);
    this.basePrice = +(+this.unitPrice * +(this.amount ?? 0)).toFixed(2);
    this.price = +(+this.basePrice - +(this.discount ?? 0)).toFixed(2);
    this.vatprice = +(vat.percentage ? +this.price * (+vat.percentage / 100) : 0).toFixed(2);
    this.priceIncl = +(+this.price + +(this.vatprice ?? 0)).toFixed(2);
  }

  calculateSublineValuesFromBaseSalesPrice(): void {
    this.price = +(this.price ?? 0);
    this.priceIncl = +(this.priceIncl ?? 0);
    this.vatprice = +(this.vatprice ?? 0);
    this.unitPrice = +(this.unitPrice ?? 0);
    this.amount = +(this.amount ?? 1);

    const vat = VatService.Get(this.vatCode);
    this.unitPrice = +(+(this.amount ?? 0) == 0 ? 0 : +this.basePrice / +this.amount).toFixed(2);
    this.price = +(+this.basePrice - +(this.discount ?? 0)).toFixed(2);
    this.vatprice = +(vat.percentage ? +this.price * (+vat.percentage / 100) : 0).toFixed(2);
    this.priceIncl = +(+this.price + +(this.vatprice ?? 0)).toFixed(2);
  }

  calculateSublineValuesFromDiscount(): void {
    this.price = +(this.price ?? 0);
    this.priceIncl = +(this.priceIncl ?? 0);
    this.vatprice = +(this.vatprice ?? 0);
    this.unitPrice = +(this.unitPrice ?? 0);
    this.amount = +(this.amount ?? 1);

    const vat = VatService.Get(this.vatCode);
    this.price = +(+this.basePrice - +(this.discount ?? 0)).toFixed(2);
    this.vatprice = +(vat.percentage ? +this.price * (+vat.percentage / 100) : 0).toFixed(2);
    this.priceIncl = +(+this.price + +(this.vatprice ?? 0)).toFixed(2);
  }

  calculateSublineValuesFromSalesPrice(): void {
    this.price = +(this.price ?? 0);
    this.priceIncl = +(this.priceIncl ?? 0);
    this.vatprice = +(this.vatprice ?? 0);
    this.unitPrice = +(this.unitPrice ?? 0);
    this.amount = +(this.amount ?? 1);

    const vat = VatService.Get(this.vatCode);
    this.basePrice = +(+this.price + +(this.discount ?? 0)).toFixed(2);
    this.unitPrice = +(+(this.amount ?? 0) == 0 ? 0 : +this.basePrice / +this.amount).toFixed(2);
    this.vatprice = +(vat.percentage ? +this.price * (+vat.percentage / 100) : 0).toFixed(2);
    this.priceIncl = +(+this.price + +(this.vatprice ?? 0)).toFixed(2);
  }

  calculateSublineValuesFromSalesPriceIncl(): void {
    this.price = +(this.price ?? 0);
    this.vatprice = +(this.vatprice ?? 0);
    this.basePrice = +(this.basePrice ?? 0);
    this.unitPrice = +(this.unitPrice ?? 0);
    this.priceIncl = +(this.priceIncl ?? 0);
    this.discount = +(this.discount ?? 0);
    this.amount = +(this.amount ?? 1);

    const vat = VatService.Get(this.vatCode);
    this.price = +(vat.percentage ? +this.priceIncl / (+vat.percentage / 100 + 1) : +this.priceIncl).toFixed(2);
    this.vatprice = +(vat.percentage ? +this.price * (+vat.percentage / 100) : 0).toFixed(2);
    this.basePrice = +(+this.price + +(this.discount ?? 0)).toFixed(2);
    this.unitPrice = +(+(this.amount ?? 0) == 0 ? 0 : +this.basePrice / +this.amount).toFixed(2);
  }
}

export enum SubscriptionFrequencyEnum {
  DAILY = 1,
  WEEKLY = 2,
  MONTHLY = 3,
  YEARLY = 4,
}

export enum SubscriptionFrequencyEnumLanguage {
  DAILY = 'UI_DAYS',
  WEEKLY = 'UI_WEEKS',
  MONTHLY = 'UI_MONTHS',
  YEARLY = 'UI_YEARS',
}

export enum SubscriptionStatusEnum {
  CONCEPT = 1,
  ACTIVE = 2,
  ONHOLD = 3,
  CANCELED = 4,
  EXPIRED = 5,
  BLOCKED = 6,
}

export enum SubscriptionStatusEnumLanguage {
  CONCEPT = 'UI_CONCEPT',
  ACTIVE = 'UI_ACTIVE',
  ONHOLD = 'UI_ONHOLD',
  CANCELED = 'UI_CANCELED',
  EXPIRED = 'UI_EXPIRED',
  BLOCKED = 'UI_BLOCKED',
}
