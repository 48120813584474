import {Injectable} from '@angular/core';
import {EntityPhone} from 'app/shared/models/relation/entity-phone';
import {MutationInputObject} from 'app/shared/models/system/mutation-object';
import _ from 'lodash';
import {IEntityPhoneMutationInput} from "../graphql/generated/types";

@Injectable({
  providedIn: 'root',
})
export class RelationPhoneService {
  createPhoneMutations(phones: EntityPhone[], originalPhones: EntityPhone[] = null): MutationInputObject<IEntityPhoneMutationInput> {
    if (_.isNil(originalPhones)) {
      phones.forEach((phone) => {
        phone.id == null;
      });
    }

    const input: MutationInputObject<IEntityPhoneMutationInput> = {deletes: [], updates: []};

    const newPhone = phones.filter((phone) => _.isNil(phone.id))
      .map((phone) => phone.convertToInput());
    const updatedPhone = phones
      .filter((phone) => !_.isNil(phone.id))
      .map((phone) => phone.convertToInput())
      .filter((phone) => !_.isEqual(phone, originalPhones?.find((s) => s.id == phone.id)
        ?.convertToInput()));
    input.updates = _.concat(newPhone, updatedPhone)
      .map((phone) => {
        return {
          id: phone.id,
          phone: phone,
        };
      });

    input.deletes = _.differenceBy(
      originalPhones,
      phones.filter((phone) => !_.isNil(phone.id)),
      (phone) => phone.id
    )
      .map((phone) => +phone.id);

    return input;
  }
}
