<p-dialog  [(visible)]="visible" appendTo="body" [modal]="true" [breakpoints]="{ '960px': '75vw', '640px': '100vw' }" [style]="{ 'max-height': '90vh', 'overflow': 'auto', width: '60vw' }" [closable]="false">
  <div class="grid formgrid p-fluid">
    <div class="field mb-4 col-12 lg:col-8">
      <label for="name" class="font-medium text-900">{{ 'UI_NAME' | translate }}</label>
      <input id="name" type="text" pInputText [(ngModel)]="service.name" />
      <small class="text-orange-600" *ngIf="service && (service.name ?? '').trim().length <= 0">{{ 'label.base.fieldRequired' | transloco : { default: 'Dit veld is verplicht!' } }}</small>
    </div>

    <div class="field mb-4 col-12 lg:col-4">
      <label for="sku" class="font-medium text-900">{{ 'UI_SKU' | translate }}</label>
      <input id="sku" type="text" pInputText [(ngModel)]="service.sku" />
    </div>

    <doffice-field-buttons class="mb-2 col-12" [buttons]="articleCategoryButtons">
      <label for="accountNumberClasses" class="font-medium text-900">{{ 'label.article.category' | transloco: { default: 'Categorie' } }}</label>
      <div class="p-inputgroup" *ngIf="!categoriesLoading && service">
        <p-dropdown
          inputId="accountNumberClasses"
          [options]="categories"
          optionValue="id"
          optionLabel="name"
          [filter]="true"
          filterBy="name"
          [showClear]="false"
          [(ngModel)]="service.artcat_id"
          (onChange)="setCategory($event.value)"
          placeholder="{{ 'label.general.selectPlaceholder' | transloco: { default: 'Selecteer een {{name}}', name: ('label.product.category' | transloco: { default: 'Categorie' } | lowercase) } }}"
          emptyFilterMessage="{{ 'label.list.articleCategories.emptyFilter' | transloco: { default: 'Er zijn geen artikel categorieën in het systeem die aan de huidige filters voldoen.' } }}"
          emptyMessage="{{ 'label.list.articleCategories.empty' | transloco: { default: 'Er zijn geen artikel categorieën gevonden in het systeem.' } }}"
        >
          <ng-template let-category pTemplate="item">
            <div class="flex align-items-center">
              <div>{{ category.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <p-skeleton height="2.7rem"  *ngIf="!(!categoriesLoading && service)"></p-skeleton>
    </doffice-field-buttons>

    <div class="field mb-4 col-12 lg:col-6">
      <label for="salesPrice" class="font-medium text-900">{{ 'UI_DEFAULT_SALESPRICE' | translate }}</label>
      <input id="salesPrice" type="number" pInputText [(ngModel)]="service.salesprice" />
    </div>

    <div class="field mb-4 col-12 lg:col-6">
      <label for="unit" class="font-medium text-900">{{ 'UI_DEFAULT_UNIT' | translate }}</label>
      <input id="unit" type="text" pInputText [(ngModel)]="service.defaultUnit" />
    </div>

    <div class="field mb-4 col-12">
      <label for="description" class="font-medium text-900">{{ 'UI_DESCRIPTION' | translate }}</label>
      <doffice-editor id="description" [(value)]="service.description"></doffice-editor>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="border-top-1 surface-border pt-3">
      <button pButton pRipple (click)="cancelClicked()" class="p-button-sm mr-2 p-button-text p-button-danger" label="{{ 'label.general.cancel' | transloco: { default: 'Annuleren' } }}"></button>
      <button pButton pRipple icon="pi pi-check" [disabled]="!service.Validation" [loading]="saveLoading" (click)="saveClicked()" class="p-button-sm p-button-success" label="{{ 'label.general.saveAndUse' | transloco: { default: 'Opslaan en gebruiken' } }}"></button>
    </div>
  </ng-template>
</p-dialog>
