import {User} from '../entity/user';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {TranslationInjector} from 'app/app.module';
import {TranslationService} from 'app/core/services/translation.service';
import {IPeppol} from "../../../core/graphql/generated/types";
import {GetDateTimeScalar, SetDateTimeScalar} from 'app/core/logic/date-scalars';


export enum PeppolStatusEnum {
  CREATED = 1,
  SENT = 2,
  FAILED = 3,
}

export enum PeppolObjectEnum {
  INVOICE = 1,
  CREDITNOTE = 2,
}

export class Peppol implements IPeppol {
  id: number = 0;
  subscriberId: number = 0;
  userId: number = 0;
  user: User;
  object: PeppolObjectEnum = PeppolObjectEnum.INVOICE;
  objectId: number = 0;
  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  error: string = '';
  status: PeppolStatusEnum = PeppolStatusEnum.CREATED;
  externalId: string = '';

  get Error() {
    try {
      const obj = JSON.parse(this.error);
      return obj;
    } catch {
      return this.error;
    }
  }

  constructor(peppol: IPeppol) {
    if (peppol === null) {
      return;
    }

    constructFromInterface(this, peppol);

    if (peppol.created) {this._created = GetDateTimeScalar(peppol.created);}
    if (peppol.user) {
      this.user = new User(peppol.user);
    }
  }

  get Status() {
    switch (this.status) {
      case PeppolStatusEnum.CREATED:
        return TranslationInjector.get(TranslationService)
          .getValueByKey('UI_CREATED');
      case PeppolStatusEnum.FAILED:
        return TranslationInjector.get(TranslationService)
          .getValueByKey('UI_FAILED');
      case PeppolStatusEnum.SENT:
        return TranslationInjector.get(TranslationService)
          .getValueByKey('UI_SENT');
    }
  }
}
