<p-dialog  [(visible)]="visible" appendTo="body" [modal]="true" [breakpoints]="{ '960px': '75vw', '640px': '100vw' }" [style]="{ 'max-height': '90vh', 'overflow': 'auto', width: '60vw' }" [closable]="false">
  <div class="grid formgrid p-fluid">
    <div class="field mb-4 col-12">
      <label for="name" class="font-medium text-900">{{ 'label.articleCategory.name' | transloco: { default: 'Naam' } }}</label>
      <input id="name" [readonly]="saveLoading" type="text" pInputText [(ngModel)]="category.name" />
      <small class="text-orange-600" *ngIf="category && (category.name ?? '').trim().length <= 0">{{ 'label.general.fieldRequired' | transloco: { default: 'Dit veld is verplicht!' } }}</small>
    </div>

    <div class="field mb-4 col-12">
      <label for="description" class="font-medium text-900">{{ 'label.articleCategory.description' | transloco: { default: 'Omschrijving' } }}</label>
      <doffice-editor id="description" [readOnly]="saveLoading" [(value)]="category.description"></doffice-editor>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="border-top-1 surface-border pt-3">
      <button pButton pRipple (click)="cancelClicked()" class="p-button-sm mr-2 p-button-text p-button-danger" label="{{ 'label.general.cancel' | transloco: { default: 'Annuleren' } }}"></button>
      <button pButton pRipple icon="pi pi-check" [disabled]="!category.Validation" [loading]="saveLoading" (click)="saveClicked()" class="p-button-sm p-button-success" label="{{ 'label.general.saveAndUse' | transloco: { default: 'Opslaan en gebruiken' } }}"></button>
    </div>
  </ng-template>
</p-dialog>
