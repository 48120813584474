import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import _ from 'lodash';

import { RelationService } from 'app/core/services/relation.service';
import { Contact } from 'app/shared/models/relation/contact';
import {GraphqlService} from "../../../../core/services/graphql.service";

@Component({
  selector: 'doffice-creation-relation-contact-modal',
  templateUrl: 'creation-relation-contact-modal.component.html',
  styleUrls: ['creation-relation-contact-modal.component.scss'],
})
export class CreationRelationContactModalComponent implements OnDestroy, OnInit {
  private _visible = new BehaviorSubject<boolean>(true);
  get visible$() {
    return this._visible.asObservable();
  }
  get visible() {
    return this._visible.getValue();
  }
  set visible(visible: boolean) {
    this._visible.next(visible);
  }

  relationId: number;
  contact = Contact.new();
  saveLoading = false;

  created$ = new Subject<number>();
  private _destroy$ = new Subject<void>();

  constructor( private cdr: ChangeDetectorRef, private relationService: RelationService, private graphqlService: GraphqlService) {
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 10);
  }

  ngOnInit(): void {
    this.contact = Contact.new();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  cancelClicked() {
    this._visible.next(false);
  }

  saveClicked() {
    this.saveLoading = true;
    this.cdr.detectChanges();

    const input = this.relationService.createrRelationContactMutationInput(this.contact);
    this.graphqlService.mutations.relation.contact.update({input, relationId: +this.relationId})
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        this._visible.next(false);
        this.created$.next(res.id);
        this.cdr.detectChanges();
      });
  }
}
