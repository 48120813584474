import {Injectable} from '@angular/core';
import {ArticlePrice} from 'app/shared/models/article/article-price';
import {MutationInputObject} from 'app/shared/models/system/mutation-object';
import _ from 'lodash';
import {IArticlePriceMutationInput} from "../graphql/generated/types";

@Injectable({
  providedIn: 'root',
})
export class ArticlePriceService {
  createArticlePriceMutations(prices: ArticlePrice[], originalPrices: ArticlePrice[] = null): MutationInputObject<IArticlePriceMutationInput> {
    if (_.isNil(originalPrices)) {
      prices.forEach((price) => {
        price.id == null;
      });
    }

    const input: MutationInputObject<IArticlePriceMutationInput> = {deletes: [], updates: []};

    const newPrice = prices.filter((price) => _.isNil(price.id))
      .map((price) => price.convertToInput());
    const updatedSublines = prices
      .filter((price) => !_.isNil(price.id))
      .map((price) => price.convertToInput())
      .filter((price) => !_.isEqual(price, originalPrices?.find((s) => s.id == price.id)
        ?.convertToInput()));
    input.updates = _.concat(newPrice, updatedSublines)
      .map((price) => {
        return {
          id: price.id,
          price: price,
        };
      });

    input.deletes = _.differenceBy(
      originalPrices,
      prices.filter((price) => !_.isNil(price.id)),
      (price) => price.id
    )
      .map((price) => +price.id);

    return input;
  }
}
