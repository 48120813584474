<p-dialog  [(visible)]="visible" appendTo="body" [modal]="true" [breakpoints]="{ '960px': '85vw', '640px': '100vw' }" [style]="{ 'max-height': '90vh', 'overflow': 'auto', width: '50vw' }" [closable]="false">
  <div class="grid formgrid p-fluid">
    <div class="field mb-4 col-12 flex-1 gap-3 flex flex-column sm:flex-row">
      <div class="flex-1 flex justify-content-center align-items-center license-modal-border" *ngFor="let license of licenses">
        <div class="text-center">
          <div class="text-5xl font-bold mb-4">{{ license.Name }}</div>
          <div class="license-modal-document-limit-text" innerHTML="{{ 'UI_LICENSE_DOCUMENT_LIMIT' | translate : [license.documentLimit] }}" *ngIf="license.documentLimit"></div>
          <div class="license-modal-document-limit-text" innerHTML="{{ 'UI_LICENSE_NO_DOCUMENT_LIMIT' | translate }}" *ngIf="!license.documentLimit"></div>
          <div class="license-modal-user-limit-text" innerHTML="{{ 'UI_LICENSE_USER_LIMIT' | translate : [license.userLimit] }}" *ngIf="license.userLimit"></div>
          <div class="license-modal-user-limit-text" innerHTML="{{ 'UI_LICENSE_NO_USER_LIMIT' | translate }}" *ngIf="!license.userLimit"></div>

          <div class="mt-5 text-sm">
            <span class="font-bold license-modal-price text-2xl">{{ getLicensePrice(license) }}</span
            ><span class="text-sm text-600 font-italic">/{{ 'UI_MONTH' | translate | lowercase }}</span>
          </div>
          <div class="text-sm text-600 font-italic mt-1">{{ 'UI_EXCL_VAT' | translate }}</div>

          <div class="mt-5 font-bold">{{ license.userFree }} {{ (license.userFree == 1 ? 'UI_USER' : 'UI_USERS') | translate | lowercase }}</div>
          <div class="">{{ 'UI_LICENSE_EXTRA_USER_PRICE' | translate }}: {{ license.userCost | money }}</div>

          <button pButton class="mt-3 doffice-button-gold" label="{{ (selectedLicense == license.id ? 'UI_SELECTED' : 'UI_SELECT') | translate }}" [disabled]="selectedLicense == license.id" (click)="setLicense(license)"></button>
        </div>
      </div>
    </div>

    <div class="field mb-4 col-12" *ngIf="isNew">
      <label for="periodPicker" class="font-medium text-900">{{ 'UI_PERIOD' | translate }}</label>
      <div class="p-inputgroup">
        <p-dropdown
          inputId="periodPicker"
          [options]="periods"
          optionValue="period"
          optionLabel="label"
          [filter]="false"
          filterBy="label"
          [showClear]="false"
          [(ngModel)]="period"
          (onChange)="setPeriod()"
          placeholder="{{ 'label.selectPlaceholder.period' | transloco : { default: 'Selecteer een periode' } }}"
          appendTo="body"
        >
          <ng-template let-period pTemplate="item">
            <div class="flex align-items-center">
              <div>{{ period.label }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>

    <div class="field mb-4 col-12">
      <label for="users" class="font-medium text-900">{{ 'UI_USERS' | translate }}</label>
      <input id="users" pInputText type="number" [(ngModel)]="userAmount" (ngModelChange)="userAmountChanged()" [min]="minUserAmount" />
      <small>{{ 'UI_RESELLER_SUBSCRIBER_LICENSE_MIN_USERS' | translate }}: {{ minUserAmount }}</small>
    </div>

    <div class="field mb-4 col-12" *ngIf="!isReseller && creditUsed > 0">
      <label for="licensepriceexclvat" class="font-medium text-900">{{ 'UI_USED_CREDIT' | translate }}</label>
      <input id="licensepriceexclvat" pInputText type="text" readonly ngModel="{{ creditUsed | money }}" />
    </div>

    <div class="field mb-4 col-12" *ngIf="!isReseller">
      <label for="licensepriceexclvat" class="font-medium text-900">{{ 'UI_PRICE_EXCL_VAT' | translate }}</label>
      <input id="licensepriceexclvat" pInputText type="text" readonly ngModel="{{ advisedPrice | money }}" />
    </div>
  </div>

  <div class="flex-1 gap-3 flex flex-column sm:flex-row mt-5" *ngIf="isReseller">
    <div class="flex-1 flex justify-content-center align-items-center bg-white">
      <div class="text-center">
        <i class="pi pi-money-bill text-4xl mb-2"></i>
        <div class="text-sm font-semibold mb-2">{{ 'UI_ADVISED_RESELLER_PRICE' | translate }}</div>
        <span class="font-semibold">{{ advisedPrice | money }}</span>
      </div>
    </div>
    <div class="flex-1 flex justify-content-center align-items-center bg-white">
      <div class="text-center">
        <i class="pi pi-download text-4xl mb-2"></i>
        <div class="text-sm font-semibold mb-2">{{ 'UI_REAL_RESELLER_PRICE' | translate }}</div>
        <span class="font-semibold">{{ resellerPrice | money }}</span>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="border-top-1 surface-border pt-3">
      <button pButton pRipple (click)="cancelClicked()" class="p-button-sm mr-2 p-button-text p-button-danger" label="{{ 'label.general.cancel' | transloco: { default: 'Annuleren' } }}"></button>
      <button pButton pRipple icon="pi pi-check" [disabled]="!valid" (click)="activateClicked()" class="p-button-sm p-button-success" label="{{ (isReseller ? 'UI_ACTIVATE' : (isNew ? 'UI_PURCHASE_LICENSE' : 'UI_UPGRADE_LICENSE')) | translate }}"></button>
    </div>
  </ng-template>
</p-dialog>
