<p-dialog [(visible)]="visible" appendTo="body" [modal]="true" [breakpoints]="{ '960px': '75vw', '640px': '100vw' }" [style]="{ 'max-height': '90vh', overflow: 'auto', width: '60vw' }" [closable]="false">
  <div class="grid formgrid p-fluid">
    <div class="field mb-4 col-12" [class.lg:col-6]="relation?.entityType?.vat">
      <label for="relationTypes" class="font-medium text-900">{{ 'label.relation.type' | transloco : { default: 'Relatie type' } }}</label>
      <p-dropdown
        id="relationTypes"
        inputId="relationTypes"
        *ngIf="!entityTypeLoading && relation"
        [options]="entityTypes"
        optionValue="id"
        optionLabel="name"
        [filter]="true"
        filterBy="name"
        [showClear]="false"
        [(ngModel)]="relation.entityTypeId"
        (onChange)="setEntityType($event.value)"
        placeholder="{{ 'label.selectPlaceholder.relationType' | transloco : { default: 'Selecteer een relatie type' } }}"
        emptyFilterMessage="{{ 'label.list.relationTypes.emptyFilter' | transloco : { default: 'Er zijn geen relatie types in het systeem die aan de huidige filters voldoen.' } }}"
        emptyMessage="{{ 'label.list.relationTypes.empty' | transloco : { default: 'Er zijn geen relatie types gevonden in het systeem.' } }}"
        appendTo="body"
      >
        <ng-template let-class pTemplate="item">
          <div class="flex align-items-center">
            <div>{{ class.name }}</div>
          </div>
        </ng-template>
      </p-dropdown>
      <p-skeleton height="2.7rem"  *ngIf="!(!entityTypeLoading && relation)"></p-skeleton>
      <small class="text-orange-600" *ngIf="relation && !relation.entityTypeId">{{ 'label.base.fieldRequired' | transloco : { default: 'Dit veld is verplicht!' } }}</small>
    </div>

    <doffice-field-buttons class="mb-2 col-12 lg:col-6" [buttons]="vatButtons" *ngIf="relation?.entityType?.vat">
      <label for="vatnumber" class="font-medium text-900">{{ 'UI_VAT_NUMBER' | translate }}</label>
      <div class="p-inputgroup">
        <input id="vatnumber" type="text" pInputText [(ngModel)]="relation.vatNumber" />
      </div>
    </doffice-field-buttons>

    <div class="field mb-4 col-12">
      <label for="name" class="font-medium text-900">{{ 'UI_NAME' | translate }}</label>
      <input id="name" type="text" pInputText [(ngModel)]="relation.name" />
      <small class="text-orange-600" *ngIf="relation && (relation.name ?? '').trim().length <= 0">{{ 'label.base.fieldRequired' | transloco : { default: 'Dit veld is verplicht!' } }}</small>
    </div>

    <doffice-checkbox-group class="mb-4 col-12">
      <doffice-checkbox [readonly]="fixedCustomer" label="{{ 'label.relation.isCustomer' | transloco : { default: 'Klant' } }}" [(ngModel)]="relation.isCustomer"></doffice-checkbox>
      <doffice-checkbox [readonly]="fixedSupplier" label="{{ 'label.relation.isSupplier' | transloco : { default: 'Leverancier' } }}" [(ngModel)]="relation.isSupplier"></doffice-checkbox>
    </doffice-checkbox-group>

    <p-divider class="col-12 mb-2" [align]="'center'">
      {{ 'label.relation.defaultAddress' | transloco : { default: 'Standaard adres' } }}
    </p-divider>

    <div class="field mb-4 col-12 lg:col-9">
      <label for="defaultStreet" class="font-medium text-900">{{ 'UI_ADDRESS_STREET' | translate }}</label>
      <div class="p-inputgroup">
        <input id="defaultStreet" type="text" pInputText [(ngModel)]="getDefaultAddress().street" />
      </div>
    </div>

    <div class="field mb-4 col-12 lg:col-3">
      <label for="defaultNumber" class="font-medium text-900">{{ 'UI_ADDRESS_NUMBER' | translate }}</label>
      <div class="p-inputgroup">
        <input id="defaultNumber" type="text" pInputText [(ngModel)]="getDefaultAddress().number" />
      </div>
    </div>

    <div class="field mb-4 col-12 lg:col-3">
      <label for="defaultZip" class="font-medium text-900">{{ 'UI_ADDRESS_ZIP' | translate }}</label>
      <div class="p-inputgroup">
        <input id="defaultZip" type="text" pInputText [(ngModel)]="getDefaultAddress().zip" />
      </div>
    </div>

    <div class="field mb-4 col-12 lg:col-9">
      <label for="defaultCity" class="font-medium text-900">{{ 'UI_ADDRESS_CITY' | translate }}</label>
      <div class="p-inputgroup">
        <input id="defaultCity" type="text" pInputText [(ngModel)]="getDefaultAddress().city" />
      </div>
    </div>

    <div class="field mb-4 col-12 lg:col-6">
      <label for="defaultCounty" class="font-medium text-900">{{ 'UI_COUNTY' | translate }}</label>
      <div class="p-inputgroup">
        <input id="defaultCounty" type="text" pInputText [(ngModel)]="getDefaultAddress().county" />
      </div>
    </div>

    <div class="field mb-4 col-12 lg:col-6">
      <label for="defaultCountry" class="font-medium text-900">{{ 'UI_COUNTRY' | translate }}</label>
      <div class="p-inputgroup" *ngIf="relation">
        <p-dropdown
          id="defaultCountry"
          inputId="defaultCountry"
          [options]="countries"
          optionValue="id"
          optionLabel="name"
          [filter]="true"
          filterBy="name"
          [showClear]="false"
          [(ngModel)]="getDefaultAddress().country_id"
          (onChange)="setDefaultCountry($event.value)"
          placeholder="{{ 'label.selectPlaceholder.country' | transloco : { default: 'Selecteer een land' } }}"
          emptyFilterMessage="{{ 'label.list.countries.emptyFilter' | transloco : { default: 'Er zijn geen landen in het systeem die aan de huidige filters voldoen.' } }}"
          emptyMessage="{{ 'label.list.countries.empty' | transloco : { default: 'Er zijn geen landen gevonden in het systeem.' } }}"
          appendTo="body"
        >
          <ng-template let-country pTemplate="item">
            <div class="flex align-items-center">
              <div>{{ country.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <p-skeleton height="2.7rem"  *ngIf="!(relation)"></p-skeleton>
    </div>

    <p-divider class="col-12 mb-2" [align]="'center'">
      {{ 'UI_DOCUMENT_SETTINGS' | translate }}
    </p-divider>

    <doffice-field-buttons class="mb-2 col-12">
      <label for="vatrates" class="font-medium text-900">{{ 'UI_DEFAULT_VAT_SALES_NAME_VALUE' | translate }}</label>
      <div class="p-inputgroup">
        <p-dropdown
          id="vatrates"
          inputId="salesVatrates"
          *ngIf="relation"
          [options]="getVats()"
          optionValue="code"
          optionLabel="label"
          [filter]="true"
          filterBy="label"
          [showClear]="false"
          [(ngModel)]="relation.defaultVatCode"
          placeholder="{{ 'label.selectPlaceholder.vatrate' | transloco : { default: 'Selecteer een BTW tarief' } }}"
          emptyFilterMessage="{{ 'label.list.vatrate.emptyFilter' | transloco : { default: 'Er zijn geen BTW tarieven in het systeem die aan de huidige filters voldoen.' } }}"
          emptyMessage="{{ 'label.list.vatrate.empty' | transloco : { default: 'Er zijn geen BTW tarieven gevonden in het systeem.' } }}"
          appendTo="body"
        >
          <ng-template let-vat pTemplate="item">
            <div class="flex align-items-center">
              <div>{{ vat.label }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <p-skeleton height="2.7rem"  *ngIf="!(relation)"></p-skeleton>
    </doffice-field-buttons>
  </div>
  <ng-template pTemplate="footer">
    <div class="border-top-1 surface-border pt-3">
      <button pButton pRipple (click)="cancelClicked()" class="p-button-sm mr-2 p-button-text p-button-danger" label="{{ 'label.general.cancel' | transloco : { default: 'Annuleren' } }}"></button>
      <button
        pButton
        pRipple
        icon="pi pi-check"
        [disabled]="!relation.Validation"
        [loading]="saveLoading"
        (click)="saveClicked()"
        class="p-button-sm p-button-success"
        label="{{ 'label.general.saveAndUse' | transloco : { default: 'Opslaan en gebruiken' } }}"
      ></button>
    </div>
  </ng-template>
</p-dialog>
