import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslationModule} from 'app/core/pipes/translation/translation.module';
import {ConvertionModule} from 'app/core/pipes/convertion/convertion.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {FormsModule} from '@angular/forms';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {DofficeCheckboxModule} from '../checkbox/checkbox.module';
import {RippleModule} from 'primeng/ripple';
import {ErrorModalComponent} from './error-modal/error-modal.component';
import {AcceptanceLeaveComponentModalComponent} from './acceptance-leave-component-modal/acceptance-leave-component-modal.component';
import {AcceptanceRemoveDataModalComponent} from './acceptance-remove-data-modal/acceptance-remove-data-modal.component';
import {CreationArticleCategoryModalComponent} from './creation-article-category-modal/creation-article-category-modal.component';
import {SkeletonModule} from 'primeng/skeleton';
import {InputTextModule} from 'primeng/inputtext';
import {SimpleInformationModalComponent} from './simple-information-modal/simple-information-modal.component';
import {AcceptanceVatCheckModalComponent} from './acceptance-vat-check-modal/acceptance-vat-check-modal.component';
import {CreationRelationModalComponent} from './creation-relation-modal/creation-relation-modal.component';
import {DropdownModule} from 'primeng/dropdown';
import {DividerModule} from 'primeng/divider';
import {CreationServiceModalComponent} from './creation-service-modal/creation-service-modal.component';
import {CreationProductModalComponent} from './creation-product-modal/creation-product-modal.component';
import {CreationRelationContactModalComponent} from './creation-relation-contact-modal/creation-relation-contact-modal.component';
import {SendDocumentEmailModalComponent} from './send-document-email-modal/send-document-email-modal.component';
import {StepsModule} from 'primeng/steps';
import {CheckboxModule} from 'primeng/checkbox';
import {AcceptanceSetGlobalVatModalComponent} from './acceptance-set-global-vat-modal/acceptance-set-global-vat-modal.component';
import {CreationBankAccountModalComponent} from './creation-bank-account-modal/creation-bank-account-modal.component';
import {AcceptanceConfirmModalComponent} from './acceptance-confirm-modal/acceptance-confirm-modal.component';
import {AcceptanceYesNoModalComponent} from './acceptance-yesno-modal/acceptance-yesno-modal.component';
import {ChangeSubscriberModalComponent} from './change-subscriber-request-modal/change-subscriber-modal.component';
import {PasswordModule} from 'primeng/password';
import {PurchaseLicenseModalComponent} from './purchase-license/purchase-license-modal.component';
import {SelectBankModalComponent} from './select-bank-modal/select-bank-modal.component';
import {DofficeEditorModule} from '../editor/editor.module';
import {ViewPdfModalComponent} from './view-pdf-modal/view-pdf-modal.component';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {CreationTransactionGatewayModalComponent} from './creation-transaction-gateway-modal/creation-transaction-gateway-modal.component';
import {CreationTransactionModalComponent} from './creation-transaction-modal/creation-transaction-modal.component';
import {AcceptanceOlderDateOnConfirmModalComponent} from './acceptance-older-date-on-confirm-modal/acceptance-older-date-on-confirm-modal.component';
import {RequestSignatureModalComponent} from './request-signature-modal/request-signature-modal.component';
import {SignaturePadModule} from 'angular-signature-pad-v2';
import {AcceptanceSetGlobalAccountnumberModalComponent} from './acceptance-set-global-accountnumber-modal/acceptance-set-global-accountnumber-modal.component';
import {SearchRelationModalComponent} from './search-relation/search-relation-modal.component';
import {SearchProductModalComponent} from './search-product/search-product-modal.component';
import {TranslocoModule} from '@jsverse/transloco';
import {SearchServiceModalComponent} from './search-service/search-service-modal.component';
import {SearchCombinedArticleModalComponent} from './search-combined-article/search-combined-article-modal.component';
import {DofficeFieldButtonsModule} from '../field-buttons/field-buttons.module';
import {SearchVatModalComponent} from "./search-vat/search-vat-modal.component";
import {DownloadModalComponent} from "./download-modal/download-modal.component";
import {CardModule} from "primeng/card";
import {ScrollPanelModule} from "primeng/scrollpanel";
import {DofficeCalendar} from "../calendar/calendar.component";
import {DofficeSmartTable} from "../table/smart-table.component";

const PRIMENG = [DialogModule, ButtonModule, RippleModule, SkeletonModule, InputTextModule, DropdownModule, DividerModule, StepsModule, CheckboxModule, PasswordModule, CardModule, ScrollPanelModule];
const COMPONENTS = [
  AcceptanceRemoveDataModalComponent,
  ErrorModalComponent,
  AcceptanceLeaveComponentModalComponent,
  CreationArticleCategoryModalComponent,
  SimpleInformationModalComponent,
  AcceptanceVatCheckModalComponent,
  CreationRelationModalComponent,
  CreationServiceModalComponent,
  CreationProductModalComponent,
  CreationRelationContactModalComponent,
  SendDocumentEmailModalComponent,
  AcceptanceSetGlobalVatModalComponent,
  CreationBankAccountModalComponent,
  AcceptanceConfirmModalComponent,
  AcceptanceYesNoModalComponent,
  ChangeSubscriberModalComponent,
  PurchaseLicenseModalComponent,
  SelectBankModalComponent,
  ViewPdfModalComponent,
  CreationTransactionGatewayModalComponent,
  CreationTransactionModalComponent,
  AcceptanceOlderDateOnConfirmModalComponent,
  RequestSignatureModalComponent,
  AcceptanceSetGlobalAccountnumberModalComponent,
  SearchRelationModalComponent,
  SearchProductModalComponent,
  SearchServiceModalComponent,
  SearchCombinedArticleModalComponent,
  SearchVatModalComponent,
  DownloadModalComponent
];

@NgModule({
  imports: [
    CommonModule,
    TranslationModule,
    ConvertionModule,
    FontAwesomeModule,
    DofficeEditorModule,
    FormsModule,
    DofficeCheckboxModule,
    TranslationModule,
    DofficeSmartTable,
    NgxExtendedPdfViewerModule,
    SignaturePadModule,
    TranslocoModule,
    DofficeFieldButtonsModule,
    DofficeCalendar,
    ...PRIMENG,
  ],
  exports: [...COMPONENTS],
  declarations: [AcceptanceLeaveComponentModalComponent, ...COMPONENTS],
})
export class DofficeModalModule {}
