export const environment = {
  production: true,
  azure: {
    clientId: 'ee177dec-9397-4988-a412-2586fbde481e',
    authority: 'https://login.microsoftonline.com/common/',
    scopes: ['api://doffice-api/email', 'api://doffice-api/Mail.Send', 'api://doffice-api/offline_access', 'api://doffice-api/openid', 'api://doffice-api/profile', 'api://doffice-api/User.Read'],
    systemScopes: ['api://doffice-api/email', 'api://doffice-api/Mail.Read', 'api://doffice-api/Mail.ReadWrite', 'api://doffice-api/Mail.Send', 'api://doffice-api/offline_access', 'api://doffice-api/openid', 'api://doffice-api/profile', 'api://doffice-api/User.Read']
  },
  exactOnline: {
    clientId: '9002e9ab-9798-4e75-b761-ce8c3dcb10e4',
  },
  google: {
    clientId: '601775384542-lpi6tufjdqb0sq7rg7c6slcbvvvtins4.apps.googleusercontent.com',
    scopes: ['https://www.googleapis.com/auth/gmail.send']
  },
  openreplay: {
    key: 'FLD8LYShiqwW3MIpLQ7c'
  },
  sentry: {
    dsn: 'https://ddd5f32efa6ea7374b7fc22f1ceaa950@o4504787641434112.ingest.sentry.io/4506313890922496',
    tracePropagationTargets: [
      /^https:\/\/web\.doffice\.app/,
      /^https:\/\/api\.doffice\.app/,
      /^https:\/\/whitelabel\.nyffels\.dev/,
      /^https:\/\/api-whitelabel\.nyffels\.dev/,
      /^https:\/\/qadmin\.qastan\.be/,
      /^https:\/\/api-qadmin\.qastan\.be/
    ],
    tracesSampleRate: 1.0,
    replaySessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0
  },
  encryption: {
    auth: {
      client: "pmk@hjx1KXZ8zkv9ght",
      partner: "jbr!qdu*DYC2zry!feh"
    }
  },
  whitelabels: {
    doffice: {
      name: 'D\'Office',
      app: {
        url: 'https://web.doffice.app',
        logo: '/assets/whitelabels/doffice/images/logo.svg',
        accentColor: '#d7b73f',
        systemEmail: "system@doffice.app",
        enableCatchallMailboxes: true,
        enableSelfLicenseUpgrade: true,
        support: {
          website: "",
          email: "mailto:support@doffice.app",
          call: "",
          whatsapp: "https://api.whatsapp.com/send?phone=0032495501991",
          messenger: "https://m.me/108000948759361"
        },
      },
      api: {
        url: 'https://api.doffice.app',
        ws: 'wss://information.doffice.app'
      },
      azure: {
        redirectUri: 'https://web.doffice.app',
      },
      exactOnline: {
        redirectUri: "https://web.doffice.app/client/intigration/accountancy/exact"
      }
    },
    demo: {
      name: 'Whitelabel',
      app: {
        url: 'https://whitelabel.nyffels.dev',
        logo: '/assets/whitelabels/demo/images/logo.png',
        accentColor: '#008000',
        systemEmail: "whitelabel@nyffels.be",
        enableCatchallMailboxes: false,
        enableSelfLicenseUpgrade: false,
        support: {
          website: "",
          email: "",
          call: "",
          whatsapp: "",
          messenger: ""
        },
      },
      api: {
        url: 'https://api-whitelabel.nyffels.dev',
        ws: 'wss://api-whitelabel.nyffels.dev'
      },
      azure: {
        redirectUri: 'https://whitelabel.nyffels.dev',
      },
      exactOnline: {
        redirectUri: "https://whitelabel.nyffels.dev/client/intigration/accountancy/exact"
      }
    },
    qadmin: {
      name: 'QAdmin',
      app: {
        url: 'https://qadmin.qastan.be',
        logo: '/assets/whitelabels/qadmin/images/logo.png',
        accentColor: '#15678E',
        systemEmail: "system",
        enableCatchallMailboxes: false,
        enableSelfLicenseUpgrade: false,
        support: {
          website: "",
          email: "",
          call: "",
          whatsapp: "",
          messenger: ""
        },
      },
      api: {
        url: 'https://api-qadmin.qastan.be',
        ws: 'wss://api-qadmin.qastan.be:81'
      },
      azure: {
        redirectUri: 'https://qadmin.qastan.be',
      },
      exactOnline: {
        redirectUri: "https://qadmin.qastan.be/client/intigration/accountancy/exact"
      }
    }
  }
};
