import {Component, ChangeDetectionStrategy, ChangeDetectorRef, isDevMode, OnDestroy, ViewChild, ViewContainerRef} from '@angular/core';

import {firstValueFrom, Subject} from 'rxjs';
import {TranslationService} from 'app/core/services/translation.service';
import {ConvertionService} from 'app/core/services/convertion.service';
import {default as LanguageFile} from './language.file.json';
import {ConvertionInjector, TranslationInjector} from './app.module';
import {SupportService} from './core/services/support.service';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {TranslocoService, translate} from '@jsverse/transloco';
import {marker} from '@nyffels/transloco-keys-manager/marker';
import _ from 'lodash';
import {TranslocoChangedEvent} from './shared/logic/system/translation.logic';
import {getWhitelabelEnvironment, getWhitelabelName} from "./core/logic/whitelabel.logic";
import {GraphqlService} from "./core/services/graphql.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnDestroy {
  @ViewChild('notificationsArea', {read: ViewContainerRef}) notificationArea;

  title = 'DOffice';

  startup: boolean = true; // Is the program starting up? Show splash screen, if done start application.
  isDev: boolean = false;

  private _destroy$: Subject<void> = new Subject();

  constructor(

    private cdr: ChangeDetectorRef,
    private translationService: TranslationService,
    private convertionService: ConvertionService,
    private supportService: SupportService,
    private httpClient: HttpClient,
    private titleService: Title,
    private translocoService: TranslocoService,
    private vcr: ViewContainerRef,
    private graphqlService: GraphqlService
  ) {
    this.supportService.start();
    this.isDev = isDevMode();

    this.httpClient.get('assets/version', {responseType: 'text'}).subscribe({
      next: (data) => {
        console.log('Version: ' + data);
        const name = getWhitelabelEnvironment().name;
        this.titleService.setTitle(name);
        (document.querySelector('#favIcon') as any).href = `assets/whitelabels/${getWhitelabelName()}/favicon.ico`;
        this.cdr.detectChanges();
      },
      error: (err) => {
        console.warn('VERSION NOT FOUND!');
        this.cdr.detectChanges();
      },
    });

    this.convertionService.settings = {
      dateFormat: 'DD/MM/YYYY',
      dateTimeFormat: 'DD/MM/YYYY HH:mm:ss',
      timezone: 'Europe/Brussels',
      moneySettings: {
        decimals: 2,
        symbol: '€',
        symbolLocation: 'BEFORE',
      },
      numberSettings: {
        decimalSeperationSymbol: ',',
        digitSeperationSymbol: '.',
      },
    };
    ConvertionInjector.get(ConvertionService).settings = this.convertionService.settings;

    this.translationService.loadData(LanguageFile, false);
    TranslationInjector.get(TranslationService).loadData(LanguageFile, false);

    this.translationService.language = navigator.language.substring(0, 2);
    this.translationService.defaultIso = 'nl';
    TranslationInjector.get(TranslationService).language = this.translationService.language;
    TranslationInjector.get(TranslationService).defaultIso = this.translationService.language;
    this.translocoService.load(this.translationService.language).subscribe();
    this.translocoService.setActiveLang(this.translationService.language);
    TranslocoChangedEvent(this.translocoService).subscribe(() => {
      this.startupProcess().then((completed) => {
        if (completed == null) alert(translate(marker('error.init.databaseConnectionFailed', 'Verbinding met de database mislukt. Controleer de internet verbinding en probeer het opnieuw. Als je deze melding blijft krijgen, contacteer support.')));
        if (completed == true) this.startup = false;

        this.cdr.detectChanges();
      });
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._destroy$.unsubscribe();
  }

  private async startupProcess(): Promise<Boolean> {
    let startup = await firstValueFrom(this.graphqlService.queries.validateDatabaseConnection());
    if (startup == null) return null;

    return true;
  }

  getLogo() {
    return getWhitelabelEnvironment().app.logo
  }
}
