import {constructFromInterface} from 'app/core/logic/map.logic';

export interface ICountry {
  id: number;
  alpha: string;
  getName: () => string;
}

export class Country implements ICountry {
  id: number = 0;
  alpha: string = '';
  getName: () => string;

  get name() {
    return this.getName();
  }

  constructor(country: ICountry = null) {
    if (country === null) {
      return;
    }

    this.getName = country.getName;
    constructFromInterface(this, country);
  }
}
