import {ArticleCategory} from '../parameters/article-category';
import _ from 'lodash';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {IService, IServiceInput, IServiceMutationInput} from "../../../core/graphql/generated/types";
import {GetDateTimeScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";

export class Service implements IService {
  id: number = 0;
  subscriber_id: number = 0;
  sku: string = '';
  name: string = '';
  description: string = '';

  get FullName(): string {
    if (this.sku) {
      return `${this.name} (${this.sku})`;
    } else {
      return this.name;
    }
  }

  artcat_id: number = 0;
  category: ArticleCategory;
  salesprice: number = 0;
  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  archived: boolean = false;
  defaultUnit: string = '';
  importDescriptionOnDocument: boolean = false;

  get Validation(): boolean {
    return this.name != null && this.name?.trim().length > 0;
  }

  autogenerateSku(): void {
    this.sku = this.name.toLowerCase()
      .replace(/ /g, '-');
  }

  convertToInput(): IServiceInput {
    return {
      id: this.id,
      artcat_id: this.artcat_id,
      description: this.description,
      name: this.name,
      salesprice: +this.salesprice,
      sku: this.sku,
      archived: this.archived,
      defaultUnit: this.defaultUnit,
      importDescriptionOnDocument: this.importDescriptionOnDocument,
    };
  }

  createMutationInput(originalService: Service): IServiceMutationInput {
    let mutationInput: IServiceMutationInput = {id: this.id, service: null};
    mutationInput.service = _.isEqual(this.convertToInput(), originalService?.convertToInput()) ? null : this.convertToInput();

    return mutationInput;
  }

  constructor(service: IService = null) {
    if (service === null) {
      return;
    }

    constructFromInterface(this, service);

    if (service.created) {this._created = GetDateTimeScalar(service.created);}
    if (service.updated) {this._updated = GetDateTimeScalar(service.updated);}
    if (service.category) {
      this.category = new ArticleCategory(service.category);
    }
  }

  public static new(): Service {
    return new Service({
      id: null,
      subscriber_id: null,
      name: null,
      artcat_id: null,
      category: null,
      created: null,
      description: null,
      salesprice: null,
      sku: null,
      updated: null,
      archived: false,
      defaultUnit: null,
      importDescriptionOnDocument: false,
    });
  }
}
