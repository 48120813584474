import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'doffice-checkbox-group',
  styleUrls: ['./checkbox-group.scss'],
  template: `
    <div class="checkbox-group">
      <ng-content select="doffice-checkbox"></ng-content>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class DofficeCheckboxGroup implements OnChanges {
    @Input() gap: string = "30px";

    constructor(private ref: ElementRef, private cdr: ChangeDetectorRef) {
        this.ref.nativeElement.style.setProperty('--gap', this.gap);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['gap']) {
            this.ref.nativeElement.style.setProperty('--gap', changes['gap'].currentValue);
            this.cdr.detectChanges();
        }
    }
}
