import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClientAuthenticationGuard } from 'app/core/guards/client-authentication.guard';
import { ClientAuthenticationRejectGuard } from 'app/core/guards/client-authentication-reject.guard';
import { PartnerAuthenticationGuard } from './core/guards/partner-authentication.guard';
import { PartnerAuthenticationRejectGuard } from './core/guards/partner-authentication-reject.guard';
import { ClientExpiredLicenseGuard } from './core/guards/client-expirated-license.guard';

const routes: Routes = [
  {
    path: '',
    children: [
			{
        path: 'authentication',
        loadChildren: () => import('./feature/authentication/authentication.module').then((m) => m.AuthenticationModule),
      },
      {
        path: 'client',
        loadChildren: () => import('./feature/client/client.module').then((m) => m.ClientModule),
      },
			{
        path: 'partner',
        loadChildren: () => import('./feature/partner/partner.module').then((m) => m.PartnerModule),
      },
			{
        path: 'guest',
        loadChildren: () => import('./feature/guest/guest.module').then((m) => m.GuestModule),
      },
      {
        path: 'registration',
        loadChildren: () => import('./feature/registration/registration.module').then((m) => m.RegistationModule),
      },
      {
        path: 'onboarding',
        loadChildren: () => import('./feature/onboarding/onboarding.module').then((m) => m.OnboardingModule),
      },
      {
        path: 'export',
        loadChildren: () => import('./feature/export/export.module').then((m) => m.ExportModule),
      },
      /* 404 page */
      { path: '**', redirectTo: '/client' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [ClientAuthenticationGuard, ClientExpiredLicenseGuard, ClientAuthenticationRejectGuard, PartnerAuthenticationGuard, PartnerAuthenticationRejectGuard],
})
export class AppRoutingModule {}
