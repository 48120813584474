<p-dialog  [(visible)]="visible" appendTo="body" [modal]="true" [breakpoints]="{ '960px': '75vw', '640px': '100vw' }" [style]="{ 'max-height': '90vh', 'overflow': 'auto', width: '60vw' }" [closable]="false">
  <div class="grid formgrid p-fluid">
    <div class="field mb-4 col-12">
      <label for="name" class="font-medium text-900">{{ 'label.bankAccount.name' | transloco: { default: 'Naam' } }}</label>
      <input id="name" type="text" pInputText [(ngModel)]="bank.name" />
    </div>

    <div class="field mb-4 col-12 lg:col-6">
      <label for="vatRate" class="font-medium text-900">{{ 'label.bankAccount.iban' | transloco: { default: 'IBAN' } }}</label>
      <input id="vatRate" type="text" pInputText [(ngModel)]="bank.accountnumber" />
    </div>

    <div class="field mb-4 col-12 lg:col-6">
      <label for="vatRate" class="font-medium text-900">{{ 'label.bankAccount.bic' | transloco: { default: 'BIC' } }}</label>
      <input id="vatRate" type="text" pInputText [(ngModel)]="bank.bic" />
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="border-top-1 surface-border pt-3">
      <button pButton pRipple (click)="cancelClicked()" class="p-button-sm mr-2 p-button-text p-button-danger" label="{{ 'label.general.cancel' | transloco: { default: 'Annuleren' } }}"></button>
      <button pButton pRipple icon="pi pi-check" [disabled]="!bank.Validation" [loading]="saveLoading" (click)="saveClicked()" class="p-button-sm p-button-success" label="{{ 'label.general.saveAndUse' | transloco: { default: 'Opslaan en gebruiken' } }}"></button>
    </div>
  </ng-template>
</p-dialog>
