<div class="px-4 py-8 lg:px-6 lg:px-8 flex align-items-center justify-content-center doffice-registration-container" [class.flex]="!isMobile"  *ngIf="!registrationCompletedFaded">
  <div class="p-4 shadow-2 text-center lg:w-30rem doffice-registration-card" [style.opacity]="registrationCompleted ? '0' : '1'">
    <img [src]="getLogo()" alt="logo" height="75" class="mb-4 logo"/>

    <div class="doffice-registration-fields">
      <input
        type="text"
        placeholder="{{ 'label.guest.clientRegistration.name' | transloco : { default: 'Naam' } }}"
        pInputText
        class="appearance-none border-none p-3 w-full outline-none text-xl block bg-white-alpha-40 text-primary-alpha-80 mb-4"
        [(ngModel)]="name"
        [readonly]="registrationCompleted"
      />
      <input
        type="text"
        placeholder="{{ 'label.guest.clientRegistration.email' | transloco : { default: 'E-mail' } }}"
        pInputText
        [class.mb-4]="(emailError ?? '').length <= 0"
        class="appearance-none border-none p-3 w-full outline-none text-xl mb-2 block bg-white-alpha-40 text-primary-alpha-80"
        [(ngModel)]="email"
        (ngModelChange)="emailUpdated()"
        [readonly]="registrationCompleted"
      />
      <p class="mb-4 ml-2 text-left text-red-600 text-xs">{{ emailError }}</p>

      <div class="mb-4">
        <p-dropdown
          [options]="howDoYouKnowUsOptions"
          [(ngModel)]="howDoYouKnowUs"
          placeholder="{{ 'label.guest.clientRegistration.howDoYouKnowUs' | transloco : { default: 'Van waar ken je ons?' } }}"
          optionLabel="label"
          optionValue="id"
          [editable]="false"
          [readonly]="registrationCompleted"
          class="doffice-authentication-dropdown appearance-none p-1 border-none w-full outline-none text-xl mb-2 block bg-white-alpha-40 text-primary-alpha-80"
        ></p-dropdown>
      </div>
      <input
        *ngIf="howDoYouKnowUs == HowDoYouKnowUsEnum.OTHER"
        type="text"
        placeholder="{{ 'label.guest.clientRegistration.how' | transloco : { default: 'Hoe?' } }}"
        pInputText
        class="appearance-none border-none p-3 w-full outline-none text-xl mb-2 block bg-white-alpha-40 text-primary-alpha-80 mb-4"
        [(ngModel)]="howDoYouKnowUsExtra"
        [readonly]="registrationCompleted"
      />
    </div>

    <p-button
      pRipple
      label="{{ 'label.guest.clientRegistration' | transloco : { default: 'GRATIS registreren' } }}"
      [loading]="registerLoading"
      [disabled]="(name ?? '').length <= 0 || (email ?? '').length <= 0 || (emailError ?? '').length > 0 || registrationCompleted"
      class="doffice-registration-login-button"
      (onClick)="registrationRequest()"
    >
    </p-button>
  </div>
</div>

<div class="flex flex-column gap-4 align-items-center justify-content-center doffice-registration-container" *ngIf="registrationCompletedFaded">
  <div class="flex flex-row gap-3">
    <div class="p-6 shadow-2 text-center lg:w-30rem doffice-registration-card mr-2 doffice-disable-select doffice-registration-card-completed" [class.mb-4]="isMobile">
      <i class="pi pi-check-circle" style="color: rgb(3, 214, 3); font-size: 50px"></i>
      <p class="text-left text-2xl">{{ 'label.guest.clientRegistration.onboarding.requestreceived' | transloco : {default: 'We hebben jouw aanmelding goed ontvangen!'} }}</p>
    </div>
    <div class="p-6 shadow-2 text-center lg:w-30rem doffice-registration-card ml-2 mr-2 doffice-disable-select doffice-registration-card-completed" [class.mb-4]="isMobile" *ngIf="registrationCompletedFaded">
      <i class="pi pi-info-circle" style="font-size: 50px"></i>
      <p class="text-left text-2xl">{{ 'label.guest.clientRegistration.onboarding.confirmYourEmail' | transloco : {default: 'Start jouw aanmelding via de link in de e-mail of druk op de onderstaande knop.'} }}</p>
    </div>
    <div class="p-6 shadow-2 text-center lg:w-30rem doffice-registration-card ml-2 doffice-disable-select doffice-registration-card-completed" *ngIf="registrationCompletedFaded">
      <i class="pi pi-exclamation-circle" style="color: rgb(185, 151, 0); font-size: 50px"></i>
      <p class="text-left text-2xl">{{ 'label.guest.clientRegistration.keepReady.title' | transloco : {default: 'Hou dit paraat:'} }}</p>
      <ul class="text-left text-2xl">
        <li>{{ 'label.guest.clientRegistration.keepReady.vatnumber' | transloco : {default: 'BTW nummer'} }}</li>
        <li>{{ 'label.guest.clientRegistration.keepReady,accountnumber' | transloco : {default: 'Bankgegevens voor klanten'} }}</li>
        <li>{{ 'label.guest.clientRegistration.keepReady.logo' | transloco : {default: 'Logo (optioneel)'} }}</li>
      </ul>
    </div>
  </div>
  <div class="">
    <button pButton class="p-button-success" label="{{ 'label.clientRegistration.onboarding.startOnboardingButton' | transloco : { default: 'Begin direct met het opmaken van jouw omgeving' } }}" (click)="startOnboarding()"></button>
  </div>
</div>
