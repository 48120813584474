<p-dialog [(visible)]="visible" appendTo="body" [modal]="true" [breakpoints]="{ '960px': '80vw', '640px': '100vw' }"
          [style]="{ 'max-height': '90vh', overflow: 'auto', width: '80vw' }" [closable]="false">
  <ng-template pTemplate="header">
    <div class="flex align-items-center">
      <span class="flex align-items-center justify-content-center text-grey-500 mr-3">
        <i class="pi pi-search text-3xl"></i>
      </span>
      <span class="font-medium text-2xl text-900">
        <span>{{ 'label.modal.choseVat.title' | transloco : {default: 'Kies een BTW tarief'} }}</span>
      </span>
    </div>
  </ng-template>

  <div class="vatmodal-list">
    <div class="grid formgrid p-fluid">
      <div *ngFor="let vat of vats" class="vatmodel-list-item flex flex-row flex-nowrap gap-2 col-12 mb-4">
        <div class="field flex-1">
          <label class="font-medium text-900">{{ 'label.modal.choseVat.name' | transloco: {default: 'Tarief'} }}</label>
          <input [readOnly]="true" type="text" pInputText [ngModel]="vat.label"/>
        </div>
        <div class="field flex-1">
          <label
            class="font-medium text-900">{{ 'label.moda.choseVat.percentage' | transloco: {default: 'Percentage'} }}</label>
          <input [readOnly]="true" type="text" pInputText ngModel="{{vat.percentage}}%"/>
        </div>
        <div class="flex-none align-self-end mb-3">
          <button pButton class="p-button-success" icon="pi pi-check" (click)="selectClicked(vat.code)"
                  label="{{ 'label.modal.choseVat.selectBtn' | transloco: { default: 'Selecteren' } }}"></button>
        </div>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="border-top-1 surface-border pt-3">
      <button pButton pRipple (click)="cancelClicked()" class="p-button-sm mr-2 p-button-text p-button-secondary"
              label="{{ 'label.base.cancel' | transloco : { default: 'Annuleren' } }}"></button>
    </div>
  </ng-template>
</p-dialog>
