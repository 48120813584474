<p-dialog [(visible)]="visible" appendTo="body" [modal]="true" [breakpoints]="{ '960px': '75vw', '640px': '100vw' }" [style]="{ 'max-height': '90vh', overflow: 'auto', width: '40vw' }" [closable]="false">
  <ng-template pTemplate="header">
    <div class="flex align-items-center">
      <span class="font-medium text-2xl text-900">
        <span>{{ 'label.modal.change.subscriberRequest.title' | transloco : { default: 'Abonnee wijzigen' } }}</span>
      </span>
    </div>
  </ng-template>
  <div class="grid formgrid p-fluid">
    <div class="field mb-4 col-12">
      <p-steps [model]="stepItems" [(activeIndex)]="stepIndex" [readonly]="true"></p-steps>
    </div>
    <ng-container *ngIf="stepIndex == 0">
      <ng-container *ngIf="!subscribersLoading">
        <ng-container *ngFor="let subscriber of subscribers">
          <doffice-field-buttons class="mb-2 col-12" buttonMargin="0" [buttons]="selectSubscriberButtons" [data]="subscriber">
            <input [readOnly]="true" pInputText [ngModel]="subscriber.nameAndVat" />
          </doffice-field-buttons>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="subscribersLoading">
        <div class="field mb-4 col-1"><p-skeleton height="2.7rem" ></p-skeleton></div>
        <div class="field mb-4 col-11"><p-skeleton height="2.7rem" ></p-skeleton></div>

        <div class="field mb-4 col-1"><p-skeleton height="2.7rem" ></p-skeleton></div>
        <div class="field mb-4 col-11"><p-skeleton height="2.7rem" ></p-skeleton></div>

        <div class="field mb-4 col-1"><p-skeleton height="2.7rem" ></p-skeleton></div>
        <div class="field mb-4 col-11"><p-skeleton height="2.7rem" ></p-skeleton></div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="stepIndex == 1">
      <div class="field mb-4 col-12">
        <label for="password" class="font-medium text-900">{{ 'modal.change.subscriberRequest.password' | transloco : { default: 'Wachtwoord' } }}</label>
        <p-password [(ngModel)]="password" [feedback]="false"></p-password>
      </div>
    </ng-container>
  </div>
  <ng-template pTemplate="footer">
    <div class="border-top-1 surface-border pt-3">
      <button pButton pRipple [disabled]="switching" (click)="cancelClicked()" class="p-button-sm mr-2 p-button-text p-button-danger" label="{{ 'label.general.cancel' | transloco : { default: 'Annuleren' } }}"></button>
      <button
        pButton
        pRipple
        *ngIf="stepIndex == 1 && !switching"
        [disabled]="(password ?? '').length <= 0"
        (click)="loginClicked()"
        icon="pi pi-sign-in"
        class="p-button-sm p-button-success"
        label="{{ 'label.modal.change.subscriberRequest.login' | transloco : { default: 'Inloggen' } }}"
      ></button>
      <button
        pButton
        pRipple
        [loading]="true"
        *ngIf="stepIndex == 1 && switching"
        [disabled]="(password ?? '').length <= 0"
        (click)="loginClicked()"
        icon="pi pi-sign-in"
        class="p-button-sm p-button-success"
        label="{{ 'label.modal.change.subscriberRequest.login' | transloco : { default: 'Inloggen' } }}"
      ></button>
    </div>
  </ng-template>
</p-dialog>
