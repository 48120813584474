import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import _ from 'lodash';
import { EntityVatCheck } from 'app/shared/models/relation/entity-vat-check';
import { DeviceService } from 'app/core/services/device.service';

@Component({
  selector: 'doffice-acceptance-vat-check-modal',
  templateUrl: 'acceptance-vat-check-modal.component.html',
  styleUrls: ['acceptance-vat-check-modal.component.scss'],
})
export class AcceptanceVatCheckModalComponent implements OnDestroy {
  private _visible = new BehaviorSubject<boolean>(true);
  get visible$() {
    return this._visible.asObservable();
  }
  get visible() {
    return this._visible.getValue();
  }
  set visible(visible: boolean) {
    this._visible.next(visible);
  }

  vatResults: EntityVatCheck;
  importEnabled = true;
  importName = true;
  importAddress = true;

  importRequested$ = new Subject<{
    name: boolean;
    address: boolean;
  }>();

  isMobile = false;

  private _destroy$ = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef, private deviceService: DeviceService) {
    this.deviceService.applicationView$.pipe(takeUntil(this._destroy$)).subscribe((view) => (this.isMobile = view == 'MOBILE'));

    setTimeout(() => {
      this.cdr.detectChanges();
    }, 10);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  closeClicked() {
    this._visible.next(false);
  }

  importClicked() {
    this._visible.next(false);
    this.importRequested$.next({
      name: this.importName,
      address: this.importAddress,
    });
  }
}
