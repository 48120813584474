<p-dialog  [(visible)]="visible" appendTo="body" [modal]="true" [breakpoints]="{ '960px': '75vw', '640px': '100vw' }" [style]="{ 'max-height': '90vh', 'overflow': 'auto', width: '40vw' }" [closable]="false">
  <ng-template pTemplate="header">
    <div class="flex align-items-center">
      <span class="flex align-items-center justify-content-center text-orange-500 mr-3">
        <i class="pi pi-question-circle text-3xl"></i>
      </span>
      <span class="font-medium text-2xl text-900">
        <span>{{ 'label.modal.select.bankAccount' | transloco: { default: 'Rekening selecteren' } }}</span>
      </span>
    </div>
  </ng-template>
  <div class="grid formgrid p-fluid">
    <ng-container *ngFor="let bank of banks; let last = last">
      <div class="field mb-4 col-12 lg:col-4">
        <input [readOnly]="true" id="bankname" type="text" pInputText [ngModel]="bank.name" />
      </div>
      <div class="field mb-4 col-12 lg:col-4">
        <input [readOnly]="true" id="bankaccountnumber" type="text" pInputText [ngModel]="bank.accountnumber" />
      </div>
      <doffice-field-buttons class="mb-2 col-10 lg:col-4" buttonMargin="0" [buttons]="bankButtons" [data]="bank">
        <input [readOnly]="true" id="bankbic" type="text" pInputText [ngModel]="bank.bic" />
      </doffice-field-buttons>
      <p-divider [align]="'center'" class="col-12" *ngIf="!last"></p-divider>
    </ng-container>
  </div>
  <ng-template pTemplate="footer">
    <div class="border-top-1 surface-border pt-3">
      <button pButton pRipple (click)="cancelClicked()" class="p-button-sm mr-2 p-button-text p-button-danger" label="{{ 'label.general.cancel' | transloco: { default: 'Annuleren' } }}"></button>
    </div>
  </ng-template>
</p-dialog>
