<p-dialog  [(visible)]="visible" appendTo="body" [modal]="true" [breakpoints]="{ '960px': '75vw', '640px': '100vw' }" [style]="{ 'max-height': '90vh', 'overflow': 'auto', width: '40vw' }" [closable]="false">
  <div class="grid formgrid p-fluid">
    <div class="field mb-4 col-12" *ngIf="showSignatureName">
      <label for="signername" class="font-medium text-900">{{ 'UI_NAME' | translate }}</label>
      <input pInputText name="signername" [(ngModel)]="name" [readOnly]="blocked" type="text" />
    </div>

    <div class="field mb-4 col-12">
        <img [src]="ogSignature" style="display: block; margin-left: auto; margin-right: auto;" />
    </div>

    <div #SignatureContainer id="signature-pad-container" class="signature-pad-container field col-12" *ngIf="(ogSignature ?? '').trim().length <= 0 || resetted">
      <signature-pad #SignaturePad *ngIf="signatureCanvasInited" [options]="signaturePadOptions" (onEndEvent)="drawComplete()"></signature-pad>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="border-top-1 surface-border pt-3">
      <button pButton pRipple (click)="cancelClicked()" class="p-button-sm mr-2 p-button-text p-button-danger" [class.p-button-danger]="(ogSignature ?? '').trim().length <= 0" [class.p-button-secondary]="(ogSignature ?? '').trim().length > 0" label="{{ 'label.general.cancel' | transloco: { default: 'Annuleren' } }}"></button>
      <button pButton pRipple (click)="clearSignature()" *ngIf="isSignature" class="p-button-sm mr-2 p-button-text p-button-warning" label="{{ 'UI_RESET' | translate }}"></button>
      <button pButton pRipple *ngIf="isSignature" icon="pi pi-check" (click)="confirmSignature()" class="p-button-sm p-button-success" label="{{ 'UI_SIGN' | translate }}"></button>
      <button pButton pRipple *ngIf="(ogSignature ?? '').trim().length > 0" icon="pi pi-trash" (click)="confirmSignature()" class="p-button-sm p-button-danger" label="{{ 'UI_DELETE' | translate }}"></button>
    </div>
  </ng-template>
</p-dialog>
