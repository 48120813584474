<div class="download-modal">
  <p-card class="download-modal-card">
    <p class="download-header">{{ 'label.downloadModal.header' | transloco: {default: 'Downloadlijst'} }}</p>
    <div class="download-list">
      <div class="download-row" *ngFor="let row of list">
        <div class="download-row-icon" (click)="deleteRow(row.id)">
          <i class="pi pi-times" style="color: red; font-size: 0.8em"></i>
        </div>
        <div class="download-row-label">{{ row.label }}</div>
      </div>
    </div>
  </p-card>
</div>
