// GENERAL LOGIC
export function ConvertEnumValuesToNumberArray(enumValue): number[] {
  return Object.keys(enumValue)
    .map((x) => parseInt(x))
    .filter((x) => !isNaN(x));
}

export function isStringNullOrEmpty(str: string): boolean {
  let isNullOrEmpty: boolean = str === null || str === undefined;

  if (!isNullOrEmpty) {
    if (str.constructor.name !== 'String') throw Error('The given value is not a string. The isStringNullOrEmpty method can only accept a string value');
    isNullOrEmpty = str.trim().length <= 0;
  }

  return isNullOrEmpty;
}

export function convertBase64ToBlob(b64Data: string, contentType: string = '', sliceSize: number = 512): Blob {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function getObjectValueOrDefault<ReturnType>(object: any, keys: string[], defaultValue: ReturnType = null): ReturnType {
  if ((keys ?? []).length <= 0) return object as ReturnType;
  let result = object;
  for (let key of keys) {
    result = result[key];
    if (result === null || result === undefined) {
      return defaultValue;
    }
  }
  return result;
}

export function moveArrayObject(array: any[], fromIndex: number, toIndex: number) {
  const element = array[fromIndex];
  array.splice(fromIndex, 1);
  array.splice(toIndex, 0, element);
  return array;
}

export function numberToArray(number: number) {
  return Array(number)
    .fill(null)
    .map((x, i) => i);
}
