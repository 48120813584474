import {ChangeDetectorRef, Component} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {TableColumn, TableParameters, TableVariables} from './models';
import {isNil} from 'lodash';
import {DialogModule} from "primeng/dialog";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {TranslocoModule} from "@jsverse/transloco";
import {DofficeCheckboxModule} from "../checkbox/checkbox.module";
import {RippleModule} from "primeng/ripple";
import {ButtonModule} from "primeng/button";

@Component({
  selector: 'doffice-smart-table-filter-modal',
  templateUrl: './smart-table-filter-modal.component.html',
  styleUrls: ['./smart-table-filter-modal.component.scss'],
  standalone: true,
  imports: [DialogModule, CommonModule, FormsModule, InputTextModule, TranslocoModule, DofficeCheckboxModule, RippleModule, ButtonModule]
})
export class DofficeSmartTableFilterModal {
  private _visible = new BehaviorSubject<boolean>(true);

  get visible$() {
    return this._visible.asObservable();
  }

  get visible() {
    return this._visible.getValue();
  }

  set visible(visible: boolean) {
    this._visible.next(visible);
  }

  variables: TableVariables = {};
  columns: TableColumn[];
  parameters: TableParameters;

  constructor(private cdr: ChangeDetectorRef) {
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 10);
  }

  closeClicked() {
    this._visible.next(false);
  }

  getBooleanVariables() {
    return Object.keys(this.variables)
      .filter((key) => this.variables[key].display === 'BOOLEAN');
  }

  getColumns() {
    // return this.columns.filter((c) => (isNil(c.visible) || c.visible()) && !(c.disableFilter ?? false) && ["STRING"].includes(c.type ?? "STRING"));
  }

  setVariableValue(value: string, column: TableColumn) {
    // this.parameters.filter = this.parameters.filter.filter((f) => f.id != column.id);
    // switch (column.type) {
    //   default: {
    //     const filter = column.backendFilter[0]; // TODO
    //     this.parameters.filter.push({
    //       id: column.id,
    //       field: filter.field,
    //       value: value,
    //       database: filter.database,
    //       type: filter.type,
    //       valueType: column.type ?? 'STRING',
    //       externalDatabase: filter.externalDatabase,
    //       externalLinkField: filter.externalLinkField,
    //       externalValueField: filter.externalValueField,
    //     });
    //   }
    // }
  }

  getFilterValue(column: TableColumn) {
    // const filter = this.parameters.filter.find((f) => f.id == column.id);
    // return filter ? filter.value : null;
  }
}
