import _ from 'lodash';
import {Role} from './role';
import {Subscriber} from './subscriber';
import {Service} from '../article/service';
import {Language} from '../general/language';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {IUser, IUserInput, IUserMutationInput, IUserPermission, IUserSettings, IUserSettingsInput} from "../../../core/graphql/generated/types";
import {GetDateTimeScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";
import {languages} from "../../data/languages";


export class User implements IUser {
  id: number = 0;
  subscriber_id: number = 0;
  subscriber: Subscriber;
  isDefaultSubscriber: boolean = false;
  mail: string = '';
  name: string = '';
  phone: string = '';
  lastlogin: string = "";
  private _lastlogin: Date;

  get LastLogin() {return this._lastlogin;}

  set LastLogin(date: Date) {
    this._lastlogin = date;
    this.lastlogin = SetDateTimeScalar(date);
  }

  activated: boolean = false;
  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  settings: UserSettings;
  permissions: UserPermission[] = [];
  roles: Role[] = [];
  systemCommunications: boolean = false;

  constructor(user: IUser = null) {
    if (user === null) {
      return;
    }

    constructFromInterface(this, user);

    if (user.lastlogin) {this._lastlogin = GetDateTimeScalar(user.lastlogin);}
    if (user.created) {this._created = GetDateTimeScalar(user.created);}
    if (user.updated) {this._updated = GetDateTimeScalar(user.updated);}
    if (user.subscriber) {
      this.subscriber = new Subscriber(user.subscriber);
    }
    if (user.settings) {
      this.settings = new UserSettings(user.settings);
    }

    if (user.permissions) {
      this.permissions = user.permissions.map((x) => new UserPermission(x));
    }
    if (user.roles) {
      this.roles = user.roles.map((role) => new Role(role));
    }
  }

  convertToInput(): IUserInput {
    return {
      id: +this.id,
      name: this.name,
      mail: this.mail,
      activated: this.activated,
      phone: this.phone,
      roles: (this.roles ?? []).map((x) => x.id),
      systemCommunications: !!this.systemCommunications,
    };
  }

  public createMutationInput(originalUser: User = null, googleApiRefresh: boolean = false, googleApiCode: string = null, azureApiRefresh: boolean = false, azureApiCode: string = null): IUserMutationInput {
    let mutationInput: IUserMutationInput = {id: this.id, user: null, settings: null};
    mutationInput.user = _.isEqual(this.convertToInput(), originalUser?.convertToInput()) ? null : this.convertToInput(); /* Check if user is updated */

    mutationInput.settings = _.isEqual(this.settings?.convertToInput(googleApiRefresh, googleApiCode), originalUser?.settings?.convertToInput())
      ? null
      : this.settings.convertToInput(googleApiRefresh, googleApiCode, azureApiRefresh, azureApiCode); /* Check if settings are updated */
    return mutationInput;
  }

  public static new(): User {
    return new User({
      activated: false,
      id: null,
      created: null,
      lastlogin: null,
      mail: null,
      name: null,
      phone: null,
      subscriber_id: null,
      subscriber: null,
      isDefaultSubscriber: true,
      updated: null,
      settings: null,
      permissions: [],
      roles: [],
      systemCommunications: true,
    });
  }
}

export class UserPermission implements IUserPermission {
  id: number = 0;
  permission_id: string = '';
  user_id: number = 0;

  constructor(userPermission: IUserPermission = null) {
    if (userPermission === null) {
      return;
    }

    constructFromInterface(this, userPermission);
  }
}

export class UserSettings implements IUserSettings {
  id: number = 0;
  subscriber_id: number = 0;
  user_id: number = 0;
  language_id: number = 0;
  language: Language;
  tz_id: number = 0;
  dateFormatDay: string = '';
  dateFormatMonth: string = '';
  dateFormatYear: string = '';
  dateFormatHour: string = '';
  dateFormatMinute: string = '';
  dateFormatSecond: string = '';
  dateSplitSymbol: string = '';
  timeSplitSymbol: string = '';
  dateFormat: string = '';
  timeFormat: string = '';
  mailType: UserEmailTypeEnum = UserEmailTypeEnum.SYSTEM;
  mailSmtpHost: string = '';
  mailSmtpPort: number = 465;
  mailSmtpSecure: boolean = false;
  mailSmtpUser: string = '';
  mailSmtpPassword: string = '';
  hasGoogleApiToken: boolean = false;
  hasAzureApiToken: boolean = false;
  mailSender: string = '';
  mailEmail: string = '';
  defaultWorkPerformanceService: Service;
  defaultWorkPerformanceServiceId: number = 0;
  defaultWorkPerformancePrice: number = 0;
  defaultTranslocationPrice: number = 0;
  importTranslocationAsOneOnInvoice: boolean = false;
  automaticEmailAttachments: boolean = false;
  expandPdfOnInvoice: boolean = false;
  expandPdfOnOrder: boolean = false;
  expandPdfOnQuote: boolean = false;
  expandPdfOnReception: boolean = false;
  menuType: MenuTypeEnum = MenuTypeEnum.FIXED;
  dashboardShowLedgerNumberWarning: boolean = false;
  dashboardRevenueChartSizeDesktop: number = 0;
  dashboardRevenueChartSizeMobile: number = 0;
  compactSublineView = false;
  fullWidthBox = false;

  constructor(settings: IUserSettings = null) {
    if (settings === null) {
      return;
    }

    constructFromInterface(this, settings);

    if (settings.language_id) {this.language = languages.find(l => l.id == settings.language_id);}
    if (settings.defaultWorkPerformanceService) {
      this.defaultWorkPerformanceService = new Service(settings.defaultWorkPerformanceService);
    }
  }

  convertToInput(googleApiRefresh: boolean = false, googleApiCode: string = null, azureApiRefresh: boolean = false, azureApiCode: string = null): IUserSettingsInput {
    return {
      language_id: +this.language_id,
      tz_id: +this.tz_id,
      dateFormatDay: this.dateFormatDay,
      dateFormatMonth: this.dateFormatMonth,
      dateFormatYear: this.dateFormatYear,
      dateFormatHour: this.dateFormatHour,
      dateFormatMinute: this.dateFormatMinute,
      dateFormatSecond: this.dateFormatSecond,
      dateSplitSymbol: this.dateSplitSymbol,
      timeSplitSymbol: this.timeSplitSymbol,
      dateFormat: this.dateFormat,
      timeFormat: this.timeFormat,
      mailType: this.mailType,
      mailSmtpHost: this.mailSmtpHost,
      mailSmtpPort: +this.mailSmtpPort,
      mailSmtpSecure: this.mailSmtpSecure,
      mailSmtpUser: this.mailSmtpUser,
      mailSmtpPassword: this.mailSmtpPassword,
      mailGoogleApiRefresh: googleApiRefresh,
      mailGoogleApiCode: googleApiCode,
      mailAzureApiRefresh: azureApiRefresh,
      mailAzureApiCode: azureApiCode,
      mailSender: this.mailSender,
      mailEmail: this.mailEmail,
      defaultWorkPerformanceServiceId: +this.defaultWorkPerformanceServiceId,
      defaultWorkPerformancePrice: +this.defaultWorkPerformancePrice,
      expandPdfOnInvoice: !!this.expandPdfOnInvoice,
      expandPdfOnOrder: !!this.expandPdfOnOrder,
      expandPdfOnQuote: !!this.expandPdfOnQuote,
      expandPdfOnReception: !!this.expandPdfOnReception,
      defaultTranslocationPrice: this.defaultTranslocationPrice ? +this.defaultTranslocationPrice : null,
      importTranslocationAsOneOnInvoice: !!this.importTranslocationAsOneOnInvoice,
      menuType: this.menuType ? +this.menuType : null,
      dashboardShowLedgerNumberWarning: this.dashboardShowLedgerNumberWarning !== null && this.dashboardShowLedgerNumberWarning !== undefined ? !!this.dashboardShowLedgerNumberWarning : false,
      automaticEmailAttachments: this.automaticEmailAttachments !== null && this.automaticEmailAttachments !== undefined ? !!this.automaticEmailAttachments : true,
      dashboardRevenueChartSizeDesktop: !_.isNil(this.dashboardRevenueChartSizeDesktop) ? +this.dashboardRevenueChartSizeDesktop : null,
      dashboardRevenueChartSizeMobile: !_.isNil(this.dashboardRevenueChartSizeMobile) ? +this.dashboardRevenueChartSizeMobile : null,
    };
  }

  public static new() {
    return new UserSettings({
      id: null,
      subscriber_id: null,
      user_id: null,
      language_id: null,
      language: null,
      tz_id: null,
      dateFormatDay: null,
      dateFormatMonth: null,
      dateFormatYear: null,
      dateFormatHour: null,
      dateFormatMinute: null,
      dateFormatSecond: null,
      dateSplitSymbol: null,
      timeSplitSymbol: null,
      dateFormat: null,
      timeFormat: null,
      mailType: null,
      mailSmtpHost: null,
      mailSmtpPort: null,
      mailSmtpSecure: null,
      mailSmtpUser: null,
      mailSmtpPassword: null,
      hasGoogleApiToken: null,
      hasAzureApiToken: null,
      mailSender: null,
      mailEmail: null,
      defaultWorkPerformanceService: null,
      defaultWorkPerformanceServiceId: null,
      defaultWorkPerformancePrice: null,
      defaultTranslocationPrice: null,
      importTranslocationAsOneOnInvoice: null,
      automaticEmailAttachments: null,
      expandPdfOnInvoice: null,
      expandPdfOnOrder: null,
      expandPdfOnQuote: null,
      expandPdfOnReception: null,
      menuType: null,
      dashboardShowLedgerNumberWarning: null,
      dashboardRevenueChartSizeDesktop: 6,
      dashboardRevenueChartSizeMobile: 2,
      compactSublineView: false,
      fullWidthBox: false,
    });
  }
}

export enum MenuTypeEnum {
  FIXED = 1,
  HIDDEN = 2,
}

export enum UserEmailTypeEnum {
  SYSTEM = 1,
  SMTP = 2,
  GOOGLEAPI = 3,
  AZUREAPI = 4,
}
