import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DofficeCheckbox } from './checkbox';
import { DofficeCheckboxGroup } from './checkbox-group';

@NgModule({
  declarations: [DofficeCheckbox, DofficeCheckboxGroup],
  imports: [CommonModule, FormsModule],
  exports: [DofficeCheckbox, DofficeCheckboxGroup],
})
export class DofficeCheckboxModule {}
