import _ from 'lodash';
import {Observable} from 'rxjs';
import {DofficeSmartTable} from './smart-table.component';

export interface TableRowButton {
  icon?: string;
  label?: string;
  color?: 'secondary' | 'success' | 'info' | 'warning' | 'help' | 'danger';
  command?: (row: any, table: DofficeSmartTable, button: TableRowButton, index: number) => void;
  visible?: (row: any, table: DofficeSmartTable, button: TableRowButton, index: number) => boolean;
  disabled?: (row: any, table: DofficeSmartTable, button: TableRowButton, index: number) => boolean;
  loading?: boolean;
  rowIdentificationKey?: string;
  row?: TableRowButtonRowLink[];
}

export interface TableRowButtonRowLink {
  identification: string | number;
  loading?: boolean;
  visible?: (row: any, table: DofficeSmartTable, button: TableRowButton, index: number) => boolean;
  disabled?: (row: any, table: DofficeSmartTable, button: TableRowButton, index: number) => boolean;
}

export interface TableColumn {
  id: string;
  name: string;
  keys: string[];
  visible?: () => boolean;
  mutateValue?: (value: any, parent?: any) => any;

  color?: (value: any, parent?: any) => string;
  width?: string;

  type?: 'STRING' | 'BOOLEAN' | 'DATE' | 'TAG';
  tagColor?: (value: any, parent?: any) => 'primary' | 'success' | 'warning' | 'danger';
  booleanInvert?: boolean;

  /* Sort */
  sortKey?: string;
}

export class TableColumnClass implements TableColumn {
  id: string;
  name: string;
  keys: string[];
  width?: string;
  type?: 'STRING' | 'DATE';
  disableFilter: boolean = false;
  disableSort: boolean = false;
  visible?: () => boolean;
  storage: boolean = true;

  mutateValue?: (value: any, parent?: any) => string;
  color?: (value: any, parent?: any) => RowColor;
  mutateFilter?: (value: any, parent?: any) => string[];

  constructor(column: TableColumn) {
    Object.keys(column)
      .forEach((key) => (this[key] = column[key]));
  }

  extractValue(item: any) {
    let originalItem: any = _.cloneDeep(item);
    for (let key of
      this.keys) {
      if (item != null) {
        item = item[key] != null ? item[key] : null;
      }
    }
    if (!this.mutateValue) {
      return item;
    } else {
      return this.mutateValue(item, originalItem);
    }
  }
}

export interface TableOrder {
  key: string;
  direction: DirectionEnum;
}

export enum DirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface TableParameters {
  filter: string,
  pagination: PaginationParameters;
  order: OrderParameter;
}

export interface OrderParameter {
  key: string;
  direction?: DirectionEnum;
}

export interface TableGlobal {
  id: string;
  order: number;
  label: string;
  severity?: 'success' | 'info' | 'warning' | 'danger';
  mutateValue?: (value: any) => string;
}

export interface SmartTableGlobal extends TableGlobal {
  source: (variables: TableVariables) => Observable<any>;
}

export interface TableGlobalResult {
  id: string;
  order: number;
  label: string;
  value: string;
  loading: boolean;
  severity: string;
}

export interface PaginationParameters {
  page: number;
  size: number;
}

export type RowColor = 'valid' | 'invalid' | 'warning';

export interface TableVariables {
  [key: string]: TableVariable;
}

export interface TableVariable {
  value: any,
  display?: 'HIDDEN' | 'BOOLEAN';
  label?: string;
  storage?: boolean;
  mutation?: (variable: TableVariable, value: any) => boolean;
}

export interface DataColumnOption {
  label: string;
  value: any;
}

export interface ListReturnObject<T> {
  data: T[];
  pagination: ReturnPagination;
}

export interface ReturnPagination {
  pages: number;
  items: number;
}
