import {constructFromInterface} from 'app/core/logic/map.logic';
import {languages} from "../../data/languages";

export interface ILanguage {
  id: number;
  iso: string;
  getName: () => string;
}

export class Language implements ILanguage {
  id: number = 0;
  iso: string = '';
  getName: () => string;

  get name() {
    return this.getName();
  }

  constructor(language: ILanguage = null) {
    if (language === null) {
      return;
    }

    this.getName = language.getName;
    constructFromInterface(this, language);
  }

  public static GetById(id: number) {
    return languages.find(l => l.id == id);
  }

  public static GetByIso(iso: string) {
    return languages.find(l => l.iso == (iso ?? '').toUpperCase());
  }
}
