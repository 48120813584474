import {Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, ViewContainerRef} from '@angular/core';
import {combineLatest, fromEvent, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {OnboardingService} from 'app/core/services/onboarding.service';
import {DeviceService} from 'app/core/services/device.service';
import {marker} from '@nyffels/transloco-keys-manager/marker';
import {translate} from '@jsverse/transloco';
import {getWhitelabelEnvironment} from "../../../core/logic/whitelabel.logic";
import {Self_registrationClient, SubscriberOnboardingInput} from "../../../core/swagger/generated/swagger-client";
import {Language} from "../../../shared/models/general/language";
import {languages} from "../../../shared/data/languages";

export enum HowDoYouKnowUsEnum {
  OTHER = 1,
  FRIEND = 2,
  ACCOUNTANT = 3,
  SOCIALMEDIA = 4,
  GOOGLE = 5,
  OFFLINEADVERTISEMENT = 6,
}

@Component({
  selector: 'doffice-client-registration',
  templateUrl: './client-registration.component.html',
  styleUrls: ['./client-registration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientRegistrationComponent implements OnInit, OnDestroy {
  HowDoYouKnowUsEnum = HowDoYouKnowUsEnum;

  name = '';
  email = '';
  emailError = '';
  partnerId: number = 1;
  languageId: number;
  ref: string;

  registerLoading = false;
  registrationCompleted = false;
  registrationCompletedFaded = false;
  registrationCompletedEntered = false;

  howDoYouKnowUs: HowDoYouKnowUsEnum = null;
  howDoYouKnowUsExtra: string;
  howDoYouKnowUsOptions = [
    {
      id: HowDoYouKnowUsEnum.FRIEND,
      label: translate(marker('label.guest.clientRegistration.howDoYouKnowUs.friend', 'Via vrienden')),
    },
    {
      id: HowDoYouKnowUsEnum.ACCOUNTANT,
      label: translate(marker('label.guest.clientRegistration.howDoYouKnowUs.accountant', 'Via mijn boekhouder / accountant')),
    },
    {
      id: HowDoYouKnowUsEnum.SOCIALMEDIA,
      label: translate(marker('label.guest.clientRegistration.howDoYouKnowUs.socialMedia', 'Via sociale media')),
    },
    {
      id: HowDoYouKnowUsEnum.GOOGLE,
      label: translate(marker('label.guest.clientRegistration.howDoYouKnowUs.google', 'Via Google')),
    },
    {
      id: HowDoYouKnowUsEnum.OFFLINEADVERTISEMENT,
      label: translate(marker('label.guest.clientRegistration.howDoYouKnowUs.advertisement', 'Advertenties')),
    },
    {
      id: HowDoYouKnowUsEnum.OTHER,
      label: translate(marker('label.guest.clientRegistration.howDoYouKnowUs.other', 'Andere')),
    },
  ];

  getUnusedLanguages(): Language[] {
    return languages.filter((language) => this.languageId != language.id);
  }

  isMobile = false;

  private _destroy$: Subject<void> = new Subject();

  token: string;

  constructor(private cdr: ChangeDetectorRef, private route: ActivatedRoute, private onboardingService: OnboardingService, private deviceService: DeviceService, private vcr: ViewContainerRef, private selfRegistrationClient: Self_registrationClient) {
    this.deviceService.applicationView$.pipe(takeUntil(this._destroy$))
      .subscribe((view) => (this.isMobile = view == 'MOBILE'));
  }

  ngOnInit(): void {
    /* On enter key, do... */
    fromEvent(document, 'keydown')
      .pipe(
        takeUntil(this._destroy$),
        filter((keyEvent: KeyboardEvent) => keyEvent.code === 'Enter' && !keyEvent.shiftKey && !keyEvent.ctrlKey)
      )
      .subscribe({
        next: (keyEvent: KeyboardEvent) => {
          keyEvent.preventDefault();

          this.registrationRequest();
        },
        error: (err) => {
          console.error(err);
        },
      });

    combineLatest({
      qParams: this.route.queryParams,
      params: this.route.params,
    })
      .pipe(takeUntil(this._destroy$))
      .subscribe(({qParams, params}) => {
        this.ref = qParams.ref;
        console.log('Reference: ' + (this.ref ?? 'None'));
        this.partnerId = params.partnerId ?? 1;

        const browserLanguage = languages.find((language) => language.iso.toLowerCase() == navigator.language.substring(0, 2)
          .toLowerCase());
        this.languageId = browserLanguage ? browserLanguage.id : 1;

        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._destroy$.unsubscribe();
  }

  registrationRequest(): void {
    this.registerLoading = true;

    this.selfRegistrationClient.requestSelfRegistration(this.languageId, {
      accountancyId: this.partnerId,
      name: this.name,
      email: this.email,
      howDoYouKnowUs: this.howDoYouKnowUs,
      howDoYouKnowUsExtra: this.howDoYouKnowUsExtra,
      referral: this.ref,
      id: null,
      expirationPeriod: null
    } as SubscriberOnboardingInput)
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        this.token = res.token;
        this.registerLoading = false;
        this.registrationCompleted = true;

        this.cdr.detectChanges();

        setTimeout(() => {
          this.registrationCompletedFaded = true;
          this.cdr.detectChanges();
        }, 300);
      });
  }

  isValid(): boolean {
    return (this.name ?? '').trim().length > 0 && (this.email ?? '').trim().length > 0;
  }

  emailUpdated() {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (regex.test(this.email)) {
      this.emailError = null;
    } else {
      this.emailError = translate(marker('label.guest.clientRegistration.invalidEmail', 'Foutief e-mailadres'));
    }
  }

  startOnboarding() {
    const url = `${location.protocol}//${location.host}/onboarding/subscriber/activation/${this.token}`;
    window.open(url, "_blank");
  }

  getLogo() {
    return getWhitelabelEnvironment().app.logo
  }
}
