<p-dialog  [(visible)]="visible" appendTo="body" [modal]="true" [breakpoints]="{ '960px': '75vw', '640px': '100vw' }" [style]="{ 'max-height': '90vh', 'overflow': 'auto', width: '40vw' }" [closable]="false">
  <ng-template pTemplate="header">
    <div class="flex align-items-center">
      <span class="flex align-items-center justify-content-center text-orange-500 mr-3">
        <i class="pi pi-question-circle text-3xl"></i>
      </span>
      <span class="font-medium text-2xl text-900">
        <span>{{ title }}</span>
      </span>
    </div>
  </ng-template>
  <p class="line-height-3 p-0 m-0">
    <span>{{ message }}</span>
  </p>
  <div class="mt-4" *ngIf="doubleConfirm">
    <doffice-checkbox-group>
      <doffice-checkbox label="{{ doubleConfirmMessage }}" [(ngModel)]="confirmed"></doffice-checkbox>
    </doffice-checkbox-group>
  </div>
  <ng-template pTemplate="footer">
    <div class="border-top-1 surface-border pt-3">
      <button pButton pRipple (click)="cancelClicked()" class="p-button-sm mr-2 p-button-text p-button-danger" label="{{ 'label.base.cancel' | transloco: { default: 'Annuleren' } }}"></button>
      <button pButton pRipple icon="pi pi-check" [disabled]="doubleConfirm && !confirmed" (click)="confirmClicked()" class="p-button-sm p-button-success" label="{{ 'label.base.confirm' | transloco: { default: 'Bevestigen' } }}"></button>
    </div>
  </ng-template>
</p-dialog>
