<p-dialog [(visible)]="visible" appendTo="body" [modal]="true" [breakpoints]="{ '960px': '75vw', '640px': '100vw' }" [style]="{ 'max-height': '90vh', overflow: 'auto', width: '60vw' }" [closable]="false">
  <ng-template pTemplate="header">
    <div class="flex align-items-center">
      <span class="flex align-items-center justify-content-center text-black-500 mr-3">
        <i class="pi pi-info-circle text-3xl"></i>
      </span>
      <span class="font-medium text-2xl text-900">
        <span>{{ 'label.modal.acceptanceVatCheck.title' | transloco : { default: 'BTW nummer geverifieerd' } }}</span>
      </span>
    </div>
  </ng-template>
  <div class="grid formgrid p-fluid">
    <div class="mb-4 col-12 flex">
      <div class="field flex-auto mr-2">
        <label for="name" class="font-medium text-900">{{ 'label.modal.acceptanceVatCheck.name' | transloco : { default: 'Naam' } }}</label>
        <input [readOnly]="true" id="name" type="text" pInputText [ngModel]="vatResults.name" />
      </div>

      <div class="field flex mt-4 align-items-center">
        <doffice-checkbox label="{{ 'label.modal.acceptanceVatCheck.import' | transloco : { default: 'Importeren' } }}" [(ngModel)]="importName"></doffice-checkbox>
      </div>
    </div>

    <div class="mb-4 col-12 flex">
      <div class="field flex-auto mr-2">
        <label for="name" class="font-medium text-900">{{ 'label.modal.acceptanceVatCheck.address' | transloco : { default: 'Adres' } }}</label>
        <input [readOnly]="true" id="name" type="text" pInputText [ngModel]="vatResults.FullAddress" />
      </div>

      <div class="field flex mt-4 align-items-center">
        <doffice-checkbox label="{{ 'label.modal.acceptanceVatCheck.import' | transloco : { default: 'Importeren' } }}" [(ngModel)]="importAddress"></doffice-checkbox>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="border-top-1 surface-border pt-3">
      <button pButton pRipple (click)="closeClicked()" class="p-button-sm p-button-text p-button-secondary" [class.mr-2]="importEnabled" label="{{ 'label.general.clode' | transloco : { default: 'Sluiten' } }}"></button>
      <button pButton pRipple (click)="importClicked()" *ngIf="importEnabled" class="p-button-sm p-button-success" label="{{ 'label.modal.acceptanceVatCheck.importButton' | transloco : { default: 'Importeren' } }}"></button>
    </div>
  </ng-template>
</p-dialog>
