import {ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {NgxExtendedPdfViewerComponent} from 'ngx-extended-pdf-viewer';

@Component({
  selector: 'doffice-view-pdf-modal',
  templateUrl: 'view-pdf-modal.component.html',
  styleUrls: ['view-pdf-modal.component.scss'],
})
export class ViewPdfModalComponent {
  @ViewChild('pdfViewer') pdfViewer: NgxExtendedPdfViewerComponent;
  _visible = new BehaviorSubject<boolean>(true);

  get visible$() {
    return this._visible.asObservable();
  }

  get visible() {
    return this._visible.getValue();
  }

  set visible(visible: boolean) {
    this._visible.next(visible);
  }

  raw: string;
  name: string = 'document.pdf';

  constructor(private cdr: ChangeDetectorRef) {
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 10)
  }

  closeClicked() {
    this._visible.next(false);
  }

  downloadRequested() {
    let a = document.createElement('a');
    a.href = this.raw;
    a.download = this.name;
    a.click();

    this._visible.next(false);
  }
}
