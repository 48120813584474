import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewContainerRef} from '@angular/core';
import {BehaviorSubject, Subject, takeUntil} from 'rxjs';
import _ from 'lodash';

import {Relation} from 'app/shared/models/relation/relation.class';
import {RelationService} from 'app/core/services/relation.service';
import {EntityType} from 'app/shared/models/parameters/entity-type';
import {AcceptanceVatCheckModalComponent} from '../acceptance-vat-check-modal/acceptance-vat-check-modal.component';
import {Country} from 'app/shared/models/general/country';
import {SimpleInformationModalComponent} from '../simple-information-modal/simple-information-modal.component';
import {Address} from 'app/shared/models/relation/address';
import {AuthService} from 'app/core/services/auth.service';
import {translate} from '@jsverse/transloco';
import {marker} from '@nyffels/transloco-keys-manager/marker';
import {FieldButton} from '../../field-buttons/field-buttons.component';
import {Vats} from "../../../data/vat";
import {VatService} from "../../../../core/services/vat.service";
import {GraphqlService} from "../../../../core/services/graphql.service";
import {countries} from 'app/shared/data/country';

@Component({
  selector: 'doffice-creation-relation-modal',
  templateUrl: 'creation-relation-modal.component.html',
  styleUrls: ['creation-relation-modal.component.scss'],
})
export class CreationRelationModalComponent implements OnDestroy, OnInit {
  private _visible = new BehaviorSubject<boolean>(true);

  get visible$() {
    return this._visible.asObservable();
  }

  get visible() {
    return this._visible.getValue();
  }

  set visible(visible: boolean) {
    this._visible.next(visible);
  }

  relation = Relation.new();
  saveLoading = false;

  entityTypes: EntityType[];
  entityTypeLoading = true;
  countries: Country[] = countries;
  vatCheckLoading = false;

  fixedSupplier = false;
  fixedCustomer = false;

  created$ = new Subject<number>();
  private _destroy$ = new Subject<void>();

  vatButtons: FieldButton[] = [
    {
      loading: () => this.vatCheckLoading,
      disabled: () => !this.validVat(),
      icon: 'pi pi-search',
      color: 'warning',
      click: () => this.searchVatInformation(),
    },
  ];

  vatCodeButton: FieldButton[] = [
    {
      visible: () => !!this.relation.defaultVatCode,
      icon: 'pi pi-times',
      color: 'danger',
      click: () => this.removeVatCode(),
    },
  ];

  constructor(private cdr: ChangeDetectorRef, private relationService: RelationService, private vcr: ViewContainerRef, private authService: AuthService, private vatService: VatService, private graphqlService: GraphqlService) {
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 10);
  }

  ngOnInit(): void {
    this.relation = Relation.new();
    const defaultAddress = Address.new();
    defaultAddress.default = true;
    this.relation.addresses.push(defaultAddress);
    this.relation.paymentterms = this.authService.activeSubscriberSnapshot.settings.default_paymentterms;

    if (this.fixedSupplier) {
      this.relation.isSupplier = true;
    }

    if (this.fixedCustomer) {
      this.relation.isCustomer = true;
    }

    this.loadEntityTypes();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  cancelClicked() {
    this._visible.next(false);
  }

  saveClicked() {
    this.saveLoading = true;
    this.cdr.detectChanges();

    const input = this.relationService.createRelationMutationInput(this.relation);
    this.graphqlService.mutations.relation.update({input: input})
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        this._visible.next(false);
        this.created$.next(res.id);
        this.cdr.detectChanges();
      });
  }

  loadEntityTypes() {
    this.entityTypeLoading = true;
    this.entityTypes = [];
    this.graphqlService.queries.parameters.relationTypes.list()
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        this.entityTypes = res.data;
        this.entityTypeLoading = false;
        this.cdr.detectChanges();
      });
  }

  removeVatCode() {
    this.relation.defaultVatCode = null;
    this.cdr.detectChanges();
  }

  setEntityType(id: number): void {
    const type = this.entityTypes.find((et) => et.id == id);
    this.relation.entityType = type;

    if (!type.vat) {
      this.relation.vatNumber = null;
    }

    this.cdr.detectChanges();
  }

  validVat() {
    const regex = new RegExp(
      /^(ATU[0-9]{8}|BE[01][0-9]{9}|BG[0-9]{9,10}|HR[0-9]{11}|CY[A-Z0-9]{9}|CZ[0-9]{8,10}|DK[0-9]{8}|EE[0-9]{9}|FI[0-9]{8}|FR[0-9A-Z]{2}[0-9]{9}|DE[0-9]{9}|EL[0-9]{9}|HU[0-9]{8}|IE([0-9]{7}[A-Z]{1,2}|[0-9][A-Z][0-9]{5}[A-Z])|IT[0-9]{11}|LV[0-9]{11}|LT([0-9]{9}|[0-9]{12})|LU[0-9]{8}|MT[0-9]{8}|NL[0-9]{9}B[0-9]{2}|PL[0-9]{10}|PT[0-9]{9}|RO[0-9]{2,10}|SK[0-9]{10}|SI[0-9]{8}|ES[A-Z]([0-9]{8}|[0-9]{7}[A-Z])|SE[0-9]{12}|GB([0-9]{9}|[0-9]{12}|GD[0-4][0-9]{2}|HA[5-9][0-9]{2}))$/
    );
    return regex.test((this.relation.vatNumber ?? '').replaceAll(' ', '')
      .replace(/[^a-zA-Z0-9 ]/g, ''));
  }

  getDefaultAddress() {
    return this.relation.addresses.find((a) => a.default);
  }

  setDefaultCountry(id: number) {
    const country = this.countries.find((c) => c.id == id);
    this.getDefaultAddress().country_id = id;
    this.getDefaultAddress().country = country;
    this.cdr.detectChanges();
  }

  searchVatInformation() {
    this.vatCheckLoading = true;
    const vatnumber = (this.relation.vatNumber ?? '').replaceAll(' ', '')
      .replace(/[^a-zA-Z0-9 ]/g, '');
    this.graphqlService.queries.relation.checkVatnumber({
      vatNumber: vatnumber
    })
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (res) => {
          this.vatCheckLoading = false;
          this.cdr.detectChanges();

          if (res && res.valid) {
            const vatInformationModal = this.vcr.createComponent(AcceptanceVatCheckModalComponent);
            vatInformationModal.instance.importEnabled = true;
            vatInformationModal.instance.vatResults = res;

            vatInformationModal.instance.importRequested$.pipe(takeUntil(this._destroy$))
              .subscribe(({name, address}) => {
                if (name) {
                  this.relation.name = res.name;
                  this.cdr.detectChanges();
                }

                if (address) {
                  this.getDefaultAddress().street = res.address.street;
                  this.getDefaultAddress().number = '' + res.address.number;
                  this.getDefaultAddress().city = res.address.city;
                  this.getDefaultAddress().zip = res.address.zip_code;

                  const country = this.countries.find((c) => c.alpha == res.address.countryCode);
                  this.getDefaultAddress().country = country;
                  this.getDefaultAddress().country_id = country ? country.id : null;

                  this.cdr.detectChanges();
                }
              });
          } else {
            const vatFailedInformationModal = this.vcr.createComponent(SimpleInformationModalComponent);
            vatFailedInformationModal.instance.title = translate(marker('label.relation.vatCheckFailed.title', 'BTW nummer niet geverifieerd'));
            vatFailedInformationModal.instance.message = translate(marker('label.relation.vatCheckFailed.message', 'Het BTW nummer kon niet worden geverifieerd. Controleer de geldigheid bij de bevoegde instantie.'));
          }
        },
        error: (err) => {
          this.vatCheckLoading = false;
          console.error(err);
        },
      });
  }

  getVats() {
    return this.vatService.List();
  }
}
