import { ChangeDetectorRef, Component } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import _ from 'lodash';

@Component({
  selector: 'doffice-remove-data-modal',
  templateUrl: 'acceptance-remove-data-modal.component.html',
  styleUrls: ['acceptance-remove-data-modal.component.scss'],
})
export class AcceptanceRemoveDataModalComponent {
  private _visible = new BehaviorSubject<boolean>(true);
  get visible$() {
    return this._visible.asObservable();
  }
  get visible() {
    return this._visible.getValue();
  }
  set visible(visible: boolean) {
    this._visible.next(visible);
  }

  type: string;
  name: string;
  showSkipCheckbox = false;
  doubleConfirm = false;
  confirmed = false;

  skipDeletionModalInCurrentView = false;
  deleteFunction: () => void;
  deleteRequest$ = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef) {
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 10);
  }

  cancelClicked() {
    this._visible.next(false);
  }

  deleteClicked() {
    this._visible.next(false);
    if (this.deleteFunction) {
      this.deleteFunction();
    }
    this.deleteRequest$.next();
  }
}
