import {ChangeDetectorRef, Component} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {TranslationService} from 'app/core/services/translation.service';
import {DirectionEnum, TableColumn, TableOrder, TableRowButton, TableVariables} from '../../table/models';

import {Relation} from 'app/shared/models/relation/relation.class';
import {GraphqlService} from "../../../../core/services/graphql.service";

@Component({
  selector: 'doffice-search-relation-modal',
  templateUrl: 'search-relation-modal.component.html',
  styleUrls: ['search-relation-modal.component.scss'],
})
export class SearchRelationModalComponent {
  private _visible = new BehaviorSubject<boolean>(true);

  get visible$() {
    return this._visible.asObservable();
  }

  get visible() {
    return this._visible.getValue();
  }

  set visible(visible: boolean) {
    this._visible.next(visible);
  }

  showNewButton = false;

  relationSource = this.graphqlService.queries.relation.list
  relationVariables: TableVariables = {};
  relationColumns: TableColumn[] = [
    {
      id: 'number',
      name: this.translationService.getValueByKey('UI_NUMBER_SHORT'),
      keys: ['Number'],
      sortKey: 'number'
    },
    {
      id: 'entityType',
      name: this.translationService.getValueByKey('UI_TYPE'),
      keys: ['entityType', 'name'],
      width: '200px',
      mutateValue: (value: string): string => {
        return this.translationService.getValueByKey(value);
      },
    },
    {
      id: 'name',
      name: this.translationService.getValueByKey('UI_NAME'),
      keys: ['name'],
      sortKey: 'name'
    },
    {
      id: 'vatNumber',
      name: this.translationService.getValueByKey('UI_VAT_NUMBER'),
      keys: ['vatNumber'],
      width: '250px',
      sortKey: 'vatnumber'
    },
    {
      id: 'archived',
      name: this.translationService.getValueByKey('UI_ACTIVE'),
      keys: ['archived'],
      type: 'BOOLEAN',
      mutateValue: (value) => {
        return !value;
      },
      booleanInvert: true,
    },
  ];
  relationOrder: TableOrder = {
    direction: DirectionEnum.ASC,
    key: 'name',
  };
  relationButtons: TableRowButton[] = [
    {
      icon: 'pi pi-check',
      color: 'success',
      label: this.translationService.getValueByKey("UI_SELECT"),
      command: (row: Relation) => {
        this.selected$.next(row.id);
        this._visible.next(false);
      },
    },
  ];

  selected$ = new Subject<number>();
  new$ = new Subject<void>();

  constructor(private translationService: TranslationService, private cdr: ChangeDetectorRef, private graphqlService: GraphqlService) {
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 10);
  }

  cancelClicked() {
    this._visible.next(false);
  }

  newClicked() {
    this._visible.next(false);
    this.new$.next();
  }
}
