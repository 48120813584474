import {Component, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, OnInit} from '@angular/core';
import {AuthService} from 'app/core/services/auth.service';
import {ConvertionService} from 'app/core/services/convertion.service';

import {TranslationService} from 'app/core/services/translation.service';
import {License} from 'app/shared/models/entity/license';
import {Subscriber, SubscriberLicense} from 'app/shared/models/entity/subscriber';
import _ from 'lodash';
import {BehaviorSubject, Subject, takeUntil} from 'rxjs';
import {GraphqlService} from "../../../../core/services/graphql.service";

@Component({
  selector: 'doffice-puchase-license-modal',
  templateUrl: './purchase-license-modal.component.html',
  styleUrls: ['./purchase-license-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchaseLicenseModalComponent implements OnDestroy, OnInit {
  private _visible = new BehaviorSubject<boolean>(true);

  get visible$() {
    return this._visible.asObservable();
  }

  get visible() {
    return this._visible.getValue();
  }

  set visible(visible: boolean) {
    this._visible.next(visible);
  }

  private _destroy$ = new Subject<void>();

  subscriber: Subscriber;
  licenses: License[] = [];
  isReseller = false;

  selectedLicense: string = 'UNLIMITED';
  period: number = 12;
  userAmount: number = 0;
  currentLicense: SubscriberLicense;
  creditUsed: number;
  advisedPrice: number;

  get resellerPrice() {
    return this.advisedPrice * (1 - this.authService.activePartnerSnapshot.discountRate / 100);
  }

  isNew: boolean;
  validateRequest$ = new Subject<{ license: string; period: number; users: number }>();

  periods = [
    {
      label: '1 ' + this.translationService.getValueByKey('UI_MONTH').toLowerCase(),
      period: 1,
    },
    {
      label: '1 ' + this.translationService.getValueByKey('UI_YEAR').toLowerCase() + ' (10% ' + this.translationService.getValueByKey('UI_DISCOUNT') + ')',
      period: 12,
    },
  ];

  get valid() {
    return this.minUserAmount <= this.userAmount;
  }

  constructor( private cdr: ChangeDetectorRef, private authService: AuthService, private translationService: TranslationService, private convertionService: ConvertionService, private graphqlService: GraphqlService) {
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 10);
  }

  ngOnInit() {
    this.userAmount = this.minUserAmount;
    this.calculatePrice();

    if (!this.isNew) {
      const currentLicenseLicense = this.licenses.find((x) => x.id == this.currentLicense.licenseId);
      this.licenses = this.licenses.filter((license) => license.price >= currentLicenseLicense.price);
    }
    this.licenses = this.licenses.filter(
      (license) => (_.isNil(license.userLimit) || license.userLimit >= this.minUserAmount) && (_.isNil(license.documentLimit) || license.documentLimit >= this.subscriber.documentUsageAmount - this.subscriber.extraDocuments)
    );

    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  cancelClicked() {
    this._visible.next(false);
  }

  activateClicked() {
    this._visible.next(false);
    this.validateRequest$.next({
      license: this.selectedLicense,
      period: this.period,
      users: this.userAmount,
    });
    this.validateRequest$.complete();
  }

  calculatePrice() {
    this.graphqlService.queries.partner.reseller.subscriber.calculateLicensePrice({
      subscriberId: +this.subscriber.id,
      id: this.selectedLicense,
      period: +this.period,
      users: +this.userAmount,
      isUpgrade: !!(this.currentLicense ?? false),
      useCredit: !this.isReseller
    }).pipe(takeUntil(this._destroy$))
      .subscribe((price) => {
        this.creditUsed = price.creditUsed;
        this.advisedPrice = price.price;
        this.cdr.detectChanges();
      });
  }

  setLicense(license) {
    this.selectedLicense = license.id;
    this.calculatePrice();
  }

  setPeriod() {
    this.calculatePrice();
  }

  userAmountChanged() {
    this.calculatePrice();
  }

  get activeLicense() {
    return this.licenses.find((x) => x.id == (this.selectedLicense ?? 'UNLIMITED'));
  }

  get minUserAmount() {
    return Math.max(1, this.subscriber.userUsageAmount ?? 1, (this.activeLicense?.userFree ?? 0) + (this.subscriber.extraUsers ?? 0), this.currentLicense?.userAmount ?? 0);
  }

  get maxUserAmount() {
    return Math.max(this.activeLicense?.userLimit ?? this.userAmount);
  }

  get documentLimitReached() {
    return this.subscriber.documentUsageAmount - this.subscriber.extraDocuments > (this.activeLicense?.documentLimit ?? this.subscriber.documentUsageAmount);
  }

  getLicensePrice(license: License) {
    const price = this.period >= 12 ? license.price * 0.9 : license.price;
    return this.convertionService.convertNumberToMoneyString(price);
  }
}
