import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TranslationModule } from 'app/core/pipes/translation/translation.module';
import { DofficeEditorComponent } from './editor.component';

@NgModule({
  imports: [CommonModule, FormsModule, AngularEditorModule, TranslationModule],
  exports: [DofficeEditorComponent],
  declarations: [DofficeEditorComponent],
})
export class DofficeEditorModule {}
