import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, from, fromEvent } from 'rxjs';

export type ScreenPostition = 'LANDSCAPE' | 'PORTRAIT';
export type ApplicationView = 'FULL' | 'COMPACT' | 'RESPONSIVE' | 'MOBILE';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  private _screenPosition: BehaviorSubject<ScreenPostition> = new BehaviorSubject(null);
  screenPosition$: Observable<ScreenPostition>;
  set screenPosition(position: ScreenPostition) {
    this._screenPosition.next(position);
  }
  get screenPosition() {
    return this._screenPosition.getValue();
  }

  private _applicationView: BehaviorSubject<ApplicationView> = new BehaviorSubject(null);
  applicationView$: Observable<ApplicationView>;
  set applicationView(position: ApplicationView) {
    this._applicationView.next(position);
  }
  get applicationView() {
    return this._applicationView.getValue();
  }

  constructor() {
    this.screenPosition$ = this._screenPosition.asObservable();
    this.applicationView$ = this._applicationView.asObservable();

    this.calculateViews();

    fromEvent(window, 'resize').subscribe(() => {
      this.calculateViews();
    });
  }

  calculateViews() {
    this.screenPosition = window.innerWidth >= window.innerHeight ? 'LANDSCAPE' : 'PORTRAIT';
    if (window.innerWidth > 1950) {
      this.applicationView = 'FULL';
    } else if (window.innerWidth > 1620) {
      this.applicationView = 'COMPACT';
    } else if (window.innerWidth > 950) {
      this.applicationView = 'RESPONSIVE';
    } else {
      this.applicationView = 'MOBILE';
    }

    // console.log("------ DEVICE DIMENSIONS ------");
    // console.log("Resolution: " + window.innerWidth + "px x " + window.innerHeight + "px");
    // console.log("Screenposition: " + this.screenPosition);
    // console.log("Application view: " + this.applicationView);
    // console.log("-------------------------------");
  }
}
