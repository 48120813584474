import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'doffice-download-modal',
  templateUrl: 'download-modal.component.html',
  styleUrls: ['download-modal.component.scss'],
})
export class DownloadModalComponent {
  @Input() list: {id: number | string, label: string}[] = [];
  @Output('listChange') listEmitter = new EventEmitter<{id: number | string; label: string}[]>();

  constructor(private cdr: ChangeDetectorRef) {}

  deleteRow(id: number | string) {
    this.list = this.list.filter(l => l.id !== id );
    this.listEmitter.emit(this.list);
    this.cdr.detectChanges();
  }
}
