import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DofficeFieldButtonsComponent } from './field-buttons.component';
import { ButtonModule } from 'primeng/button';

const PRIMENG = [ButtonModule];
const COMPONENTS = [DofficeFieldButtonsComponent];

@NgModule({
  imports: [CommonModule, ...PRIMENG],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
})
export class DofficeFieldButtonsModule {}
