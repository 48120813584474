import {constructFromInterface} from "app/core/logic/map.logic";
import {IBank, IBankInput} from "../../../core/graphql/generated/types";
import {GetDateTimeScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";


export class Bank implements IBank {
  id: number = 0;
  subscriber_id: number = 0;
  name: string = '';
  bic: string = '';
  accountnumber: string = '';
  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  apiAccount: string = '';
  apiRequisition: string = '';
  apiBalance: number = 0;
  apiLastSynced: string = '';
  private _apiLastSynced: Date;

  get ApiLastSynced() {return this._apiLastSynced;}

  set ApiLastSynced(date: Date) {
    this._apiLastSynced = date;
    this.apiLastSynced = SetDateTimeScalar(date);
  }

  apiActive: boolean = false;

  get Validation(): boolean {
    return this.name?.trim()?.length > 0 && this.bic?.trim()?.length > 0 && this.accountnumber?.trim()?.length > 0;
  }

  get Name(): string {
    return `${this.accountnumber} (${this.name})`;
  }

  constructor(bank: IBank) {
    if (bank === null) {
      return;
    }

    constructFromInterface(this, bank);

    if (bank.apiLastSynced) {this._apiLastSynced = GetDateTimeScalar(bank.apiLastSynced);}
    if (bank.created) {this._created = GetDateTimeScalar(bank.created);}
    if (bank.updated) {this._updated = GetDateTimeScalar(bank.updated);}
  }

  public convertToInput(): IBankInput {
    return {
      id: +this.id,
      accountnumber: this.accountnumber,
      bic: this.bic,
      name: this.name,
      subscriber_id: +this.subscriber_id,
      apiAccount: this.apiAccount,
      apiRequisition: this.apiRequisition,
    };
  }

  public static new(): Bank {
    return new Bank({
      id: null,
      subscriber_id: null,
      accountnumber: null,
      bic: null,
      created: null,
      name: null,
      updated: null,
      apiAccount: null,
      apiActive: null,
      apiBalance: null,
      apiLastSynced: null,
      apiRequisition: null,
    });
  }
}
