import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {Injector} from '@angular/core';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {PendingChangesGuard} from './core/guards/pending-changes.guard';
import {DofficeModalModule} from './shared/components/modal/modal.module';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {HttpConfigInterceptor} from './core/interceptors/http-config.interceptor';
import {ConvertionService} from './core/services/convertion.service';
import {ConvertionModule} from './core/pipes/convertion/convertion.module';
import {TranslationModule} from './core/pipes/translation/translation.module';
import {TranslationService} from './core/services/translation.service';
import {StyleClassModule} from 'primeng/styleclass';
import {DofficeCheckboxModule} from './shared/components/checkbox/checkbox.module';
import {ProgressBarModule} from 'primeng/progressbar';
import * as Sentry from '@sentry/angular-ivy';
import {Router} from '@angular/router';
import {TranslocoRootModule} from './transloco-root.module';
import {TranslocoModule, TranslocoPipe} from '@jsverse/transloco';
import {API_BASE_URL} from "./core/swagger/generated/swagger-client";
import {getWhitelabelEnvironment} from "./core/logic/whitelabel.logic";
import {GraphQLModule} from './core/graphql/graphql.module';

export const TranslationInjector: Injector = Injector.create({
  providers: [
    {
      provide: TranslationService,
    },
  ],
});
export const ConvertionInjector: Injector = Injector.create({
  providers: [
    {
      provide: ConvertionService,
    },
  ],
});

export function GetApiBaseUrl() {
  return getWhitelabelEnvironment().api.url;
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    BrowserAnimationsModule,
    BrowserAnimationsModule,
    AngularEditorModule,
    DofficeModalModule,
    TranslationModule,
    ConvertionModule,
    FontAwesomeModule,
    NgxChartsModule,
    StyleClassModule,
    DofficeCheckboxModule,
    ProgressBarModule,
    TranslocoRootModule,
    TranslocoModule,
    TranslocoPipe,
    GraphQLModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    DatePipe,
    PendingChangesGuard,
    {provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true},
    {
      provide: API_BASE_URL,
      useFactory: GetApiBaseUrl
    }
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {
  constructor(library: FaIconLibrary, trace: Sentry.TraceService) {
    library.addIconPacks(fas, fab, far);
  }
}
