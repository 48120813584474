import { ChangeDetectorRef, Component } from '@angular/core';
import {BehaviorSubject, Subject,} from 'rxjs';
import {VatCode} from "../../../models/system/vatCode";
import {VatService} from "../../../../core/services/vat.service";

@Component({
  selector: 'doffice-search-vat-modal',
  templateUrl: 'search-vat-modal.component.html',
  styleUrls: ['search-vat-modal.component.scss'],
})
export class SearchVatModalComponent {
  private _visible = new BehaviorSubject<boolean>(true);
  get visible$() {
    return this._visible.asObservable();
  }
  get visible() {
    return this._visible.getValue();
  }
  set visible(visible: boolean) {
    this._visible.next(visible);
  }

  vats: VatCode[] = this.vatService.List();
  selected$ = new Subject<string>();

  constructor(private cdr: ChangeDetectorRef, private vatService: VatService) {
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 10);
  }

  cancelClicked() {
    this._visible.next(false);
  }

  selectClicked(vatcode: string) {
    this.selected$.next(vatcode);
    this._visible.next(false);
  }
}
