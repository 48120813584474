import _ from 'lodash';
import {Address} from '../relation/address';
import {Relation} from '../relation/relation.class';
import {Country} from '../general/country';
import {Document} from '../general/document';
import {Transaction} from '../general/transaction';
import {Company} from '../entity/company';
import {Email} from '../mail/email';
import {TranslationService} from 'app/core/services/translation.service';
import {TranslationInjector} from 'app/app.module';
import {User} from '../entity/user';
import moment from 'moment';
import {AccountNumber} from '../ledger/account-number';
import {LedgerExportTypeEnum} from '../ledger/ledger-export';
import {Contact} from '../relation/contact';
import {Peppol} from '../mail/peppol';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {Journal} from '../ledger/journal';
import {translate} from '@jsverse/transloco';
import {marker} from '@nyffels/transloco-keys-manager/marker';
import {VatService} from "../../../core/services/vat.service";
import {getWhitelabelEnvironment} from "../../../core/logic/whitelabel.logic";
import {ICreditnote, ICreditnoteInput, ICreditnoteSubline, ICreditnoteSublineInput} from "../../../core/graphql/generated/types";
import {GetDateScalar, GetDateTimeScalar, SetDateScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";
import {countries} from "../../data/country";

export class Creditnote implements ICreditnote {
  id: number = 0;
  subscriberId: number = 0;
  number: number = 0;
  Number: string = '';
  companyId: number = 0;
  companyName: string = '';
  companyVatNumber: string = '';
  companyAddressStreet: string = '';
  companyAddressNumber: string = '';
  companyAddressCity: string = '';
  companyAddressZip: string = '';
  companyAddressCounty: string = '';
  companyAddressCountryId: number = 0;
  companyAddressCountry: Country;

  get company_address(): Address {
    return new Address({
      id: null,
      street: this.companyAddressStreet,
      number: this.companyAddressNumber,
      city: this.companyAddressCity,
      zip: this.companyAddressZip,
      county: this.companyAddressCounty,
      country_id: this.companyAddressCountryId,
      country: this.companyAddressCountry,
      default: null,
      subscriber_id: this.subscriberId,
    });
  }

  company: Company;
  bankName: string = '';
  bankBic: string = '';
  bankAccountNumber: string = '';
  relationId: number = 0;
  relationName: string = '';
  relationVatNumber: string = '';
  relationAddressStreet: string = '';
  relationAddressNumber: string = '';
  relationAddressCity: string = '';
  relationAddressZip: string = '';
  relationAddressCounty: string = '';
  relationAddressCountryId: number = 0;
  relationAddressCountry: Country;

  get customer_address(): Address {
    return new Address({
      id: null,
      street: this.relationAddressStreet,
      number: this.relationAddressNumber,
      city: this.relationAddressCity,
      zip: this.relationAddressZip,
      county: this.relationAddressCounty,
      country_id: this.relationAddressCountryId,
      country: this.relationAddressCountry,
      default: null,
      subscriber_id: this.subscriberId,
    });
  }

  relation: Relation;
  value: number = 0;
  vatValue: number = 0;
  valueIncl: number = 0;
  intnote: string = '';
  note: string = '';
  date: string = "";
  private _date: Date;

  get Date() {return this._date;}

  set Date(date: Date) {
    this._date = date;
    this.date = SetDateScalar(date);
  }

  status: CreditnoteStatusEnum = CreditnoteStatusEnum.CONCEPT;

  get Status(): string {
    return TranslationInjector.get(TranslationService)
      .getValueByKey(CreditnoteStatusEnumLanguage[CreditnoteStatusEnum[this.status]]);
  }

  created: string = "";
  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  journalId: number = 0;
  journal: Journal;
  userId: number = 0;
  userName: string = '';
  userPhone: string = '';
  userMail: string = '';
  contactId: number = 0;
  contactName: string = '';
  contactPhone: string = '';
  contactMail: string = '';
  user: User;
  contact: Contact;
  exported: string = "";
  private _exported: Date;

  get Exported() {return this._exported;}

  set Exported(date: Date) {
    this._exported = date;
    this.exported = SetDateTimeScalar(date);
  }

  exportedType: LedgerExportTypeEnum = LedgerExportTypeEnum.PDF;

  get ExportedType(): string {
    switch (this.exportedType) {
      case LedgerExportTypeEnum.PDF:
        return translate(marker('label.ledgerExportType.email', 'E-mail'));
      case LedgerExportTypeEnum.OCTOPUS:
        return translate(marker('label.ledgerExportType.octopus', 'Octopus'));
      case LedgerExportTypeEnum.ADMISOL:
        return translate(marker('label.ledgerExportType.admisol', 'Admisol'));
      case LedgerExportTypeEnum.YUKI:
        return translate(marker('label.ledgerExportType.yuki', 'Yuki'));
      case LedgerExportTypeEnum.MANUAL:
        return translate(marker('label.ledgerExportType.manual', 'Manueel gemarkeerd als geëxporteerd'));
      case LedgerExportTypeEnum.EXACT:
        return translate(marker('label.ledgerExportType.exactApi', 'Exact'));
    }
  }

  relationReference: string = '';
  guestToken: string = '';

  get GuestToken() {
    return `${getWhitelabelEnvironment().app.url}/guest/CN+${this.guestToken}`;
  }

  guestEnabled: boolean = false;
  vatCode: string = "";
  accountNumberId: number = 0;
  accountNumber: AccountNumber;
  sublines: CreditnoteSubline[] = [];
  documents: Document[] = [];
  transactions: Transaction[] = [];
  emails: Email[] = [];
  peppols: Peppol[] = [];

  get Valid(): boolean {
    return (
      this.companyId != null &&
      this.companyId > 0 &&
      this.companyName != null &&
      this.companyName.trim().length > 0 &&
      this.companyVatNumber != null &&
      this.companyVatNumber.trim().length > 0 &&
      this.relationId != null &&
      this.relationId > 0 &&
      this.relationName != null &&
      this.relationName.trim().length > 0 &&
      this.value !== null &&
      this.vatValue !== null &&
      this.valueIncl !== null &&
      this.date !== null
    );
  }

  constructor(creditnote: ICreditnote = null) {
    if (creditnote === null) {
      return;
    }

    constructFromInterface(this, creditnote);

    if (creditnote.date) {this._date = GetDateScalar(creditnote.date);}
    if (creditnote.created) {this._created = GetDateTimeScalar(creditnote.created);}
    if (creditnote.updated) {this._updated = GetDateTimeScalar(creditnote.updated);}
    if (creditnote.exported) {this._exported = GetDateTimeScalar(creditnote.exported);}
    if (creditnote.companyAddressCountryId) {
      this.companyAddressCountry = new Country(countries.find(x => x.id == creditnote.companyAddressCountryId));
    }
    if (creditnote.company) {
      this.company = new Company(creditnote.company);
    }
    if (creditnote.relationAddressCountryId) {
      this.relationAddressCountry = new Country(countries.find(x => x.id == creditnote.relationAddressCountryId));
    }
    if (creditnote.relation) {
      this.relation = new Relation(creditnote.relation);
    }
    if (creditnote.user) {
      this.user = new User(creditnote.user);
    }
    if (creditnote.contact) {
      this.contact = new Contact(creditnote.contact);
    }
    if (creditnote.journal) {
      this.journal = new Journal(creditnote.journal);
    }
    if (creditnote.accountNumber) {
      this.accountNumber = new AccountNumber(creditnote.accountNumber);
    }

    if (creditnote.sublines) {
      this.sublines = creditnote.sublines.map((x) => new CreditnoteSubline(x));
    }
    if (creditnote.documents) {
      this.documents = creditnote.documents.map((x) => new Document(x));
    }
    if (creditnote.transactions) {
      this.transactions = creditnote.transactions.map((x) => new Transaction(x));
    }
    if (creditnote.emails) {
      this.emails = creditnote.emails.map((x) => new Email(x));
    }
    if (creditnote.peppols) {
      this.peppols = creditnote.peppols.map((x) => new Peppol(x));
    }
  }

  public calculateValue(): void {
    this.value = _.sumBy(this.sublines, (x) => +x.price);
    this.valueIncl = _.sumBy(this.sublines, (x) => +x.priceIncl);
    this.vatValue = _.sumBy(this.sublines, (x) => +x.vatprice);
  }

  public convertToInput(): ICreditnoteInput {
    return {
      id: this.id,
      companyId: +this.companyId,
      companyName: this.companyName,
      companyVatNumber: this.companyVatNumber,
      companyAddressStreet: this.companyAddressStreet,
      companyAddressNumber: this.companyAddressNumber,
      companyAddressCity: this.companyAddressCity,
      companyAddressZip: this.companyAddressZip,
      companyAddressCounty: this.companyAddressCounty,
      companyAddressCountryId: +this.companyAddressCountryId,
      relationId: +this.relationId,
      relationName: this.relationName,
      relationVatNumber: this.relationVatNumber,
      relationAddressStreet: this.relationAddressStreet,
      relationAddressNumber: this.relationAddressNumber,
      relationAddressCity: this.relationAddressCity,
      relationAddressZip: this.relationAddressZip,
      relationAddressCounty: this.relationAddressCounty,
      relationAddressCountryId: +this.relationAddressCountryId,
      value: +this.value,
      vatValue: +this.vatValue,
      valueIncl: +this.valueIncl,
      intnote: this.intnote,
      note: this.note,
      date: SetDateScalar(this.date ? moment(this.date)
        .toDate() : null),
      status: +this.status,
      bankAccountNumber: this.bankAccountNumber,
      bankBic: this.bankBic,
      bankName: this.bankName,
      journalId: +this.journalId,
      userId: this.userId ? +this.userId : null,
      contactId: this.contactId ? +this.contactId : null,
      userName: this.userName,
      userPhone: this.userPhone,
      userMail: this.userMail,
      contactName: this.contactName,
      contactPhone: this.contactPhone,
      contactMail: this.contactMail,
      relationReference: (this.relationReference ?? '').trim().length > 0 ? '' + this.relationReference : null,
      guestEnabled: !!this.guestEnabled,
      vatCode: this.vatCode ? '' + this.vatCode : null,
      accountNumberId: this.accountNumberId ? +this.accountNumberId : null,
    };
  }

  public static new(): Creditnote {
    return new Creditnote({
      id: null,
      subscriberId: null,
      number: null,
      companyId: null,
      companyName: null,
      companyVatNumber: null,
      companyAddressStreet: null,
      companyAddressNumber: null,
      companyAddressCity: null,
      companyAddressZip: null,
      companyAddressCounty: null,
      companyAddressCountryId: null,
      relationId: null,
      relationName: null,
      relationVatNumber: null,
      relationAddressStreet: null,
      relationAddressNumber: null,
      relationAddressCity: null,
      relationAddressZip: null,
      relationAddressCounty: null,
      relationAddressCountryId: null,
      value: 0,
      vatValue: 0,
      valueIncl: 0,
      intnote: null,
      note: null,
      date: SetDateScalar(new Date()),
      status: CreditnoteStatusEnum.CONCEPT,
      created: null,
      updated: null,
      Number: null,
      company: null,
      companyAddressCountry: null,
      relation: null,
      relationAddressCountry: null,
      documents: [],
      emails: [],
      peppols: [],
      sublines: [],
      transactions: [],
      bankAccountNumber: null,
      bankBic: null,
      bankName: null,
      journalId: null,
      journal: null,
      userId: null,
      contactId: null,
      userName: null,
      userPhone: null,
      userMail: null,
      contactName: null,
      contactPhone: null,
      contactMail: null,
      user: null,
      contact: null,
      exported: null,
      exportedType: null,
      relationReference: null,
      guestToken: null,
      guestEnabled: true,
      vatCode: null,
      accountNumberId: null,
      accountNumber: null,
    });
  }
}

export class CreditnoteSubline implements ICreditnoteSubline {
  id: number = 0;
  subscriberId: number = 0;
  creditnote: Creditnote;
  creditnoteId: number = 0;
  vatCode: string = "";
  name: string = '';
  amount: number = 0;
  accountNumberId: number = 0;
  accountNumber: AccountNumber;
  unitPrice: number = 0;
  price: number = 0;
  priceIncl: number = 0;
  vatprice: number = 0;
  note: string = '';
  created: string = "";
  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  order: number = 0;
  unit: string = '';

  get VatName() {
    return VatService.getLabel(this.vatCode);
  }

  get Valid(): boolean {
    return (this.name ?? '').trim().length > 0 && this.amount !== null && this.unitPrice !== null && this.price !== null && !_.isNil(this.vatCode);
  }

  constructor(subline: ICreditnoteSubline = null) {
    if (subline === null) {
      return;
    }

    constructFromInterface(this, subline);

    if (subline.created) {this._created = GetDateTimeScalar(subline.created);}
    if (subline.updated) {this._updated = GetDateTimeScalar(subline.updated);}
    if (subline.accountNumber) {
      this.accountNumber = new AccountNumber(subline.accountNumber);
    }
    if (subline.creditnote) {
      this.creditnote = new Creditnote(subline.creditnote);
    }
  }

  public convertToInput(): ICreditnoteSublineInput {
    return {
      id: +this.id,
      vatCode: "" + this.vatCode,
      name: this.name,
      amount: +this.amount,
      unitPrice: +this.unitPrice,
      price: +this.price,
      priceIncl: +this.priceIncl,
      vatprice: +this.vatprice,
      note: this.note,
      order: +this.order,
      accountNumberId: +this.accountNumberId,
      unit: (this.unit ?? '').trim().length > 0 ? '' + this.unit : null,
    };
  }

  public static new(): CreditnoteSubline {
    return new CreditnoteSubline({
      id: null,
      subscriberId: null,
      creditnote: null,
      creditnoteId: null,
      vatCode: null,
      name: null,
      amount: 1,
      unitPrice: 0,
      price: 0,
      priceIncl: 0,
      vatprice: 0,
      note: null,
      created: null,
      updated: null,
      order: null,
      accountNumber: null,
      accountNumberId: null,
      unit: null,
    });
  }

  calculateSublineValuesFromAmount(): void {
    this.price = +(this.price ?? 0);
    this.priceIncl = +(this.priceIncl ?? 0);
    this.vatprice = +(this.vatprice ?? 0);
    this.unitPrice = +(this.unitPrice ?? 0);
    this.amount = +(this.amount ?? 1);

    const vat = VatService.Get(this.vatCode);
    this.unitPrice = this.unitPrice ?? 0;
    this.price = +(+this.unitPrice * +(this.amount ?? 0)).toFixed(2);
    this.vatprice = +(vat.percentage ? +this.price * (+vat.percentage / 100) : 0).toFixed(2);
    this.priceIncl = +(+this.price + +(this.vatprice ?? 0)).toFixed(2);
  }

  calculateSublineValuesFomUnitRefundprice(): void {
    this.price = +(this.price ?? 0);
    this.priceIncl = +(this.priceIncl ?? 0);
    this.vatprice = +(this.vatprice ?? 0);
    this.unitPrice = +(this.unitPrice ?? 0);
    this.amount = +(this.amount ?? 1);

    const vat = VatService.Get(this.vatCode);
    this.price = +(+this.unitPrice * +(this.amount ?? 0)).toFixed(2);
    this.vatprice = +(vat.percentage ? +this.price * (+vat.percentage / 100) : 0).toFixed(2);
    this.priceIncl = +(+this.price + +(this.vatprice ?? 0)).toFixed(2);
  }

  calculateSublineValuesFromRefundPrice(): void {
    this.price = +(this.price ?? 0);
    this.priceIncl = +(this.priceIncl ?? 0);
    this.vatprice = +(this.vatprice ?? 0);
    this.unitPrice = +(this.unitPrice ?? 0);
    this.amount = +(this.amount ?? 1);

    const vat = VatService.Get(this.vatCode);
    this.unitPrice = +(+(this.amount ?? 0) == 0 ? 0 : +this.price / +this.amount).toFixed(2);
    this.vatprice = +(vat.percentage ? +this.price * (+vat.percentage / 100) : 0).toFixed(2);
    this.priceIncl = +(+this.price + +(this.vatprice ?? 0)).toFixed(2);
  }

  calculateSublineValuesFromSalesPriceIncl(): void {
    this.price = +(this.price ?? 0);
    this.priceIncl = +(this.priceIncl ?? 0);
    this.vatprice = +(this.vatprice ?? 0);
    this.unitPrice = +(this.unitPrice ?? 0);
    this.amount = +(this.amount ?? 1);

    const vat = VatService.Get(this.vatCode);
    this.price = +(vat.percentage ? +this.priceIncl / (+vat.percentage / 100 + 1) : +this.priceIncl).toFixed(2);
    this.vatprice = +(vat.percentage ? +this.price * (+vat.percentage / 100) : 0).toFixed(2);
    this.unitPrice = +(+(this.amount ?? 0) == 0 ? 0 : +this.price / +this.amount).toFixed(2);
  }
}

export enum CreditnoteStatusEnum {
  CONCEPT = 1,
  UNPAID = 2,
  PARTIALLYPAID = 3,
  PAID = 4,
}

export enum CreditnoteStatusEnumLanguage {
  CONCEPT = 'UI_CONCEPT',
  UNPAID = 'UI_UNPAID',
  PARTIALLYPAID = 'UI_PARTIALLY_PAID',
  PAID = 'UI_PAID',
}
