import {Injectable} from '@angular/core';
import {Contact} from 'app/shared/models/relation/contact';
import {MutationInputObject} from 'app/shared/models/system/mutation-object';
import _ from 'lodash';
import {IContactMutationInput} from "../graphql/generated/types";

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  createContactMutations(contacts: Contact[], originalContacts: Contact[] = null): MutationInputObject<IContactMutationInput> {
    if (_.isNil(originalContacts)) {
      contacts.forEach((contact) => {
        contact.id == null;
      });
    }

    const input: MutationInputObject<IContactMutationInput> = {deletes: [], updates: []};

    const newContacts = contacts.filter((contact) => _.isNil(contact.id))
      .map((contact) => contact.convertToInput());
    const updatedContact = contacts
      .filter((contact) => !_.isNil(contact.id))
      .map((contact) => contact.convertToInput())
      .filter((contact) => !_.isEqual(contact, originalContacts?.find((s) => s.id == contact.id)
        ?.convertToInput()));
    input.updates = _.concat(newContacts, updatedContact)
      .map((contact) => {
        return {
          id: contact.id,
          contact: contact,
        };
      });

    input.deletes = _.differenceBy(
      originalContacts,
      contacts.filter((contact) => !_.isNil(contact.id)),
      (contact) => contact.id
    )
      .map((contact) => +contact.id);

    return input;
  }
}
