import { ChangeDetectorRef, Component } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import _ from 'lodash';
import { translate } from '@jsverse/transloco';
import { marker } from '@nyffels/transloco-keys-manager/marker';

@Component({
  selector: 'doffice-confirm-modal',
  templateUrl: 'acceptance-confirm-modal.component.html',
  styleUrls: ['acceptance-confirm-modal.component.scss'],
})
export class AcceptanceConfirmModalComponent {
  private _visible = new BehaviorSubject<boolean>(true);
  get visible$() {
    return this._visible.asObservable();
  }
  get visible() {
    return this._visible.getValue();
  }
  set visible(visible: boolean) {
    this._visible.next(visible);
  }

  title: string = translate(marker('label.modal.acceptance.title', 'Bevestig de actie'));
  message: string = '';
  doubleConfirm = false;
  doubleConfirmMessage = translate(marker('label.modal.acceptance.doubleConfirm.message', 'Ik bevestig mijn keuze en erken de risico\'s'));
  confirmed = false;

  confirmFunction: () => void;
  confirm$ = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef) {
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 10);
  }

  cancelClicked() {
    this._visible.next(false);
  }

  confirmClicked() {
    this._visible.next(false);
    this.confirm$.next();
    if (this.confirmFunction) this.confirmFunction();
  }
}
