<p-dialog [(visible)]="visible" appendTo="body" [modal]="true" [breakpoints]="{ '960px': '75vw', '640px': '100vw' }" [style]="{ 'max-height': '90vh', overflow: 'auto', width: '60vw' }" [closable]="false">
  <div class="grid formgrid p-fluid">
    <doffice-field-buttons class="mb -2 col-12" [buttons]="gatewayButtons">
      <label for="transactionGateways" class="font-medium text-900">{{ 'label.create.transaction.modal.name' | transloco : { default: 'Naam' } }}</label>
      <div class="p-inputgroup" *ngIf="!gatewaysLoading">
        <p-dropdown
          inputId="transactionGateways"
          [options]="gateways"
          optionValue="id"
          optionLabel="name"
          [filter]="true"
          filterBy="name"
          [showClear]="false"
          [(ngModel)]="transaction.gateway_id"
          (onChange)="setGateway($event.value)"
          placeholder="{{ 'label.selectPlaceholder.transactionGateway' | transloco : { default: 'Selecteer een Betaalmethode' } }}"
          emptyFilterMessage="{{ 'label.list.transactionGateway.emptyFilter' | transloco : { default: 'Er zijn geen betaalmethodes in het systeem die aan de huidige filters voldoen.' } }}"
          emptyMessage="{{ 'label.list.transactionGateway.empty' | transloco : { default: 'Er zijn geen betaalmethodes gevonden in het systeem.' } }}"
        >
          <ng-template let-gateway pTemplate="item">
            <div class="flex align-items-center">
              <div>{{ gateway.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <p-skeleton height="2.7rem" *ngIf="gatewaysLoading"></p-skeleton>
    </doffice-field-buttons>

    <div class="field mb-4 col-12 lg:col-6">
      <label for="amount" class="font-medium text-900">{{ 'label.create.transaction.modal.amount' | transloco : { default: 'Bedrag' } }}</label>
      <input id="amount" type="number" pInputText [(ngModel)]="transaction.amount" />
    </div>

    <div class="field mb-4 col-12 lg:col-6">
      <label for="date" class="font-medium text-900">{{ 'label.create.transaction.modal.date' | transloco : { default: 'Datum' } }}</label>
      <doffice-calendar id="date" [(ngModel)]="transaction.date"></doffice-calendar>
    </div>

    <div class="field mb-4 col-12">
      <label for="description" class="font-medium text-900">{{ 'label.create.transaction.modal.description' | transloco : { default: 'Omschrijving' } }}</label>
      <doffice-editor id="description" [(value)]="transaction.note"></doffice-editor>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="border-top-1 surface-border pt-3">
      <button pButton pRipple (click)="cancelClicked()" class="p-button-sm mr-2 p-button-text p-button-danger" label="{{ 'label.general.cancel' | transloco : { default: 'Annuleren' } }}"></button>
      <button pButton pRipple icon="pi pi-check" [loading]="saveLoading" (click)="saveClicked()" class="p-button-sm p-button-success" label="{{ 'label.create.transaction.button.save' | transloco : { default: 'Opslaan' } }}"></button>
    </div>
  </ng-template>
</p-dialog>
