import _ from 'lodash';
import {TranslationInjector} from 'app/app.module';
import {TranslationService} from 'app/core/services/translation.service';
import {Invoice} from './invoice';
import {Transaction} from '../general/transaction';
import {Document} from '../general/document';
import {Relation} from '../relation/relation.class';
import {Country} from '../general/country';
import {Company} from '../entity/company';
import {Email} from '../mail/email';
import {Address} from '../relation/address';
import moment from 'moment';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {MutationInputObject} from "../system/mutation-object";
import {IDocumentMutationInput, IInvoiceReminder, IInvoiceReminderInput, IInvoiceReminderMutationInput, ITransactionMutationInput} from "../../../core/graphql/generated/types";
import {GetDateScalar, GetDateTimeScalar, SetDateScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";
import {countries} from "../../data/country";

export class InvoiceReminder implements IInvoiceReminder {
  id: number = 0;
  subscriberId: number = 0;
  number: number = 0;
  Number: string = '';
  surprice: number = 0;
  level: number = 0;
  date: string = "";
  private _date: Date;

  get Date() {return this._date;}

  set Date(date: Date) {
    this._date = date;
    this.date = SetDateScalar(date);
  }

  enddate: string = "";
  private _enddate: Date;

  get Enddate() {return this._enddate;}

  set Enddate(date: Date) {
    this._enddate = date;
    this.enddate = SetDateScalar(date);
  }

  created: string = "";
  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  status: InvoiceReminderStatus = InvoiceReminderStatus.CONCEPT;
  relation: Relation;
  relationId: number = 0;
  relationName: string = '';
  relationVatNumber: string = '';
  relationAddressStreet: string = '';
  relationAddressNumber: string = '';
  relationAddressCity: string = '';
  relationAddressZip: string = '';
  relationAddressCounty: string = '';
  relationAddressCountryId: number = 0;
  relationAddressCountry: Country;

  get relationAddress(): Address {
    return new Address({
      id: null,
      street: this.relationAddressStreet,
      number: this.relationAddressNumber,
      city: this.relationAddressCity,
      zip: this.relationAddressZip,
      county: this.relationAddressCounty,
      country_id: this.relationAddressCountryId,
      country: this.relationAddressCountry,
      default: null,
      subscriber_id: this.subscriberId,
    });
  }

  paymentReference: string = '';
  paymentReferenceStruct: boolean = false;
  company: Company;
  companyId: number = 0;
  companyName: string = '';
  companyVatNumber: string = '';
  companyAddressStreet: string = '';
  companyAddressNumber: string = '';
  companyAddressCity: string = '';
  companyAddressZip: string = '';
  companyAddressCounty: string = '';
  companyAddressCountryId: number = 0;
  companyAddressCountry: Country;

  get companyAddress(): Address {
    return new Address({
      id: null,
      street: this.companyAddressStreet,
      number: this.companyAddressNumber,
      city: this.companyAddressCity,
      zip: this.companyAddressZip,
      county: this.companyAddressCounty,
      country_id: this.companyAddressCountryId,
      country: this.companyAddressCountry,
      default: null,
      subscriber_id: this.subscriberId,
    });
  }

  bankName: string = '';
  bankBic: string = '';
  bankAccountNumber: string = '';
  levelOne: string = "";
  private _levelOne: Date;

  get LevelOne() {return this._levelOne;}

  set LevelOne(date: Date) {
    this._levelOne = date;
    this.levelOne = SetDateTimeScalar(date);
  }

  levelTwo: string = "";
  private _levelTwo: Date;

  get LevelTwo() {return this._levelTwo;}

  set LevelTwo(date: Date) {
    this._levelTwo = date;
    this.levelTwo = SetDateTimeScalar(date);
  }

  levelThree: string = "";
  private _levelThree: Date;

  get LevelThree() {return this._levelThree;}

  set LevelThree(date: Date) {
    this._levelThree = date;
    this.levelThree = SetDateTimeScalar(date);
  }

  invoices: Invoice[] = [];
  transactions: Transaction[] = [];
  documents: Document[] = [];
  emails: Email[] = [];
  internalNote: string = '';
  note: string = '';

  get Status(): string {
    return TranslationInjector.get(TranslationService)
      .getValueByKey(InvoiceReminderStatusLanguage[InvoiceReminderStatus[this.status]]);
  }

  get Valid(): boolean {
    return true; // TODO
  }

  constructor(reminder: IInvoiceReminder = null) {
    if (reminder === null) {
      return;
    }

    constructFromInterface(this, reminder);

    if (reminder.date) {this._date = GetDateScalar(reminder.date);}
    if (reminder.enddate) {this._enddate = GetDateScalar(reminder.enddate);}
    if (reminder.created) {this._created = GetDateTimeScalar(reminder.created);}
    if (reminder.updated) {this._updated = GetDateTimeScalar(reminder.updated);}
    if (reminder.levelOne) {this._levelOne = GetDateTimeScalar(reminder.levelOne);}
    if (reminder.levelTwo) {this._levelTwo = GetDateTimeScalar(reminder.levelTwo);}
    if (reminder.levelThree) {this._levelThree = GetDateTimeScalar(reminder.levelThree);}
    if (reminder.relation) {
      this.relation = new Relation(reminder.relation);
    }
    if (reminder.relationAddressCountryId) {
      this.relationAddressCountry = new Country(countries.find(x => x.id == reminder.relationAddressCountryId));
    }
    if (reminder.company) {
      this.company = new Company(reminder.company);
    }
    if (reminder.companyAddressCountryId) {
      this.companyAddressCountry = new Country(countries.find(x => x.id == reminder.companyAddressCountryId));
    }

    if (reminder.invoices) {
      this.invoices = reminder.invoices.map((x) => new Invoice(x));
    }
    if (reminder.transactions) {
      this.transactions = reminder.transactions.map((x) => new Transaction(x));
    }
    if (reminder.documents) {
      this.documents = reminder.documents.map((x) => new Document(x));
    }
    if (reminder.emails) {
      this.emails = reminder.emails.map((x) => new Email(x));
    }
  }

  public static new(): InvoiceReminder {
    return new InvoiceReminder({
      id: null,
      subscriberId: null,
      number: null,
      Number: null,
      surprice: null,
      level: null,
      date: null,
      enddate: null,
      created: null,
      updated: null,
      status: null,
      relation: null,
      relationId: null,
      relationName: null,
      relationVatNumber: null,
      relationAddressStreet: null,
      relationAddressNumber: null,
      relationAddressCity: null,
      relationAddressZip: null,
      relationAddressCounty: null,
      relationAddressCountryId: null,
      relationAddressCountry: null,
      paymentReference: null,
      paymentReferenceStruct: null,
      company: null,
      companyId: null,
      companyName: null,
      companyVatNumber: null,
      companyAddressStreet: null,
      companyAddressNumber: null,
      companyAddressCity: null,
      companyAddressZip: null,
      companyAddressCounty: null,
      companyAddressCountryId: null,
      companyAddressCountry: null,
      bankName: null,
      bankBic: null,
      bankAccountNumber: null,
      levelOne: null,
      levelTwo: null,
      levelThree: null,
      invoices: null,
      transactions: [],
      documents: [],
      emails: [],
      note: null,
      internalNote: null,
    });
  }

  public convertToInput(): IInvoiceReminderInput {
    return {
      id: this.id ? +this.id : null,
      surprice: this.surprice ? +this.surprice : null,
      date: SetDateScalar(this.date ? moment(this.date)
        .toDate() : null),
      enddate: SetDateScalar(this.enddate ? moment(this.enddate)
        .toDate() : null),
      relationId: this.relationId ? +this.relationId : null,
      relationName: this.relationName,
      relationVatNumber: this.relationVatNumber,
      relationAddressStreet: this.relationAddressStreet,
      relationAddressNumber: this.relationAddressNumber,
      relationAddressCity: this.relationAddressCity,
      relationAddressZip: this.relationAddressZip,
      relationAddressCounty: this.relationAddressCounty,
      relationAddressCountryId: +this.relationAddressCountryId,
      paymentReference: this.paymentReference,
      paymentReferenceStruct: !!this.paymentReferenceStruct,
      companyId: this.companyId ? +this.companyId : null,
      companyName: this.companyName,
      companyVatNumber: this.companyVatNumber,
      companyAddressStreet: this.companyAddressStreet,
      companyAddressNumber: this.companyAddressNumber,
      companyAddressCity: this.companyAddressCity,
      companyAddressZip: this.companyAddressZip,
      companyAddressCounty: this.companyAddressCounty,
      companyAddressCountryId: +this.companyAddressCountryId,
      bankName: this.bankName,
      bankBic: this.bankBic,
      bankAccountNumber: this.bankAccountNumber,
      internalNote: this.internalNote,
      note: this.note,
    };
  }

  public createMutationInput(documents: MutationInputObject<Document>, transactions: MutationInputObject<Transaction>, originalReminder: InvoiceReminder = null): IInvoiceReminderMutationInput {
    let mutationInput: IInvoiceReminderMutationInput = {id: this.id, reminder: null, documents: null, transactions: null};
    mutationInput.reminder = _.isEqual(this.convertToInput(), originalReminder?.convertToInput()) ? null : this.convertToInput(); /* Check if invoice is updated */

    /* Set documents */
    mutationInput.documents = {
      deletes: documents.deletes.map((x) => Number(x)),
      updates: documents.updates.map((x) => {
        return {
          id: x.id,
          document: x.convertToInput(),
        } as IDocumentMutationInput;
      }),
    };

    /* Set transactions */
    mutationInput.transactions = {
      deletes: transactions.deletes.map((x) => Number(x)),
      updates: transactions.updates.map((x) => {
        return {
          id: x.id,
          transaction: x.convertToInput(),
        } as ITransactionMutationInput;
      }),
    };

    return mutationInput;
  }
}

export enum InvoiceReminderStatus {
  CONCEPT = 1,
  OPEN = 2,
  OVERDUEPAYED = 3,
  PAID = 4,
  FINISHED = 5,
}

export enum InvoiceReminderStatusLanguage {
  CONCEPT = 'UI_CONCEPT',
  OPEN = 'UI_OPEN',
  OVERDUEPAYED = 'UI_OVERDUE_PAID',
  PAID = 'UI_PAID',
  FINISHED = 'UI_FINISHED',
}
