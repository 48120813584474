import {marker} from "@nyffels/transloco-keys-manager/marker";
import {translate} from "@jsverse/transloco";
import {Country} from "../models/general/country";

export const countries: Country[] = [
  new Country({id: 4, alpha: 'AF', getName: () => {return translate(marker('country.name.AF', 'Afghanistan'))}}),
  new Country({id: 8, alpha: 'AL', getName: () => {return translate(marker('country.name.AL', 'Albanië'))}}),
  new Country({id: 10, alpha: 'AQ', getName: () => {return translate(marker('country.name.AQ', 'Antarctica'))}}),
  new Country({id: 12, alpha: 'DZ', getName: () => {return translate(marker('country.name.DZ', 'Algerije'))}}),
  new Country({id: 16, alpha: 'AS', getName: () => {return translate(marker('country.name.AS', 'Amerikaans Samoa'))}}),
  new Country({id: 20, alpha: 'AD', getName: () => {return translate(marker('country.name.AD', 'Andorra'))}}),
  new Country({id: 24, alpha: 'AO', getName: () => {return translate(marker('country.name.AO', 'Angola'))}}),
  new Country({id: 28, alpha: 'AG', getName: () => {return translate(marker('country.name.AG', 'Antigua en Barbuda'))}}),
  new Country({id: 31, alpha: 'AZ', getName: () => {return translate(marker('country.name.AZ', 'Azerbeidzjan'))}}),
  new Country({id: 32, alpha: 'AR', getName: () => {return translate(marker('country.name.AR', 'Argentinië'))}}),
  new Country({id: 36, alpha: 'AU', getName: () => {return translate(marker('country.name.AU', 'Australië'))}}),
  new Country({id: 40, alpha: 'AT', getName: () => {return translate(marker('country.name.AT', 'Oostenrijk'))}}),
  new Country({id: 44, alpha: 'BS', getName: () => {return translate(marker('country.name.BS', 'Bahama\'s'))}}),
  new Country({id: 48, alpha: 'BH', getName: () => {return translate(marker('country.name.BH', 'Bahrein'))}}),
  new Country({id: 50, alpha: 'BD', getName: () => {return translate(marker('country.name.BD', 'Bangladesh'))}}),
  new Country({id: 51, alpha: 'AM', getName: () => {return translate(marker('country.name.AM', 'Armenië'))}}),
  new Country({id: 52, alpha: 'BB', getName: () => {return translate(marker('country.name.BB', 'Barbados'))}}),
  new Country({id: 56, alpha: 'BE', getName: () => {return translate(marker('country.name.BE', 'België'))}}),
  new Country({id: 60, alpha: 'BM', getName: () => {return translate(marker('country.name.BM', 'Bermuda'))}}),
  new Country({id: 64, alpha: 'BT', getName: () => {return translate(marker('country.name.BT', 'Bhutan'))}}),
  new Country({id: 68, alpha: 'BO', getName: () => {return translate(marker('country.name.BO', 'Bolivia'))}}),
  new Country({id: 70, alpha: 'BA', getName: () => {return translate(marker('country.name.BA', 'Bosnië en Herzegovina'))}}),
  new Country({id: 72, alpha: 'BW', getName: () => {return translate(marker('country.name.BW', 'Botswana'))}}),
  new Country({id: 74, alpha: 'BV', getName: () => {return translate(marker('country.name.BV', 'Bouveteiland'))}}),
  new Country({id: 76, alpha: 'BR', getName: () => {return translate(marker('country.name.BR', 'Brazilië'))}}),
  new Country({id: 84, alpha: 'BZ', getName: () => {return translate(marker('country.name.BZ', 'Belize'))}}),
  new Country({id: 86, alpha: 'IO', getName: () => {return translate(marker('country.name.IO', 'Britse Gebieden in de Indische Oceaan'))}}),
  new Country({id: 90, alpha: 'SB', getName: () => {return translate(marker('country.name.SB', 'Salomonseilanden'))}}),
  new Country({id: 92, alpha: 'VG', getName: () => {return translate(marker('country.name.VG', 'Britse Maagdeneilanden'))}}),
  new Country({id: 96, alpha: 'BN', getName: () => {return translate(marker('country.name.BN', 'Brunei'))}}),
  new Country({id: 100, alpha: 'BG', getName: () => {return translate(marker('country.name.BG', 'Bulgarije'))}}),
  new Country({id: 104, alpha: 'MM', getName: () => {return translate(marker('country.name.MM', 'Myanmar'))}}),
  new Country({id: 108, alpha: 'BI', getName: () => {return translate(marker('country.name.BI', 'Burundi'))}}),
  new Country({id: 112, alpha: 'BY', getName: () => {return translate(marker('country.name.BY', 'Wit-Rusland'))}}),
  new Country({id: 116, alpha: 'KH', getName: () => {return translate(marker('country.name.KH', 'Cambodja'))}}),
  new Country({id: 120, alpha: 'CM', getName: () => {return translate(marker('country.name.CM', 'Kameroen'))}}),
  new Country({id: 124, alpha: 'CA', getName: () => {return translate(marker('country.name.CA', 'Canada'))}}),
  new Country({id: 132, alpha: 'CV', getName: () => {return translate(marker('country.name.CV', 'Kaapverdië'))}}),
  new Country({id: 136, alpha: 'KY', getName: () => {return translate(marker('country.name.KY', 'Caymaneilanden'))}}),
  new Country({id: 140, alpha: 'CF', getName: () => {return translate(marker('country.name.CF', 'Centraal-Afrikaanse Republiek'))}}),
  new Country({id: 144, alpha: 'LK', getName: () => {return translate(marker('country.name.LK', 'Sri Lanka'))}}),
  new Country({id: 148, alpha: 'TD', getName: () => {return translate(marker('country.name.TD', 'Tsjaad'))}}),
  new Country({id: 152, alpha: 'CL', getName: () => {return translate(marker('country.name.CL', 'Chili'))}}),
  new Country({id: 156, alpha: 'CN', getName: () => {return translate(marker('country.name.CN', 'China'))}}),
  new Country({id: 158, alpha: 'TW', getName: () => {return translate(marker('country.name.TW', 'Taiwan'))}}),
  new Country({id: 162, alpha: 'CX', getName: () => {return translate(marker('country.name.CX', 'Christmaseiland'))}}),
  new Country({id: 166, alpha: 'CC', getName: () => {return translate(marker('country.name.CC', 'Cocoseilanden'))}}),
  new Country({id: 170, alpha: 'CO', getName: () => {return translate(marker('country.name.CO', 'Colombia'))}}),
  new Country({id: 174, alpha: 'KM', getName: () => {return translate(marker('country.name.KM', 'Comoren'))}}),
  new Country({id: 175, alpha: 'YT', getName: () => {return translate(marker('country.name.YT', 'Mayotte'))}}),
  new Country({id: 178, alpha: 'CG', getName: () => {return translate(marker('country.name.CG', 'Congo'))}}),
  new Country({id: 180, alpha: 'CD', getName: () => {return translate(marker('country.name.CD', 'Congo (DRC)'))}}),
  new Country({id: 184, alpha: 'CK', getName: () => {return translate(marker('country.name.CK', 'Cookeilanden'))}}),
  new Country({id: 188, alpha: 'CR', getName: () => {return translate(marker('country.name.CR', 'Costa Rica'))}}),
  new Country({id: 191, alpha: 'HR', getName: () => {return translate(marker('country.name.HR', 'Kroatië'))}}),
  new Country({id: 192, alpha: 'CU', getName: () => {return translate(marker('country.name.CU', 'Cuba'))}}),
  new Country({id: 196, alpha: 'CY', getName: () => {return translate(marker('country.name.CY', 'Cyprus'))}}),
  new Country({id: 203, alpha: 'CZ', getName: () => {return translate(marker('country.name.CZ', 'Tsjechië'))}}),
  new Country({id: 204, alpha: 'BJ', getName: () => {return translate(marker('country.name.BJ', 'Benin'))}}),
  new Country({id: 208, alpha: 'DK', getName: () => {return translate(marker('country.name.DK', 'Denemarken'))}}),
  new Country({id: 212, alpha: 'DM', getName: () => {return translate(marker('country.name.DM', 'Dominica'))}}),
  new Country({id: 214, alpha: 'DO', getName: () => {return translate(marker('country.name.DO', 'Dominicaanse Republiek'))}}),
  new Country({id: 218, alpha: 'EC', getName: () => {return translate(marker('country.name.EC', 'Ecuador'))}}),
  new Country({id: 222, alpha: 'SV', getName: () => {return translate(marker('country.name.SV', 'El Salvador'))}}),
  new Country({id: 226, alpha: 'GQ', getName: () => {return translate(marker('country.name.GQ', 'Equatoriaal-Guinea'))}}),
  new Country({id: 231, alpha: 'ET', getName: () => {return translate(marker('country.name.ET', 'Ethiopië'))}}),
  new Country({id: 232, alpha: 'ER', getName: () => {return translate(marker('country.name.ER', 'Eritrea'))}}),
  new Country({id: 233, alpha: 'EE', getName: () => {return translate(marker('country.name.EE', 'Estland'))}}),
  new Country({id: 234, alpha: 'FO', getName: () => {return translate(marker('country.name.FO', 'Faeröer'))}}),
  new Country({id: 238, alpha: 'FK', getName: () => {return translate(marker('country.name.FK', 'Falklandeilanden'))}}),
  new Country({id: 239, alpha: 'GS', getName: () => {return translate(marker('country.name.GS', 'Zuid-Georgia en Zuidelijke Sandwicheilanden'))}}),
  new Country({id: 242, alpha: 'FJ', getName: () => {return translate(marker('country.name.FJ', 'Fiji'))}}),
  new Country({id: 246, alpha: 'FI', getName: () => {return translate(marker('country.name.FI', 'Finland'))}}),
  new Country({id: 248, alpha: 'AX', getName: () => {return translate(marker('country.name.AX', 'Ålandeilanden'))}}),
  new Country({id: 250, alpha: 'FR', getName: () => {return translate(marker('country.name.FR', 'Frankrijk'))}}),
  new Country({id: 254, alpha: 'GF', getName: () => {return translate(marker('country.name.GF', 'Frans-Guyana'))}}),
  new Country({id: 258, alpha: 'PF', getName: () => {return translate(marker('country.name.PF', 'Frans-Polynesië'))}}),
  new Country({id: 260, alpha: 'TF', getName: () => {return translate(marker('country.name.TF', 'Franse Gebieden in de zuidelijke Indische Oceaan'))}}),
  new Country({id: 262, alpha: 'DJ', getName: () => {return translate(marker('country.name.DJ', 'Djibouti'))}}),
  new Country({id: 266, alpha: 'GA', getName: () => {return translate(marker('country.name.GA', 'Gabon'))}}),
  new Country({id: 268, alpha: 'GE', getName: () => {return translate(marker('country.name.GE', 'Georgië'))}}),
  new Country({id: 270, alpha: 'GM', getName: () => {return translate(marker('country.name.GM', 'Gambia'))}}),
  new Country({id: 275, alpha: 'PS', getName: () => {return translate(marker('country.name.PS', 'Palestijnse gebieden'))}}),
  new Country({id: 276, alpha: 'DE', getName: () => {return translate(marker('country.name.DE', 'Duitsland'))}}),
  new Country({id: 288, alpha: 'GH', getName: () => {return translate(marker('country.name.GH', 'Ghana'))}}),
  new Country({id: 292, alpha: 'GI', getName: () => {return translate(marker('country.name.GI', 'Gibraltar'))}}),
  new Country({id: 296, alpha: 'KI', getName: () => {return translate(marker('country.name.KI', 'Kiribati'))}}),
  new Country({id: 300, alpha: 'GR', getName: () => {return translate(marker('country.name.GR', 'Griekenland'))}}),
  new Country({id: 304, alpha: 'GL', getName: () => {return translate(marker('country.name.GL', 'Groenland'))}}),
  new Country({id: 308, alpha: 'GD', getName: () => {return translate(marker('country.name.GD', 'Grenada'))}}),
  new Country({id: 312, alpha: 'GP', getName: () => {return translate(marker('country.name.GP', 'Guadeloupe'))}}),
  new Country({id: 316, alpha: 'GU', getName: () => {return translate(marker('country.name.GU', 'Guam'))}}),
  new Country({id: 320, alpha: 'GT', getName: () => {return translate(marker('country.name.GT', 'Guatemala'))}}),
  new Country({id: 324, alpha: 'GN', getName: () => {return translate(marker('country.name.GN', 'Guinee'))}}),
  new Country({id: 328, alpha: 'GY', getName: () => {return translate(marker('country.name.GY', 'Guyana'))}}),
  new Country({id: 332, alpha: 'HT', getName: () => {return translate(marker('country.name.HT', 'Haïti'))}}),
  new Country({id: 334, alpha: 'HM', getName: () => {return translate(marker('country.name.HM', 'Heard-en McDonaldeilanden'))}}),
  new Country({id: 336, alpha: 'VA', getName: () => {return translate(marker('country.name.VA', 'Vaticaanstad'))}}),
  new Country({id: 340, alpha: 'HN', getName: () => {return translate(marker('country.name.HN', 'Honduras'))}}),
  new Country({id: 344, alpha: 'HK', getName: () => {return translate(marker('country.name.HK', 'Hongkong'))}}),
  new Country({id: 348, alpha: 'HU', getName: () => {return translate(marker('country.name.HU', 'Hongarije'))}}),
  new Country({id: 352, alpha: 'IS', getName: () => {return translate(marker('country.name.IS', 'IJsland'))}}),
  new Country({id: 356, alpha: 'IN', getName: () => {return translate(marker('country.name.IN', 'India'))}}),
  new Country({id: 360, alpha: 'ID', getName: () => {return translate(marker('country.name.ID', 'Indonesië'))}}),
  new Country({id: 364, alpha: 'IR', getName: () => {return translate(marker('country.name.IR', 'Iran'))}}),
  new Country({id: 368, alpha: 'IQ', getName: () => {return translate(marker('country.name.IQ', 'Irak'))}}),
  new Country({id: 372, alpha: 'IE', getName: () => {return translate(marker('country.name.IE', 'Ierland'))}}),
  new Country({id: 376, alpha: 'IL', getName: () => {return translate(marker('country.name.IL', 'Israël'))}}),
  new Country({id: 380, alpha: 'IT', getName: () => {return translate(marker('country.name.IT', 'Italië'))}}),
  new Country({id: 384, alpha: 'CI', getName: () => {return translate(marker('country.name.CI', 'Ivoorkust'))}}),
  new Country({id: 388, alpha: 'JM', getName: () => {return translate(marker('country.name.JM', 'Jamaica'))}}),
  new Country({id: 392, alpha: 'JP', getName: () => {return translate(marker('country.name.JP', 'Japan'))}}),
  new Country({id: 398, alpha: 'KZ', getName: () => {return translate(marker('country.name.KZ', 'Kazachstan'))}}),
  new Country({id: 400, alpha: 'JO', getName: () => {return translate(marker('country.name.JO', 'Jordanië'))}}),
  new Country({id: 404, alpha: 'KE', getName: () => {return translate(marker('country.name.KE', 'Kenia'))}}),
  new Country({id: 408, alpha: 'KP', getName: () => {return translate(marker('country.name.KP', 'Noord-Korea'))}}),
  new Country({id: 410, alpha: 'KR', getName: () => {return translate(marker('country.name.KR', 'Zuid-Korea'))}}),
  new Country({id: 414, alpha: 'KW', getName: () => {return translate(marker('country.name.KW', 'Koeweit'))}}),
  new Country({id: 417, alpha: 'KG', getName: () => {return translate(marker('country.name.KG', 'Kirgizië'))}}),
  new Country({id: 418, alpha: 'LA', getName: () => {return translate(marker('country.name.LA', 'Laos'))}}),
  new Country({id: 422, alpha: 'LB', getName: () => {return translate(marker('country.name.LB', 'Libanon'))}}),
  new Country({id: 426, alpha: 'LS', getName: () => {return translate(marker('country.name.LS', 'Lesotho'))}}),
  new Country({id: 428, alpha: 'LV', getName: () => {return translate(marker('country.name.LV', 'Letland'))}}),
  new Country({id: 430, alpha: 'LR', getName: () => {return translate(marker('country.name.LR', 'Liberia'))}}),
  new Country({id: 434, alpha: 'LY', getName: () => {return translate(marker('country.name.LY', 'Libië'))}}),
  new Country({id: 438, alpha: 'LI', getName: () => {return translate(marker('country.name.LI', 'Liechtenstein'))}}),
  new Country({id: 440, alpha: 'LT', getName: () => {return translate(marker('country.name.LT', 'Litouwen'))}}),
  new Country({id: 442, alpha: 'LU', getName: () => {return translate(marker('country.name.LU', 'Luxemburg'))}}),
  new Country({id: 446, alpha: 'MO', getName: () => {return translate(marker('country.name.MO', 'Macao'))}}),
  new Country({id: 450, alpha: 'MG', getName: () => {return translate(marker('country.name.MG', 'Madagaskar'))}}),
  new Country({id: 454, alpha: 'MW', getName: () => {return translate(marker('country.name.MW', 'Malawi'))}}),
  new Country({id: 458, alpha: 'MY', getName: () => {return translate(marker('country.name.MY', 'Maleisië'))}}),
  new Country({id: 462, alpha: 'MV', getName: () => {return translate(marker('country.name.MV', 'Maldiven'))}}),
  new Country({id: 466, alpha: 'ML', getName: () => {return translate(marker('country.name.ML', 'Mali'))}}),
  new Country({id: 470, alpha: 'MT', getName: () => {return translate(marker('country.name.MT', 'Malta'))}}),
  new Country({id: 474, alpha: 'MQ', getName: () => {return translate(marker('country.name.MQ', 'Martinique'))}}),
  new Country({id: 478, alpha: 'MR', getName: () => {return translate(marker('country.name.MR', 'Mauritanië'))}}),
  new Country({id: 480, alpha: 'MU', getName: () => {return translate(marker('country.name.MU', 'Mauritius'))}}),
  new Country({id: 484, alpha: 'MX', getName: () => {return translate(marker('country.name.MX', 'Mexico'))}}),
  new Country({id: 492, alpha: 'MC', getName: () => {return translate(marker('country.name.MC', 'Monaco'))}}),
  new Country({id: 496, alpha: 'MN', getName: () => {return translate(marker('country.name.MN', 'Mongolië'))}}),
  new Country({id: 498, alpha: 'MD', getName: () => {return translate(marker('country.name.MD', 'Moldavië'))}}),
  new Country({id: 499, alpha: 'ME', getName: () => {return translate(marker('country.name.ME', 'Montenegro'))}}),
  new Country({id: 500, alpha: 'MS', getName: () => {return translate(marker('country.name.MS', 'Montserrat'))}}),
  new Country({id: 504, alpha: 'MA', getName: () => {return translate(marker('country.name.MA', 'Marokko'))}}),
  new Country({id: 508, alpha: 'MZ', getName: () => {return translate(marker('country.name.MZ', 'Mozambique'))}}),
  new Country({id: 512, alpha: 'OM', getName: () => {return translate(marker('country.name.OM', 'Oman'))}}),
  new Country({id: 516, alpha: 'NA', getName: () => {return translate(marker('country.name.NA', 'Namibië'))}}),
  new Country({id: 520, alpha: 'NR', getName: () => {return translate(marker('country.name.NR', 'Nauru'))}}),
  new Country({id: 524, alpha: 'NP', getName: () => {return translate(marker('country.name.NP', 'Nepal'))}}),
  new Country({id: 528, alpha: 'NL', getName: () => {return translate(marker('country.name.NL', 'Nederland'))}}),
  new Country({id: 531, alpha: 'CW', getName: () => {return translate(marker('country.name.CW', 'Curaçao'))}}),
  new Country({id: 533, alpha: 'AW', getName: () => {return translate(marker('country.name.AW', 'Aruba'))}}),
  new Country({id: 534, alpha: 'SX', getName: () => {return translate(marker('country.name.SX', 'Sint Maarten'))}}),
  new Country({id: 535, alpha: 'BQ', getName: () => {return translate(marker('country.name.BQ', 'Caribisch Nederland'))}}),
  new Country({id: 540, alpha: 'NC', getName: () => {return translate(marker('country.name.NC', 'Nieuw-Caledonië'))}}),
  new Country({id: 548, alpha: 'VU', getName: () => {return translate(marker('country.name.VU', 'Vanuatu'))}}),
  new Country({id: 554, alpha: 'NZ', getName: () => {return translate(marker('country.name.NZ', 'Nieuw-Zeeland'))}}),
  new Country({id: 558, alpha: 'NI', getName: () => {return translate(marker('country.name.NI', 'Nicaragua'))}}),
  new Country({id: 562, alpha: 'NE', getName: () => {return translate(marker('country.name.NE', 'Niger'))}}),
  new Country({id: 566, alpha: 'NG', getName: () => {return translate(marker('country.name.NG', 'Nigeria'))}}),
  new Country({id: 570, alpha: 'NU', getName: () => {return translate(marker('country.name.NU', 'Niue'))}}),
  new Country({id: 574, alpha: 'NF', getName: () => {return translate(marker('country.name.NF', 'Norfolkeiland'))}}),
  new Country({id: 578, alpha: 'NO', getName: () => {return translate(marker('country.name.NO', 'Noorwegen'))}}),
  new Country({id: 580, alpha: 'MP', getName: () => {return translate(marker('country.name.MP', 'Noordelijke Marianeneilanden'))}}),
  new Country({id: 581, alpha: 'UM', getName: () => {return translate(marker('country.name.UM', 'Kleine afgelegen eilanden van de Verenigde Staten'))}}),
  new Country({id: 583, alpha: 'FM', getName: () => {return translate(marker('country.name.FM', 'Micronesië'))}}),
  new Country({id: 584, alpha: 'MH', getName: () => {return translate(marker('country.name.MH', 'Marshalleilanden'))}}),
  new Country({id: 585, alpha: 'PW', getName: () => {return translate(marker('country.name.PW', 'Palau'))}}),
  new Country({id: 586, alpha: 'PK', getName: () => {return translate(marker('country.name.PK', 'Pakistan'))}}),
  new Country({id: 591, alpha: 'PA', getName: () => {return translate(marker('country.name.PA', 'Panama'))}}),
  new Country({id: 598, alpha: 'PG', getName: () => {return translate(marker('country.name.PG', 'Papoea-Nieuw-Guinea'))}}),
  new Country({id: 600, alpha: 'PY', getName: () => {return translate(marker('country.name.PY', 'Paraguay'))}}),
  new Country({id: 604, alpha: 'PE', getName: () => {return translate(marker('country.name.PE', 'Peru'))}}),
  new Country({id: 608, alpha: 'PH', getName: () => {return translate(marker('country.name.PH', 'Filipijnen'))}}),
  new Country({id: 612, alpha: 'PN', getName: () => {return translate(marker('country.name.PN', 'Pitcairneilanden'))}}),
  new Country({id: 616, alpha: 'PL', getName: () => {return translate(marker('country.name.PL', 'Polen'))}}),
  new Country({id: 620, alpha: 'PT', getName: () => {return translate(marker('country.name.PT', 'Portugal'))}}),
  new Country({id: 624, alpha: 'GW', getName: () => {return translate(marker('country.name.GW', 'Guinee-Bissau'))}}),
  new Country({id: 626, alpha: 'TL', getName: () => {return translate(marker('country.name.TL', 'Oost-Timor'))}}),
  new Country({id: 630, alpha: 'PR', getName: () => {return translate(marker('country.name.PR', 'Puerto Rico'))}}),
  new Country({id: 634, alpha: 'QA', getName: () => {return translate(marker('country.name.QA', 'Qatar'))}}),
  new Country({id: 638, alpha: 'RE', getName: () => {return translate(marker('country.name.RE', 'Réunion'))}}),
  new Country({id: 642, alpha: 'RO', getName: () => {return translate(marker('country.name.RO', 'Roemenië'))}}),
  new Country({id: 643, alpha: 'RU', getName: () => {return translate(marker('country.name.RU', 'Rusland'))}}),
  new Country({id: 646, alpha: 'RW', getName: () => {return translate(marker('country.name.RW', 'Rwanda'))}}),
  new Country({id: 652, alpha: 'BL', getName: () => {return translate(marker('country.name.BL', 'Saint Barthélemy'))}}),
  new Country({id: 654, alpha: 'SH', getName: () => {return translate(marker('country.name.SH', 'Sint-Helena, Ascension en Tristan da Cunha'))}}),
  new Country({id: 659, alpha: 'KN', getName: () => {return translate(marker('country.name.KN', 'Saint Kitts en Nevis'))}}),
  new Country({id: 660, alpha: 'AI', getName: () => {return translate(marker('country.name.AI', 'Anguilla'))}}),
  new Country({id: 662, alpha: 'LC', getName: () => {return translate(marker('country.name.LC', 'Saint Lucia'))}}),
  new Country({id: 663, alpha: 'MF', getName: () => {return translate(marker('country.name.MF', 'Saint-Martin'))}}),
  new Country({id: 666, alpha: 'PM', getName: () => {return translate(marker('country.name.PM', 'Saint Pierre en Miquelon'))}}),
  new Country({id: 670, alpha: 'VC', getName: () => {return translate(marker('country.name.VC', 'Saint Vincent en de Grenadines'))}}),
  new Country({id: 674, alpha: 'SM', getName: () => {return translate(marker('country.name.SM', 'San Marino'))}}),
  new Country({id: 678, alpha: 'ST', getName: () => {return translate(marker('country.name.ST', 'Sao Tomé en Principe'))}}),
  new Country({id: 682, alpha: 'SA', getName: () => {return translate(marker('country.name.SA', 'Saoedi-Arabië'))}}),
  new Country({id: 686, alpha: 'SN', getName: () => {return translate(marker('country.name.SN', 'Senegal'))}}),
  new Country({id: 688, alpha: 'RS', getName: () => {return translate(marker('country.name.RS', 'Servië'))}}),
  new Country({id: 690, alpha: 'SC', getName: () => {return translate(marker('country.name.SC', 'Seychellen'))}}),
  new Country({id: 694, alpha: 'SL', getName: () => {return translate(marker('country.name.SL', 'Sierra Leone'))}}),
  new Country({id: 702, alpha: 'SG', getName: () => {return translate(marker('country.name.SG', 'Singapore'))}}),
  new Country({id: 703, alpha: 'SK', getName: () => {return translate(marker('country.name.SK', 'Slowakije'))}}),
  new Country({id: 704, alpha: 'VN', getName: () => {return translate(marker('country.name.VN', 'Vietnam'))}}),
  new Country({id: 705, alpha: 'SI', getName: () => {return translate(marker('country.name.SI', 'Slovenië'))}}),
  new Country({id: 706, alpha: 'SO', getName: () => {return translate(marker('country.name.SO', 'Somalië'))}}),
  new Country({id: 710, alpha: 'ZA', getName: () => {return translate(marker('country.name.ZA', 'Zuid-Afrika'))}}),
  new Country({id: 716, alpha: 'ZW', getName: () => {return translate(marker('country.name.ZW', 'Zimbabwe'))}}),
  new Country({id: 724, alpha: 'ES', getName: () => {return translate(marker('country.name.ES', 'Spanje'))}}),
  new Country({id: 728, alpha: 'SS', getName: () => {return translate(marker('country.name.SS', 'Zuid-Soedan'))}}),
  new Country({id: 729, alpha: 'SD', getName: () => {return translate(marker('country.name.SD', 'Soedan'))}}),
  new Country({id: 732, alpha: 'EH', getName: () => {return translate(marker('country.name.EH', 'Westelijke Sahara'))}}),
  new Country({id: 740, alpha: 'SR', getName: () => {return translate(marker('country.name.SR', 'Suriname'))}}),
  new Country({id: 744, alpha: 'SJ', getName: () => {return translate(marker('country.name.SJ', 'Svalbard en Jan Mayen'))}}),
  new Country({id: 748, alpha: 'SZ', getName: () => {return translate(marker('country.name.SZ', 'Swaziland'))}}),
  new Country({id: 752, alpha: 'SE', getName: () => {return translate(marker('country.name.SE', 'Zweden'))}}),
  new Country({id: 756, alpha: 'CH', getName: () => {return translate(marker('country.name.CH', 'Zwitserland'))}}),
  new Country({id: 760, alpha: 'SY', getName: () => {return translate(marker('country.name.SY', 'Syrië'))}}),
  new Country({id: 762, alpha: 'TJ', getName: () => {return translate(marker('country.name.TJ', 'Tadzjikistan'))}}),
  new Country({id: 764, alpha: 'TH', getName: () => {return translate(marker('country.name.TH', 'Thailand'))}}),
  new Country({id: 768, alpha: 'TG', getName: () => {return translate(marker('country.name.TG', 'Togo'))}}),
  new Country({id: 772, alpha: 'TK', getName: () => {return translate(marker('country.name.TK', 'Tokelau'))}}),
  new Country({id: 776, alpha: 'TO', getName: () => {return translate(marker('country.name.TO', 'Tonga'))}}),
  new Country({id: 780, alpha: 'TT', getName: () => {return translate(marker('country.name.TT', 'Trinidad en Tobago'))}}),
  new Country({id: 784, alpha: 'AE', getName: () => {return translate(marker('country.name.AE', 'Verenigde Arabische Emiraten'))}}),
  new Country({id: 788, alpha: 'TN', getName: () => {return translate(marker('country.name.TN', 'Tunesië'))}}),
  new Country({id: 792, alpha: 'TR', getName: () => {return translate(marker('country.name.TR', 'Turkije'))}}),
  new Country({id: 795, alpha: 'TM', getName: () => {return translate(marker('country.name.TM', 'Turkmenistan'))}}),
  new Country({id: 796, alpha: 'TC', getName: () => {return translate(marker('country.name.TC', 'Turks-en Caicoseilanden'))}}),
  new Country({id: 798, alpha: 'TV', getName: () => {return translate(marker('country.name.TV', 'Tuvalu'))}}),
  new Country({id: 800, alpha: 'UG', getName: () => {return translate(marker('country.name.UG', 'Oeganda'))}}),
  new Country({id: 804, alpha: 'UA', getName: () => {return translate(marker('country.name.UA', 'Oekraïne'))}}),
  new Country({id: 807, alpha: 'MK', getName: () => {return translate(marker('country.name.MK', 'Noord-Macedonië'))}}),
  new Country({id: 818, alpha: 'EG', getName: () => {return translate(marker('country.name.EG', 'Egypte'))}}),
  new Country({id: 826, alpha: 'GB', getName: () => {return translate(marker('country.name.GB', 'Verenigd Koninkrijk'))}}),
  new Country({id: 831, alpha: 'GG', getName: () => {return translate(marker('country.name.GG', 'Guernsey'))}}),
  new Country({id: 832, alpha: 'JE', getName: () => {return translate(marker('country.name.JE', 'Jersey'))}}),
  new Country({id: 833, alpha: 'IM', getName: () => {return translate(marker('country.name.IM', 'Isle of Man'))}}),
  new Country({id: 834, alpha: 'TZ', getName: () => {return translate(marker('country.name.TZ', 'Tanzania'))}}),
  new Country({id: 840, alpha: 'US', getName: () => {return translate(marker('country.name.US', 'Verenigde Staten'))}}),
  new Country({id: 850, alpha: 'VI', getName: () => {return translate(marker('country.name.VI', 'Amerikaanse Maagdeneilanden'))}}),
  new Country({id: 854, alpha: 'BF', getName: () => {return translate(marker('country.name.BF', 'Burkina Faso'))}}),
  new Country({id: 858, alpha: 'UY', getName: () => {return translate(marker('country.name.UY', 'Uruguay'))}}),
  new Country({id: 860, alpha: 'UZ', getName: () => {return translate(marker('country.name.UZ', 'Oezbekistan'))}}),
  new Country({id: 862, alpha: 'VE', getName: () => {return translate(marker('country.name.VE', 'Venezuela'))}}),
  new Country({id: 876, alpha: 'WF', getName: () => {return translate(marker('country.name.WF', 'Wallis en Futuna'))}}),
  new Country({id: 882, alpha: 'WS', getName: () => {return translate(marker('country.name.WS', 'Samoa'))}}),
  new Country({id: 887, alpha: 'YE', getName: () => {return translate(marker('country.name.YE', 'Jemen'))}}),
  new Country({id: 894, alpha: 'ZM', getName: () => {return translate(marker('country.name._ZM', 'Zambia'))}}),
];
